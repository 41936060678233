import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { AddDuplicateIcon } from '../../Icons/Icons'

const useStyles = makeStyles((theme) => ({
  listItem: {
    minHeight: 56,
    height: 56,
    color: theme.palette.primary.contrastText,
    '&:hover': { color: theme.palette.secondary.main }
  },
  listItemIcon: { minWidth: 0, color: 'currentColor' }
}))

const EditableListEmptyItem = ({ text, onAdd }) => {
  const classes = useStyles()
  return (
    <ListItem button className={classes.listItem} onClick={onAdd}>
      <ListItemText primary={text} />
      <ListItemIcon className={classes.listItemIcon}>
        <AddDuplicateIcon />
      </ListItemIcon>
    </ListItem>
  )
}
export default EditableListEmptyItem
