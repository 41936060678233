import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Bar from '../../Toolbar/Bar'
import BarButton from '../../Toolbar/BarButton'
import {
  setCurrentLateralContent,
  LATERAL_CONTENT
} from '../../../modules/reducerMain'
const useStyles = makeStyles((theme) => ({
  topBar: {}
}))

const MDTopBar = ({ tabs, currentTab, children, onChange }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleBack = useCallback(() => {
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.MATTERTAGS_LIST))
  }, [dispatch])

  return (
    <Bar className={classes.topBar}>
      <BarButton tooltipTitle={'Atras'} tooltipEnabled onClick={handleBack}>
        <ArrowBackIcon />
      </BarButton>
    </Bar>
  )
}
export default MDTopBar
