import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { GotoIcon } from '../Icons/Icons'
import BarButton from './BarButton'
import {
  modelLoadedSelector,
  sdkSelector
} from '../../modules/reducerMatterport'

import { navigateToMatterTagPromise } from '../../utils/matterportUtils'

const GoToTagButton = ({ sid }) => {
  const modelLoaded = useSelector(modelLoadedSelector)
  const sdk = useSelector(sdkSelector)

  const handleGoTo = useCallback(() => {
    navigateToMatterTagPromise(sdk, sid)
  }, [sdk, sid])

  return (
    <BarButton
      tooltipTitle={'Ir a'}
      tooltipEnabled
      disabled={!modelLoaded || !sid}
      onClick={handleGoTo}
    >
      <GotoIcon />
    </BarButton>
  )
}
export default GoToTagButton
