import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Accordion from '../../Common/Accordion/Accordion'

import PFFloor from './PFFloor'
import PFName from './PFName'
import { enabledSelector } from '../../../modules/reducerPointsFilter'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const PointsFilter = () => {
  const classes = useStyles()
  const filterEnabled = useSelector(enabledSelector)

  return (
    <Accordion show={filterEnabled} height={60}>
      <Grid
        container
        className={classes.root}
        spacing={1}
        alignContent="flex-start"
      >
        <Grid item xs={6}>
          <PFName />
        </Grid>
        <Grid item xs={6}>
          <PFFloor />
        </Grid>
      </Grid>
    </Accordion>
  )
}
export default PointsFilter
