export const navigateToMatterTagPromise = (sdk, sid) => {
  return new Promise((resolve, reject) => {
    if (!sdk) {
      return reject('SDK null')
    }
    sdk.Mattertag.preventAction(sid, {
      navigating: false,
      opening: true
    })
      .then(() => {
        sdk.Mattertag.navigateToTag(sid, sdk.Mattertag.Transition.INSTANT).then(
          () => {
            sdk.Mattertag.preventAction(sid, {
              navigating: true,
              opening: true
            }).then(() => {
              resolve()
            })
          }
        )
      })
      .catch((error) => resolve(error))
  })
}

export const addMattertagPromise = (sdk, newMattertagData) => {
  return new Promise((resolve, reject) => {
    if (!sdk) {
      return reject('SDK null')
    }
    sdk.Mattertag.add(newMattertagData)
      .then((sids) => {
        sdk.Mattertag.preventAction(sids[0], {
          opening: true,
          navigating: true
        }).then(() => {
          resolve(sids[0])
        })
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const replaceMattertagPromise = (sdk, sid, newMattertagData) => {
  return new Promise((resolve, reject) => {
    if (!sdk) {
      reject('SDK null')
    }
    sdk.Mattertag.remove(sid)
      .then(() => {
        addMattertagPromise(sdk, newMattertagData).then((sid) => {
          resolve(sid)
        })
      })
      .catch((error) => {
        reject(error)
      })
  })
}
