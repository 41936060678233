import { makeStyles } from '@material-ui/core/styles'

import Bar from '../../Toolbar/Bar'
import BarButton from '../../Toolbar/BarButton'
import SeparatorButton from '../../Toolbar/SeparatorButton'

const useStyles = makeStyles((theme) => ({
  topBar: {}
}))

const TETopBar = ({ tabs, currentTab, children, onChange }) => {
  const classes = useStyles()

  return (
    <Bar className={classes.topBar}>
      {tabs.map((tab) => (
        <BarButton
          key={tab.id}
          tooltipTitle={tab.name}
          tooltipEnabled
          disabled={tab.disabled}
          selected={currentTab === tab.id}
          onClick={() => onChange(tab.id)}
        >
          {tab.icon}
        </BarButton>
      ))}
      <SeparatorButton />
      {children}
    </Bar>
  )
}
export default TETopBar
