import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { VisibleIcon } from '../../Icons/Icons'
import BarButton from '../../Toolbar/BarButton'

import {
  setAllOriginalMattertagsVisibles,
  getNOriginalMattertagsShown,
  originalMattertagsSelector
} from '../../../modules/reducerMattertags'

const MLShowAllMattertagsButton = () => {
  const nOriginalMattertagsShown = useSelector(getNOriginalMattertagsShown)
  const originalMattertags = useSelector(originalMattertagsSelector)
  const dispatch = useDispatch()

  const handleShowAll = useCallback(() => {
    dispatch(setAllOriginalMattertagsVisibles(true))
  }, [dispatch])

  return (
    <BarButton
      tooltipTitle={'Mostrar todas'}
      tooltipEnabled
      disabled={originalMattertags.length === nOriginalMattertagsShown}
      onClick={handleShowAll}
    >
      <VisibleIcon />
    </BarButton>
  )
}
export default MLShowAllMattertagsButton
