import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import OKCancelButtons from '../../Common/Buttons/OKCancelButtons'
import GEForm from './GEForm'
import GEPointList from './GEPointList'
import GEAddPoint from './GEAddPoint'

import { getCurrentGroup, restoreGroup } from '../../../modules/reducerGroups'

import {
  LATERAL_CONTENT,
  setCurrentLateralContent
} from '../../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    maxHeight: '100%',
    minHeight: 0,
    flexGrow: 1,
    boxSizing: 'border-box',

    width: '100%'
  },
  contentWrapper: { overflow: 'auto', flex: '0 0 auto' }
}))

const GroupEditor = ({ small }) => {
  const currentGroup = useSelector(getCurrentGroup)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleOK = useCallback(() => {
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.GROUPS_LIST))
  }, [dispatch])

  const handleCancel = useCallback(() => {
    dispatch(restoreGroup())
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.GROUPS_LIST))
  }, [dispatch])

  return currentGroup ? (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <GEForm />
      </div>
      <GEAddPoint />
      <GEPointList />
      <OKCancelButtons onOk={handleOK} onCancel={handleCancel} />
    </div>
  ) : null
}
export default GroupEditor
