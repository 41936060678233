import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DraggableListItem from '../../Common/List/DraggableListItem'
import ListButton from '../../Common/List/ListButton'
import ShowButton from '../../Common/List/ShowButton'
import MLItemIcon from './MLItemIcon'
import { DetailsIcon, ImportIcon } from '../../Icons/Icons'
import {
  setCurrentLateralContent,
  LATERAL_CONTENT
} from '../../../modules/reducerMain'
import {
  currentOriginalMattertagSelector,
  getOriginalMattertag,
  setCurrentOriginalMattertag,
  setOriginalMatetertagVisible
} from '../../../modules/reducerMattertags'
import { currentTagSelector } from '../../../modules/reducerTagsSelectors'
import { updateTag } from '../../../modules/reducerTagsActions'
import { TAG_PROPERTIES } from '../../../modules/tag'

const MLItem = ({ sid, index, editorMode }) => {
  const currentOriginalMattertag = useSelector(currentOriginalMattertagSelector)
  const dispatch = useDispatch()
  const gOriginalMattertag = useMemo(getOriginalMattertag, [])
  const originalMattertag = useSelector((state) =>
    gOriginalMattertag(state, sid)
  )
  const currentTag = useSelector(currentTagSelector)

  const handleSelect = useCallback(() => {
    dispatch(setCurrentOriginalMattertag(sid))
  }, [dispatch, sid])

  const handleSelectMattertag = useCallback(() => {
    dispatch(updateTag(currentTag, TAG_PROPERTIES.ORIGINAL_MATTERTAG_SID, sid))
  }, [dispatch, currentTag, sid])

  const handleDetails = useCallback(() => {
    dispatch(setCurrentOriginalMattertag(sid))
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.MATTERTAGS_DETAILS))
  }, [dispatch, sid])

  const handleShow = useCallback(() => {
    dispatch(setOriginalMatetertagVisible(sid, !originalMattertag.visibleSid))
  }, [dispatch, sid, originalMattertag])

  return originalMattertag ? (
    <DraggableListItem
      key={originalMattertag.sid}
      id={originalMattertag.sid}
      index={index}
      currentItemId={currentOriginalMattertag}
      primaryText={sid}
      secondaryText={originalMattertag.label}
      icon={<MLItemIcon sid={sid} color={originalMattertag.color} />}
      hoverButtons={
        editorMode ? (
          <ListButton
            tooltipTitle="Seleccionar"
            onClick={handleSelectMattertag}
          >
            <ImportIcon />
          </ListButton>
        ) : (
          <ListButton tooltipTitle="Detalles" onClick={handleDetails}>
            <DetailsIcon />
          </ListButton>
        )
      }
      buttons={
        <ShowButton
          visible={originalMattertag.visibleSid}
          onChange={handleShow}
        />
      }
      onSelect={handleSelect}
    ></DraggableListItem>
  ) : null
}
export default MLItem
