import { useCallback, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline ': {
      borderColor: theme.palette.primary.warning
    }
  },
  notchedOutline: {}
}))

const EditorTextField = ({
  label,
  value,
  warning,
  error,
  onChange,
  ...props
}) => {
  const classes = useStyles({ warning: true })
  const [localValue, setLocalValue] = useState(value)
  useEffect(() => {
    setLocalValue(value) //Deja esto
  }, [value])

  const updateExternalValue = useCallback(() => {
    if (onChange) {
      onChange(localValue)
    }
    setLocalValue(value)
  }, [onChange, localValue, value])

  const handleBlur = useCallback(
    (e) => {
      updateExternalValue()
    },
    [updateExternalValue]
  )

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        updateExternalValue()
      }
    },
    [updateExternalValue]
  )

  const handleChange = useCallback((e) => {
    setLocalValue(e.target.value)
  }, [])

  return (
    <TextField
      label={label}
      value={localValue}
      error={error}
      InputProps={{
        classes: {
          root: warning && classes.root,
          notchedOutline: warning && classes.notchedOutline
        }
      }}
      InputLabelProps={{
        classes: {
          root: warning && classes.rootLabel
        }
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      {...props}
    />
  )
}
export default EditorTextField
