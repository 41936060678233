import { createSelector } from 'reselect'
import { sdkSelector } from './reducerMatterport'
import { mattertagIconSelector } from './reducerMatterportIcons'
import {
  addMattertagPromise,
  navigateToMatterTagPromise
} from '../utils/matterportUtils'
import { swap } from '../utils/utils'

export const MATTERTAG_SET_ORIGINAL_MATTERTAGS =
  'MATTERTAG_SET_ORIGINAL_MATTERTAGS'
export const MATTERTAG_SET_MATTERTAGS = 'MATTERTAG_SET_MATTERTAGS'
export const MATTERTAG_SET_CURRENT_ORIGINAL_MATTERTAG =
  'MATTERTAG_SET_CURRENT_ORIGINAL_MATTERTAG'
export const MATTERTAG_SET_MATTERTAG_VISIBLE = 'MATTERTAG_SET_MATTERTAG_VISIBLE'
export const MATTERTAG_SWAP_BY_ID = 'MATTERTAG_SWAP_BY_ID'
const initialState = {
  originalMattertags: [], //Mattertag originales del modelo
  mattertags: [], //Mattertags visibles en el modelo, tanto originalMattertags
  //como tags
  currentOriginalMattertag: ''
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case MATTERTAG_SET_ORIGINAL_MATTERTAGS: {
      const originalMattertags = []
      for (let tagSid in action.collection) {
        originalMattertags.push(
          JSON.parse(JSON.stringify(action.collection[tagSid]))
        )
      }
      return { ...state, originalMattertags }
    }
    case MATTERTAG_SET_MATTERTAGS: {
      const mattertags = []
      for (let tagSid in action.collection) {
        mattertags.push({
          sid: tagSid,
          floorId: action.collection[tagSid].floorInfo.id
        })
      }
      return { ...state, mattertags }
    }

    case MATTERTAG_SET_MATTERTAG_VISIBLE:
      const originalMattertags = state.originalMattertags.map((mt) => {
        if (mt.sid === action.sid) {
          return { ...mt, visibleSid: action.show ? action.visibleSid : '' }
        }
        return mt
      })
      return {
        ...state,
        originalMattertags
      }
    case MATTERTAG_SET_CURRENT_ORIGINAL_MATTERTAG:
      return { ...state, currentOriginalMattertag: action.sid }
    case MATTERTAG_SWAP_BY_ID:
      const fromIdx = state.originalMattertags.findIndex(
        (mt) => mt.sid === action.fromId
      )
      const toIdx = state.originalMattertags.findIndex(
        (mt) => mt.sid === action.toId
      )
      return {
        ...state,
        originalMattertags: swap([...state.originalMattertags], fromIdx, toIdx)
      }
    default:
      return state
  }
}
export default changeState

export const setMattertags = (collection) => ({
  type: MATTERTAG_SET_MATTERTAGS,
  collection
})

export const swapMattertags = (fromId, toId) => ({
  type: MATTERTAG_SWAP_BY_ID,
  fromId,
  toId
})

export const setOriginalMattertags = (collection) => (dispatch, getState) => {
  const state = getState()
  const sdk = sdkSelector(state)
  if (!sdk) {
    return
  }
  sdk.Mattertag.remove(Object.getOwnPropertyNames(collection))
    .then(() =>
      dispatch({
        type: MATTERTAG_SET_ORIGINAL_MATTERTAGS,
        collection
      })
    )
    .catch((error) => alert(error))
}

export const setAllOriginalMattertagsVisibles = (show) => (
  dispatch,
  getState
) => {
  const state = getState()
  const originalMattertags = originalMattertagsSelector(state)
  for (let mt of originalMattertags) {
    dispatch(setOriginalMatetertagVisible(mt.sid, show))
  }
}

export const setOriginalMatetertagVisible = (sid, show) => (
  dispatch,
  getState
) => {
  const state = getState()
  const sdk = sdkSelector(state)
  if (!sdk) {
    return
  }
  const originalMattertags = originalMattertagsSelector(state)
  const mattertag = originalMattertags.find((mt) => mt.sid === sid)
  if (!mattertag) {
    return
  }
  if (show && !mattertag.visibleSid) {
    //Mostar
    addMattertagPromise(sdk, mattertag)
      .then((newSid) => {
        const icon = mattertagIconSelector(state)
        sdk.Mattertag.editIcon(newSid, icon.id)
          .then(() =>
            dispatch({
              type: MATTERTAG_SET_MATTERTAG_VISIBLE,
              sid,
              visibleSid: newSid,
              show: true
            })
          )
          .catch((error) => {
            console.log(error)
            dispatch({
              type: MATTERTAG_SET_MATTERTAG_VISIBLE,
              sid,
              visibleSid: newSid,
              show: true
            })
          })
      })
      .catch((error) => alert(error))
  } else if (!show && mattertag.visibleSid) {
    //Ocultar
    sdk.Mattertag.remove(mattertag.visibleSid).then(() =>
      dispatch({
        type: MATTERTAG_SET_MATTERTAG_VISIBLE,
        visibleSid: '',
        sid: sid,
        show: false
      })
    )
  }
}

export const goToMattertag = (sid) => (dispatch, getState) => {
  const state = getState()
  const sdk = sdkSelector(state)
  if (!sdk) {
    return
  }
  const mattertags = originalMattertagsSelector(state)
  const mattertag = mattertags.find((mt) => mt.sid === sid)

  if (mattertag && mattertag.visibleSid) {
    navigateToMatterTagPromise(sdk, mattertag.visibleSid)
      .then()
      .catch((error) => alert(error))
  }
}

export const setCurrentOriginalMattertag = (sid) => (dispatch, getState) => {
  const state = getState()
  const mattertags = originalMattertagsSelector(state)
  const mattertag = mattertags.find((mt) => mt.sid === sid)
  if (!mattertag) {
    return
  }
  const sdk = sdkSelector(state)
  if (!sdk) {
    dispatch({
      type: MATTERTAG_SET_CURRENT_ORIGINAL_MATTERTAG,
      sid
    })
  }

  if (mattertag.visibleSid) {
    navigateToMatterTagPromise(sdk, mattertag.visibleSid)
      .then(() =>
        dispatch({
          type: MATTERTAG_SET_CURRENT_ORIGINAL_MATTERTAG,
          sid
        })
      )
      .catch((error) => alert(error))
  } else {
    dispatch({
      type: MATTERTAG_SET_CURRENT_ORIGINAL_MATTERTAG,
      sid
    })
  }
}
/*
export const setCurrentOriginalMattertag = (sid) => ({
  type: MATTERTAG_SET_CURRENT_ORIGINAL_MATTERTAG,
  sid
})
*/
export const originalMattertagsSelector = (state) =>
  state.mattertags.originalMattertags
export const mattertagsSelector = (state) => state.mattertags.mattertags
export const currentOriginalMattertagSelector = (state) =>
  state.mattertags.currentOriginalMattertag

export const getOriginalMattertag = () =>
  createSelector([originalMattertagsSelector, (_, sid) => sid], (tags, sid) => {
    return tags.find((tag) => tag.sid === sid)
  })

export const getNOriginalMattertagsShown = createSelector(
  [originalMattertagsSelector],
  (originalMattertags) => {
    return originalMattertags.reduce((acc, mt) => {
      if (mt.visibleSid) {
        acc++
      }
      return acc
    }, 0)
  }
)
