import { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'

import { sdkSelector, boundsSelector } from '../../modules/reducerMatterport'
import {
  setCurrentTagPosition,
  setCurrentTagDirection,
  setCurrentTagStemVector
} from '../../modules/reducerTagsActions'

import { getCurrentTag } from '../../modules/reducerTagsSelectors'

import {
  cursorEnabledSelector,
  setCursorEnabled
} from '../../modules/reducerMatterportCursor'

const useStyles = makeStyles((theme) => ({
  overlay: {
    touchAction: 'none',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    backgroundColor: 'transparent' //'#70707070'
  },
  dialog: {
    position: 'absolute',
    left: ({ position }) => position.x,
    top: ({ position }) => position.y,
    transform: 'translate(-50%,0%)',

    backgroundColor: 'transparent',
    zIndex: 11
  },
  iconButton: {
    border: '1px solid black',
    background: 'white',
    color: theme.palette.primary.main,
    '&:hover': { color: theme.palette.secondary.main, background: 'white' }
  }
}))

const ORIENTATIONS = [
  { x: 0, y: 0, z: 1 },
  { x: 0, y: 1, z: 0 },
  { x: 1, y: 0, z: 0 },
  { x: 0, y: 0, z: -1 },
  { x: 0, y: -1, z: 0 },
  { x: -1, y: 0, z: 0 }
]
const MatterportCursor = () => {
  const [bk, setBk] = useState(null)
  const [orientation, setOrientation] = useState(0)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const classes = useStyles({ position })
  const sdk = useSelector(sdkSelector)
  const enabled = useSelector(cursorEnabledSelector)
  const bounds = useSelector(boundsSelector)
  const currentTag = useSelector(getCurrentTag)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!bk && currentTag && enabled) {
      setBk({
        position: currentTag.position,
        stemVector: currentTag.stemVector
      })
    }
    if (!enabled) {
      setBk(null)
    }
  }, [enabled, currentTag, bk])

  const handleCursor = useCallback(
    (e) => {
      setPosition({ x: e.clientX - bounds.x, y: e.clientY - bounds.y })
      sdk.Renderer.getWorldPositionData({
        x: e.clientX - bounds.x,
        y: e.clientY - bounds.y
      }).then((data) => {
        if (data.position) {
          dispatch(setCurrentTagPosition(data.position))
        }
      })
    },
    [dispatch, sdk, bounds]
  )
  const handleOk = useCallback(
    (e) => {
      dispatch(setCursorEnabled(false))
    },
    [dispatch]
  )
  const handleCancel = useCallback(
    (e) => {
      if (bk) {
        dispatch(setCurrentTagPosition(bk.position))
        dispatch(setCurrentTagStemVector(bk.stemVector))
      }
      dispatch(setCursorEnabled(false))
    },
    [dispatch, bk]
  )

  const handleRotate = useCallback(
    (e) => {
      dispatch(setCurrentTagDirection(ORIENTATIONS[orientation]))
      setOrientation((orientation + 1) % ORIENTATIONS.length)
    },
    [dispatch, orientation]
  )

  return enabled ? (
    <>
      <div
        className={classes.overlay}
        onClick={handleCursor}
        onMouseMove={handleCursor}
      />
      <div className={classes.dialog}>
        <IconButton
          size={'small'}
          className={classes.iconButton}
          onClick={handleRotate}
        >
          <Rotate90DegreesCcwIcon />
        </IconButton>
        <IconButton
          size={'small'}
          className={classes.iconButton}
          onClick={handleOk}
        >
          <DoneIcon />
        </IconButton>
        <IconButton
          size={'small'}
          className={classes.iconButton}
          onClick={handleCancel}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </>
  ) : null
}
export default MatterportCursor
