import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { originalMattertagsSelector } from '../../../../modules/reducerMattertags'
import { updateTag } from '../../../../modules/reducerTagsActions'
import { TAG_PROPERTIES } from '../../../../modules/tag'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between'
  }
}))

const TEOMSelect = ({ id, value }) => {
  const classes = useStyles()
  const originalMattertags = useSelector(originalMattertagsSelector)
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (e) => {
      dispatch(
        updateTag(id, TAG_PROPERTIES.ORIGINAL_MATTERTAG_SID, e.target.value)
      )
    },
    [dispatch, id]
  )

  return (
    <TextField
      select
      label="Mattertag original"
      value={value}
      onChange={updateTagValue}
      SelectProps={{ classes: { root: classes.menuItem } }}
    >
      {originalMattertags.map((mt, idx) => {
        return (
          <MenuItem key={idx} value={mt.sid} className={classes.menuItem}>
            <span>{mt.sid}</span>
            <span>{mt.label}</span>
          </MenuItem>
        )
      })}
    </TextField>
  )
}
export default TEOMSelect
