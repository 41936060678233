// import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { getCurrentTag } from '../../../../modules/reducerTagsSelectors'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    boxSizing: 'border-box',
    paddingTop: 16,
    padding: 0,
    margin: 0,
    overflow: 'auto'
  },
  fieldset: {
    minHeight: 0,
    flex: '1 1 auto',
    borderRadius: 4,
    margin: 8
  },
  legend: { color: theme.palette.primary.contrastText }
}))

const TagsEditorContent = () => {
  const tag = useSelector(getCurrentTag)
  const classes = useStyles()

  return tag ? (
    <Grid
      container
      className={classes.root}
      spacing={2}
      alignContent="flex-start"
    >
      <Grid item xs={12}>
        Contenido
      </Grid>
    </Grid>
  ) : null
}
export default TagsEditorContent
