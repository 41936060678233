// import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import PEName from './PEName'
import PEDescription from './PEDescription'

import { getCurrentPoint } from '../../../modules/reducerPoints'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    boxSizing: 'border-box',
    paddingTop: 16,
    margin: 0,

    width: '100%',
    // height: '100%',
    // maxHeight: '200px',
    overflow: 'auto'

    // display: 'flex',
    // flexFlow: 'column'
  }
}))

const PEForm = ({ small }) => {
  const point = useSelector(getCurrentPoint)
  const classes = useStyles()

  return point ? (
    <Grid
      container
      className={classes.root}
      spacing={2}
      alignContent="flex-start"
    >
      <Grid item xs={12}>
        <PEName id={point.id} name={point.name} />
      </Grid>
      <Grid item xs={12}>
        <PEDescription id={point.id} description={point.description} />
      </Grid>
    </Grid>
  ) : null
}
export default PEForm
//
