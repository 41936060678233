import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import EditorTextField from '../../Common/Input/EditorTextField'
import {
  updatePointProperty,
  POINT_PROPERTIES
} from '../../../modules/reducerPoints'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const PEDescription = ({ id, description }) => {
  // const tag = useSelector(getCurrentTag)
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (value) => {
      dispatch(updatePointProperty(id, POINT_PROPERTIES.DESCRIPTION, value))
    },
    [dispatch, id]
  )

  return (
    <EditorTextField
      label="Descripción"
      value={description}
      multiline
      rowsMax={4}
      onChange={updateTagValue}
    />
  )
}
export default PEDescription
