import { createMuiTheme } from '@material-ui/core/styles'
// import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
// const breakpoints = createBreakpoints({})

let matterportEditorTheme = createMuiTheme({
  sizes: {
    mainMenu: { width: 100 },
    menu: {
      iconWidth: 24,
      width: 500,
      paddingLeft: 24,
      paddingLeftClosed: 12,
      widthClosed: 24 + 2 * 12, //iconWidth+2*paddingLeftClosed
      borderWidth: 4
    }
  },
  typography: {
    fontFamily: `Futura Lt Bt Light ,Roboto, Helvetica, Arial, sans-serif`,
    h1: { fontSize: '24px' },
    h2: { fontSize: '20px' },
    h3: { fontSize: '16px' },
    h4: { fontSize: '14px' },
    h5: { fontSize: '12px' },
    h6: { fontSize: '8px' },

    subtitle1: { fontSize: '16px', textAlign: 'center' }
  },
  palette: {
    primary: {
      main: '#000F31',
      mainTransparent: '#000F31E0',
      disabled: '#707070', //'rgba(0, 0, 0, 0.38)',
      warning: 'green'
    },
    secondary: {
      main: '#CFA57E ',
      mainTransparent: '#CFA57EE0',
      contrastText: '#FFFFFF'
    },
    default: { main: '#CFA57E ', contrastText: '#FFFFFF' },
    other: { background: 'white', detail: '#bdbdbd', greyTable: '#5c667b' },
    textInput: { main: '#FFFFFF', dark: '#EEEEEE', contrastText: '#000000' }
  }
})

matterportEditorTheme.palette = {
  ...matterportEditorTheme.palette
}

matterportEditorTheme.props = {
  MuiButton: {
    variant: 'outlined',
    disableFocusRipple: 'true',
    disableRipple: 'true'
  },
  MuiTextField: {
    variant: 'outlined',
    fullWidth: true,
    margin: 'none',
    color: 'secondary',
    size: 'small'
  },
  // MuiInputLabel: {
  //   shrink: true,
  //   error: false
  // },
  MuiInput: {
    // disableUnderline: true
  }
}
//
matterportEditorTheme.overrides = {
  MuiButton: {
    root: { textTransform: 'none', padding: 0 },
    outlined: {
      color: matterportEditorTheme.palette.primary.contrastText,
      borderColor: matterportEditorTheme.palette.primary.contrastText,
      '&:hover': {
        color: matterportEditorTheme.palette.primary.contrastText,
        borderColor: matterportEditorTheme.palette.secondary.main
      }
    }
  },
  MuiTextField: { root: {} },
  MuiFormLabel: {
    root: { color: matterportEditorTheme.palette.primary.contrastText }
  },
  MuiOutlinedInput: {
    root: {
      '& $notchedOutline': {
        borderColor: matterportEditorTheme.palette.primary.contrastText
      },
      '&:hover $notchedOutline': {
        borderColor: matterportEditorTheme.palette.secondary.main
      }
      // '&$error $notchedOutline ': {
      //   borderColor: 'green'
      // }
    }
  },
  MuiInputBase: {
    root: { color: matterportEditorTheme.palette.primary.contrastText }
  },
  MuiInput: {
    root: {
      color: matterportEditorTheme.palette.secondary.contrastText,
      // fontSize: matterportEditorTheme.typography.h4.fontSize,
      backgroundColor: matterportEditorTheme.palette.secondary.main,
      borderColor: matterportEditorTheme.palette.secondary.main,
      '&$focused': {
        backgroundColor: matterportEditorTheme.palette.secondary.main
      }
    }
  }
}
// PDFViewerTheme = responsiveFontSizes(PDFViewerTheme)
export default matterportEditorTheme
