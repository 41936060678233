import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import EditorTextField from '../../Common/Input/EditorTextField'

import {
  setLabel,
  labelSelector
} from '../../../modules/reducerMattertagsFilter'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const MTFLabel = () => {
  // const classes = useStyles()
  const label = useSelector(labelSelector)
  const dispatch = useDispatch()

  const handleLabelChange = useCallback(
    (value) => {
      dispatch(setLabel(value))
    },
    [dispatch]
  )
  return (
    <EditorTextField label="Label" value={label} onChange={handleLabelChange} />
  )
}
export default MTFLabel
