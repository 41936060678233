import { combineReducers } from 'redux'
import reducerTags from './reducerTags'
import reducerMain from './reducerMain'
import reducerMatterport from './reducerMatterport'
import reducerOpenSave from './reducerOpenSave'
import reducerTagsFilter from './reducerTagsFilter'
import reducerMatterportIcons from './reducerMatterportIcons'
import reducerMattertags from './reducerMattertags'
import reducerMattertagsFilter from './reducerMattertagsFilter'
import reducerMatterportCursor from './reducerMatterportCursor'
import reducerLateral from './reducerLateral'
import reducerPoints from './reducerPoints'
import reducerPointsFilter from './reducerPointsFilter'
import reducerGroups from './reducerGroups'

export default combineReducers({
  main: reducerMain,
  tags: reducerTags,
  tagsFilter: reducerTagsFilter,
  matterport: reducerMatterport,
  openSave: reducerOpenSave,
  matterportIcons: reducerMatterportIcons,
  mattertags: reducerMattertags,
  mattertagsFilter: reducerMattertagsFilter,
  cursor: reducerMatterportCursor,
  lateral: reducerLateral,
  points: reducerPoints,
  pointsFilter: reducerPointsFilter,
  groups: reducerGroups
})
