import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTransition, animated } from 'react-spring'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px',
    boxSizing: 'border-box',
    flex: '1 0 auto',
    overflow: ({ animating }) => (animating ? 'hidden' : 'auto')
  }
}))

const Accordion = ({ show, height, children }) => {
  const [animating, setAnimating] = useState(false)
  const classes = useStyles({ animating })
  // const dispatch = useDispatch()

  const transition = useTransition(show, {
    onRest: () => {
      setAnimating(false)
    },
    onStart: (o) => {
      setAnimating(true)
    },
    from: { opacity: 0, maxHeight: '0px', padding: 0 },
    enter: {
      opacity: 1,
      maxHeight: height + 'px',
      padding: 8
    },
    leave: { opacity: 0, maxHeight: '0px', padding: 0 }
  })

  return transition((styles, item) =>
    item ? (
      <animated.div style={styles} className={classes.root}>
        {children}
      </animated.div>
    ) : null
  )
}
export default Accordion
