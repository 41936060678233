import { useCallback } from 'react'
import BarButton from './BarButton'
import { VisibleIcon } from '../Icons/Icons'

const ToggleVisibilityButton = ({ visible, onChange }) => {
  const handleClick = useCallback(
    (e) => {
      if (onChange) {
        onChange(!visible)
      }
    },
    [visible, onChange]
  )

  return (
    <BarButton
      tooltipTitle={visible ? 'Ocultar' : 'Mostrar'}
      tooltipEnabled
      selected={visible}
      onClick={handleClick}
    >
      <VisibleIcon />
    </BarButton>
  )
}
export default ToggleVisibilityButton
