import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import FloatValue from '../../../Common/Input/FloatValue'
import { updateTag } from '../../../../modules/reducerTagsActions'
import { TAG_PROPERTIES } from '../../../../modules/tag'
// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

export const TEPBOffsetX = ({ id, value, warning }) => {
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (value) => {
      dispatch(updateTag(id, TAG_PROPERTIES.PB_OFFSET_X, value))
    },
    [dispatch, id]
  )

  return (
    <FloatValue
      label="Offset X"
      value={value}
      warning={warning}
      onChange={updateTagValue}
    />
  )
}

export const TEPBOffsetY = ({ id, value }) => {
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (value) => {
      dispatch(updateTag(id, TAG_PROPERTIES.PB_OFFSET_Y, value))
    },
    [dispatch, id]
  )

  return <FloatValue label="Offset Y" value={value} onChange={updateTagValue} />
}
