import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import LBOpenFileButton from './LBOpenFileButton'
import LBSaveFileButton from './LBSaveFileButton'
import LBButton from './LBButton'
import {
  menuOptionsSelector,
  setCurrentMenuOption,
  MENU_OPTIONS
} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'row',
    borderRight: `1px solid ${theme.palette.primary.main}`
  },
  optionsWrapper: {
    backgroundColor: theme.palette.primary.light,
    boxSizing: 'border-box',
    padding: '20px 4px',
    height: '100%',
    display: 'flex',
    flexFlow: 'column'
  }
}))

const LateralBar = () => {
  const classes = useStyles()
  const options = useSelector(menuOptionsSelector)

  const dispatch = useDispatch()

  const handleClick = useCallback(
    (optionId) => {
      dispatch(setCurrentMenuOption(optionId))
    },
    [dispatch]
  )
  const getOptionButton = (option) => {
    if (option.id === MENU_OPTIONS.OPEN) {
      return <LBOpenFileButton key={option.id} />
    } else if (option.id === MENU_OPTIONS.SAVE) {
      return <LBSaveFileButton key={option.id} />
    } else {
      return (
        <LBButton
          key={option.id}
          name={option.name}
          optionId={option.id}
          disabled={option.disabled}
          onClick={handleClick}
        />
      )
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.optionsWrapper}>
        {options.map((option) => {
          return getOptionButton(option)
        })}
      </div>
    </div>
  )
}
export default LateralBar
