import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { modelLoadedSelector } from '../../../modules/reducerMatterport'
import {
  MATTERTAG_STATES,
  stateSelector,
  setState
} from '../../../modules/reducerMattertagsFilter'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const MTFState = () => {
  // const classes = useStyles()
  const state = useSelector(stateSelector)
  const modelLoaded = useSelector(modelLoadedSelector)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (e) => {
      dispatch(setState(e.target.value))
    },
    [dispatch]
  )
  return (
    <TextField
      label="Estado"
      select
      disabled={!modelLoaded}
      value={state}
      onChange={handleChange}
    >
      {MATTERTAG_STATES.map((st) => (
        <MenuItem key={st.id} value={st.id}>
          {st.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
export default MTFState
