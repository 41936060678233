import { makeStyles } from '@material-ui/core/styles'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 8,
    boxSizing: 'border-box',
    width: '100%',
    height: 40, //'calc(100% - 0px)',
    margin: 0,
    border: '1px solid',
    borderRadius: 4,
    borderColor: ({ disabled, error, warning }) =>
      disabled
        ? theme.palette.primary.disabled
        : error
        ? theme.palette.primary.error
        : warning
        ? theme.palette.primary.warning
        : theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      borderColor: ({ disabled }) =>
        disabled ? theme.palette.primary.disabled : theme.palette.secondary.main
    },
    '&:disabled': { color: theme.palette.primary.disabled }
  },
  checkbox: {
    '&:hover': { color: theme.palette.secondary.main },
    color: 'inherit',
    padding: '4px'
  }
}))

const ToggleButton = ({
  label,
  checked,
  disabled,
  warning,
  error,
  children,
  onChange
}) => {
  const classes = useStyles({ disabled, warning, error })

  return (
    <FormControlLabel
      disabled={disabled}
      className={classes.root}
      control={
        <Checkbox
          className={classes.checkbox}
          size="small"
          checked={checked}
          onChange={onChange ? onChange : null}
        />
      }
      label={label}
    />
  )
}
export default ToggleButton
