import { createSelector } from 'reselect'
// import { sdkSelector } from './reducerMatterport'

import { pointsSelector } from './reducerPoints'
// import { findById } from '../utils/utils'

export const POINTS_FILTER_SET_ENABLED = 'POINTS_FILTER_SET_ENABLED'
export const POINTS_FILTER_RESET = 'POINTS_FILTER_RESET'
export const POINTS_FILTER_UPDATE_PROPERTY = 'POINTS_FILTER_UPDATE_PROPERTY'

export const POINTS_FILTER_PROPERTIES = {
  NAME: 'name',
  FLOOR_ID: 'floorId'
}

const initialState = {
  currentPoint: '',
  enabled: false,
  floorId: '',
  name: ''
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case POINTS_FILTER_SET_ENABLED:
      return { ...state, enabled: action.enabled }
    case POINTS_FILTER_RESET:
      return stateClearFilter(state)
    case POINTS_FILTER_UPDATE_PROPERTY:
      return stateUpdateProperty(state, action.property, action.value)
    default:
      return state
  }
}
export default changeState

const stateClearFilter = (state) => {
  return {
    ...state,
    floorId: '',
    name: ''
  }
}

const stateUpdateProperty = (state, property, value) => {
  const newState = { ...state }
  newState[property] = value
  return newState
}

export const setFilterEnable = (enabled) => ({
  type: POINTS_FILTER_SET_ENABLED,
  enabled
})

export const enableFilter = () => ({
  type: POINTS_FILTER_SET_ENABLED,
  enabled: true
})

export const disableFilter = () => ({
  type: POINTS_FILTER_SET_ENABLED,
  enabled: false
})

export const resetFilter = () => ({
  type: POINTS_FILTER_RESET
})

export const updateProperty = (property, value) => ({
  type: POINTS_FILTER_UPDATE_PROPERTY,
  property,
  value
})

export const setName = (value) => ({
  type: POINTS_FILTER_UPDATE_PROPERTY,
  property: POINTS_FILTER_PROPERTIES.NAME,
  value
})

export const setFloor = (value) => ({
  type: POINTS_FILTER_UPDATE_PROPERTY,
  property: POINTS_FILTER_PROPERTIES.FLOOR_ID,
  value
})

export const currentPointSelector = (state) => state.pointsFilter.currentPoint
export const enabledSelector = (state) => state.pointsFilter.enabled
export const floorIdSelector = (state) => state.pointsFilter.floorId
export const nameSelector = (state) => state.pointsFilter.name

export const getFilteredPoints = createSelector(
  [pointsSelector, enabledSelector, floorIdSelector, nameSelector],
  (points, enabled, floorId, name) => {
    if (!enabled) {
      return points
    }
    let res = points
    //Planta
    if (floorId) {
      // res = res.filter((tag) => {
      //   const mtFloor = currentMattertagsFloor.find((mt) => mt.sid === tag.sid)
      //   if (mtFloor) {
      //     return mtFloor.floorId === floorId
      //   }
      //   return false
      // })
    }
    //Nombre
    if (name) {
      res = res.filter((tag) => tag.name.includes(name))
    }
    return res
  }
)
