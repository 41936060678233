import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RemoveIcon, AddDuplicateIcon, EditIcon } from '../../Icons/Icons'
import TLItemIcon from './TLItemIcon'
import DraggableListItem from '../../Common/List/DraggableListItem'
import ListButton from '../../Common/List/ListButton'

import {
  setCurrentLateralContent,
  LATERAL_CONTENT
} from '../../../modules/reducerMain'
import {
  currentTagSelector,
  getTag
} from '../../../modules/reducerTagsSelectors'
import {
  setCurrentTag,
  removeTag,
  insertTag
} from '../../../modules/reducerTagsActions'

const TLItem = ({ id, index }) => {
  const currentTag = useSelector(currentTagSelector)
  const dispatch = useDispatch()
  const gTag = useMemo(getTag, [])
  const tag = useSelector((state) => gTag(state, id))

  const handleSelect = useCallback(() => {
    dispatch(setCurrentTag(id))
  }, [dispatch, id])

  const handleEdit = useCallback(() => {
    dispatch(setCurrentTag(id))
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.EDIT_TAG))
  }, [dispatch, id])

  const handleAdd = useCallback(() => {
    dispatch(insertTag(index + 1, id))
  }, [dispatch, id, index])

  const handleRemove = useCallback(() => {
    dispatch(removeTag(id))
  }, [dispatch, id])

  return tag ? (
    <DraggableListItem
      key={tag.id}
      id={tag.id}
      index={index}
      currentItemId={currentTag}
      primaryText={tag.name}
      secondaryText={tag.description}
      icon={<TLItemIcon id={tag.id} color={tag.color} iconId={tag.icon} />}
      onSelect={handleSelect}
      hoverButtons={
        <>
          <ListButton tooltipTitle="Editar" onClick={handleEdit}>
            <EditIcon />
          </ListButton>
          <ListButton tooltipTitle="Añadir/Duplicar" onClick={handleAdd}>
            <AddDuplicateIcon />
          </ListButton>
          <ListButton tooltipTitle="Borrar" onClick={handleRemove}>
            <RemoveIcon />
          </ListButton>
        </>
      }
    ></DraggableListItem>
  ) : null
}
export default TLItem
