import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import DraggableList from '../../Common/List/DraggableList'
import EditableListEmptyItem from '../../Common/List/EditableListEmptyItem'
import GLItem from './GLItem'
import {
  groupsSelector,
  insertGroup,
  swapGroups
} from '../../../modules/reducerGroups'

const GLList = () => {
  const groups = useSelector(groupsSelector)
  const dispatch = useDispatch()

  const onDragEnd = (fromIdx, toIdx) => {
    dispatch(swapGroups(groups[fromIdx].id, groups[toIdx].id))
  }

  const handleAdd = useCallback(
    (e) => {
      dispatch(insertGroup(0, ''))
    },
    [dispatch]
  )

  return groups.length === 0 ? (
    <EditableListEmptyItem text={'Añadir grupo'} onAdd={handleAdd} />
  ) : (
    <DraggableList onSwap={onDragEnd}>
      {groups.map((gr, index) => (
        <GLItem key={gr.id} id={gr.id} index={index} />
      ))}
    </DraggableList>
  )
}
export default GLList
