import { createSelector } from 'reselect'
import { findById, getNotUsedNameObjects, swap } from '../utils/utils'
import {
  pointsSelector,
  POINTS_REMOVE,
  POINTS_REMOVE_ALL
} from './reducerPoints'

export const GROUPS_SET_GROUPS = 'GROUPS_SET_GROUPS'
export const GROUPS_INSERT = 'GROUPS_INSERT'
export const GROUPS_REMOVE = 'GROUP_REMOVE'
export const GROUPS_REMOVE_ALL = 'GROUP_REMOVE_ALL'
export const GROUPS_UPDATE_PROPERTY = 'GROUP_UPDATE_PROPERTY'
export const GROUPS_SET_CURRENT_GROUP = 'GROUP_SET_CURRENT_POINT'
export const GROUPS_REPLACE = 'GROUP_REPLACE'
export const GROUPS_SWAP = 'GROUP_SWAP'

export const GROUPS_INSERT_POINT = 'GROUPS_INSERT_POINT'
export const GROUPS_REMOVE_POINT = 'GROUPS_REMOVE_POINT'
export const GROUPS_REMOVE_ALL_POINTS = 'GROUPS_REMOVE_ALL_POINTS'
export const GROUPS_SWAP_POINTS = 'GROUPS_SWAP_POINTS'

export const GROUP_PROPERTIES = {
  NAME: 'name',
  DESCRIPTION: 'description'
}

const Group = () => {
  return { id: 'g0', name: 'Group', description: '', points: [] }
}

const initialState = {
  groups: [],
  currentGroup: '',
  backup: null
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case POINTS_REMOVE: {
      const newGroups = state.groups.map((group) => {
        return {
          ...group,
          points: group.points.filter((pointId) => pointId !== action.id)
        }
      })
      return { ...state, groups: newGroups }
    }
    case POINTS_REMOVE_ALL: {
      const newGroups = state.groups.map((group) => {
        return { ...group, points: [] }
      })
      return { ...state, groups: newGroups }
    }
    case GROUPS_INSERT_POINT: {
      const newGroups = state.groups.map((group) => {
        if (
          group.id === action.groupId &&
          group.points.indexOf(action.pointId) < 0
        ) {
          const points = [...group.points]
          points.splice(action.position, 0, action.pointId)
          return { ...group, points: points }
        }
        return group
      })
      return { ...state, groups: newGroups }
    }
    case GROUPS_REMOVE_POINT: {
      const newGroups = state.groups.map((group) => {
        if (group.id === action.groupId) {
          const points = group.points.filter(
            (pointId) => pointId !== action.pointId
          )
          return { ...group, points: points }
        }
        return group
      })
      return { ...state, groups: newGroups }
    }
    case GROUPS_REMOVE_ALL_POINTS: {
      const newGroups = state.groups.map((group) => {
        if (group.id === action.groupId) {
          return { ...group, points: [] }
        }
        return group
      })
      return { ...state, groups: newGroups }
    }
    case GROUPS_SWAP_POINTS: {
      const newGroups = state.groups.map((group) => {
        if (group.id === action.groupId) {
          const fromIdx = group.points.indexOf(action.fromId)
          const toIdx = group.points.indexOf(action.toId)
          return { ...group, points: swap([...group.points], fromIdx, toIdx) }
        }
        return group
      })
      return { ...state, groups: newGroups }
    }
    case GROUPS_SET_CURRENT_GROUP:
      let group = findById(state.groups, action.id)
      if (group) {
        group = JSON.parse(JSON.stringify(group))
        return { ...state, currentGroup: action.id, backup: group }
      }
      return state
    case GROUPS_SET_GROUPS:
      return { ...state, groups: action.groups, currentGroup: '' }
    case GROUPS_INSERT:
      const newGroups = [...state.groups]
      newGroups.splice(action.position, 0, action.group)
      return { ...state, groups: newGroups }
    case GROUPS_REMOVE:
      return {
        ...state,
        groups: state.groups.filter((gr) => gr.id !== action.id),
        currentGroup: state.currentGroup === action.id ? '' : state.currentGroup
      }
    case GROUPS_REMOVE_ALL:
      return { ...state, currentGroup: '', groups: [] }
    case GROUPS_UPDATE_PROPERTY:
      return stateUpdateProperty(
        state,
        action.id,
        action.property,
        action.value
      )
    case GROUPS_REPLACE: {
      const groups = state.groups.map((gr) => {
        if (gr.id === action.groupId) {
          return action.newGroup
        }
        return gr
      })
      return { ...state, groups }
    }
    case GROUPS_SWAP:
      const fromIdx = state.groups.findIndex((gr) => gr.id === action.fromId)
      const toIdx = state.groups.findIndex((gr) => gr.id === action.toId)
      return { ...state, groups: swap([...state.groups], fromIdx, toIdx) }
    default:
      return state
  }
}
export default changeState

const stateUpdateProperty = (state, id, property, value) => {
  const groups = state.groups.map((gr) => {
    if (gr.id === id) {
      switch (property) {
        case GROUP_PROPERTIES.NAME:
          return { ...gr, name: value }
        case GROUP_PROPERTIES.DESCRIPTION:
          return { ...gr, description: value }
        default:
      }
    }
    return gr
  })
  return { ...state, groups }
}

export const setGroups = (groups) => ({
  type: GROUPS_SET_GROUPS,
  groups
})

export const removeGroup = (id) => ({
  type: GROUPS_REMOVE,
  id
})

export const removeAllGroups = (id) => ({
  type: GROUPS_REMOVE_ALL
})

export const insertGroup = (position, modelGroupId) => (dispatch, getState) => {
  const state = getState()
  let gr = findById(groupsSelector(state), modelGroupId)
  if (gr) {
    gr = JSON.parse(JSON.stringify(gr))
  } else {
    gr = Group()
  }
  gr.id = getNotUsedNameObjects(groupsSelector(state), 'id', 'g')
  gr.name = getNotUsedNameObjects(groupsSelector(state), 'name', gr.name)
  dispatch({
    type: GROUPS_INSERT,
    position,
    group: gr
  })
}

export const updateGroupProperty = (id, property, value) => ({
  type: GROUPS_UPDATE_PROPERTY,
  id,
  property,
  value
})

export const restoreGroup = () => (dispatch, getState) => {
  const state = getState()
  const backup = backupSelector(state)
  if (!backup) {
    return
  }
  dispatch({
    type: GROUPS_REPLACE,
    groupId: backup.id,
    newGroup: backup
  })
}

export const swapGroups = (fromId, toId) => ({
  type: GROUPS_SWAP,
  fromId,
  toId
})

export const setCurrentGroup = (id) => ({
  type: GROUPS_SET_CURRENT_GROUP,
  id
})

export const insertPoint = (groupId, pointId, position) => ({
  type: GROUPS_INSERT_POINT,
  groupId,
  pointId,
  position
})

export const removePoint = (groupId, pointId) => ({
  type: GROUPS_REMOVE_POINT,
  groupId,
  pointId
})

export const removeAllPoints = (groupId) => ({
  type: GROUPS_REMOVE_ALL_POINTS,
  groupId
})

export const swapPoints = (groupId, fromId, toId) => ({
  type: GROUPS_SWAP_POINTS,
  groupId,
  fromId,
  toId
})

export const groupsSelector = (state) => state.groups.groups
export const currentGroupSelector = (state) => state.groups.currentGroup
export const backupSelector = (state) => state.groups.backup

export const getGroup = () =>
  createSelector([groupsSelector, (_, id) => id], (groups, id) => {
    return findById(groups, id)
  })

export const getCurrentGroup = createSelector(
  [groupsSelector, currentGroupSelector, pointsSelector],
  (groups, currentGroup, points) => {
    const group = findById(groups, currentGroup)
    if (group) {
      return {
        ...group,
        points: group.points.reduce((acc, pointId) => {
          const pto = points.find((point) => point.id === pointId)
          if (pto) {
            acc.push(pto)
          }
          return acc
        }, [])
      }
    }
    return null
  }
)

export const getCurrentGroupFreePoints = createSelector(
  [getCurrentGroup, pointsSelector],
  (currentGroup, points) => {
    if (!currentGroup) {
      return []
    }
    return points.filter(
      (point) =>
        currentGroup.points.findIndex(
          (groupPoint) => groupPoint.id === point.id
        ) < 0
    )
  }
)
