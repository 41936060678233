import { makeStyles } from '@material-ui/core/styles'

import ResetMattertagsFilterButton from '../../Toolbar/ResetMattertagsFilterButton'
import EnableMattertagsFilterButton from '../../Toolbar/EnableMattertagsFilterButton'
import MLShowAllMattertagsButton from './MLShowAllMattertagsButton'
import MLHideAllMattertagsButton from './MLHideAllMattertagsButton'
import Bar from '../../Toolbar/Bar'

const useStyles = makeStyles((theme) => ({
  topBar: {}
}))

const MLTopBar = () => {
  const classes = useStyles()

  return (
    <Bar className={classes.topBar}>
      <MLHideAllMattertagsButton />
      <MLShowAllMattertagsButton />
      <div style={{ flexGrow: 1 }} />
      <ResetMattertagsFilterButton />
      <EnableMattertagsFilterButton />
    </Bar>
  )
}
export default MLTopBar
