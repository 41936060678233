import { makeStyles } from '@material-ui/core/styles'

import TLList from './TLList'
import TagsFilter from '../Filter/TagsFilter'
import TLTopBar from './TLTopBar'
import TLBottomBar from './TLBottomBar'
// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden'
  }
}))

const TagsList = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TagsFilter />
      <TLTopBar />
      <TLList />
      <TLBottomBar />
    </div>
  )
}
export default TagsList
