import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GotoIcon } from '../Icons/Icons'
import BarButton from './BarButton'
import {
  getOriginalMattertag,
  goToMattertag
} from '../../modules/reducerMattertags'

const GoToMattertagButton = ({ sid }) => {
  const gOriginalMattertag = useMemo(getOriginalMattertag, [])
  const mattertag = useSelector((state) => gOriginalMattertag(state, sid))
  const dispatch = useDispatch()

  const handleGoTo = useCallback(() => {
    dispatch(goToMattertag(sid))
  }, [sid, dispatch])

  return (
    <BarButton
      tooltipTitle={'Ir a'}
      tooltipEnabled
      disabled={!mattertag || !mattertag.visibleSid}
      onClick={handleGoTo}
    >
      <GotoIcon />
    </BarButton>
  )
}
export default GoToMattertagButton
