import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RemoveAllIcon } from '../Icons/Icons'
import BarButton from './BarButton'

import { pointsSelector, removeAllPoints } from '../../modules/reducerPoints'

const RemoveAllPointsButton = () => {
  const points = useSelector(pointsSelector)
  const dispatch = useDispatch()

  const handleRemoveAll = useCallback(() => {
    dispatch(removeAllPoints())
  }, [dispatch])

  return (
    <BarButton
      tooltipTitle={'Borrar todos'}
      tooltipEnabled
      disabled={points.length === 0}
      onClick={handleRemoveAll}
    >
      <RemoveAllIcon />
    </BarButton>
  )
}
export default RemoveAllPointsButton
