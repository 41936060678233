import { createSelector } from 'reselect'
import { findById } from '../utils/utils'

// const SMALL_WIDTH = 850

export const MAIN_SET_ROOT_BOUNDS = 'MAIN_SET_ROOT_BOUNDS'
export const MAIN_SET_MENU_OPTION = 'MAIN_SET_MENU_OPTION'
export const MAIN_SET_LATERAL_CONTENT = 'MAIN_SET_LATERAL_CONTENT'

export const MENU_OPTIONS = {
  SAVE: 'SAVE',
  OPEN: 'OPEN',
  FLOORS: 'FLOORS',
  TAGS: 'TAGS',
  MATTERTAGS: 'MATTERTAGS',
  POINTS: 'POINTS',
  GROUPS: 'GROUPS'
}

export const LATERAL_CONTENT = {
  TAGS_LIST: 'TAGS_LIST',
  EDIT_TAG: 'EDIT_TAG',
  MATTERTAGS_LIST: 'MATTERTAGS_LIST',
  MATTERTAGS_DETAILS: 'MATTERTAGS_DETAILS',
  POINTS_LIST: 'POINTS_LIST',
  EDIT_POINT: 'EDIT_POINT',
  GROUPS_LIST: 'GROUPS_LIST',
  EDIT_GROUP: 'EDIT_GROUP'
}

const initialState = {
  menuOptions: [
    {
      id: MENU_OPTIONS.SAVE,
      name: 'Guardar',
      lateral: '',
      disabled: false
    },
    {
      id: MENU_OPTIONS.OPEN,
      name: 'Abrir',
      lateral: '',
      disabled: false
    },
    {
      id: MENU_OPTIONS.POINTS,
      name: 'Puntos',
      lateral: LATERAL_CONTENT.POINTS_LIST,
      disabled: false
    },
    {
      id: MENU_OPTIONS.GROUPS,
      name: 'Grupos',
      lateral: LATERAL_CONTENT.GROUPS_LIST,
      disabled: false
    },
    {
      id: MENU_OPTIONS.TAGS,
      name: 'Etiquetas',
      lateral: LATERAL_CONTENT.TAGS_LIST,
      disabled: false
    },
    {
      id: MENU_OPTIONS.MATTERTAGS,
      name: 'Mattertags',
      lateral: LATERAL_CONTENT.MATTERTAGS_LIST,
      disabled: false
    }
  ],
  lateralContent: [
    {
      id: LATERAL_CONTENT.TAGS_LIST,
      title: 'Tags',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    },
    {
      id: LATERAL_CONTENT.EDIT_TAG,
      title: 'Editar etiqueta',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: false
    },
    {
      id: LATERAL_CONTENT.POINTS_LIST,
      title: 'Puntos',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    },
    {
      id: LATERAL_CONTENT.EDIT_POINT,
      title: 'Editar point',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: false
    },
    {
      id: LATERAL_CONTENT.GROUPS_LIST,
      title: 'Grupos',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    },
    {
      id: LATERAL_CONTENT.EDIT_GROUP,
      title: 'Editar grupo',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: false
    },
    {
      id: LATERAL_CONTENT.MATTERTAGS_LIST,
      title: 'Mattertags',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    },
    {
      id: LATERAL_CONTENT.MATTERTAGS_DETAILS,
      title: 'Mattertag',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    }
  ],
  rootBounds: null,
  currentMenuOption: '',
  currentLateralContent: ''
}

const changeState = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case MAIN_SET_ROOT_BOUNDS:
      return {
        ...state,
        rootBounds: action.bounds
      }

    case MAIN_SET_MENU_OPTION: {
      const lateralContent = findById(
        state.lateralContent,
        state.currentLateralContent
      )

      if (lateralContent && !lateralContent.closeButton) {
        return state
      }
      const option = state.menuOptions.find((mo) => mo.id === action.option)
      if (option) {
        return {
          ...stateSetLateralContent(state, option.lateral),
          currentMenuOption: action.option
        }
      } else {
        return {
          ...state,
          currentMenuOption: '',
          currentLateralContent: ''
        }
      }
    }
    case MAIN_SET_LATERAL_CONTENT:
      return stateSetLateralContent(state, action.id)
    default:
      return state
  }
}
export default changeState

const stateSetLateralContent = (state, lateralId) => {
  const lateralContent = findById(state.lateralContent, lateralId)
  if (!lateralContent) {
    return { ...stateEnableAllOptions(state), currentLateralContent: '' }
  }
  let newState = state
  if (lateralContent.closeButton) {
    newState = stateEnableAllOptions(newState)
  } else {
    newState = stateEnableMenuOptions(newState, [state.currentMenuOption])
  }
  return { ...newState, currentLateralContent: lateralId }
}

const stateDisableAllOptions = (state) => {
  return {
    ...state,
    menuOptions: state.menuOptions.map((mo) => {
      return { ...mo, disabled: true }
    })
  }
}

const stateEnableAllOptions = (state) => {
  return {
    ...state,
    menuOptions: state.menuOptions.map((mo) => {
      return { ...mo, disabled: false }
    })
  }
}
const stateEnableMenuOptions = (state, options) => {
  return {
    ...state,
    menuOptions: state.menuOptions.map((mo) => {
      return { ...mo, disabled: options.indexOf(mo.id) < 0 }
    })
  }
}

export const setRootBounds = (bounds) => ({
  type: MAIN_SET_ROOT_BOUNDS,
  bounds
})

export const setCurrentMenuOption = (option) => ({
  type: MAIN_SET_MENU_OPTION,
  option
})
export const setCurrentLateralContent = (id) => ({
  type: MAIN_SET_LATERAL_CONTENT,
  id
})

export const rootBoundsSelector = (state) => state.main.rootBounds
export const menuOptionsSelector = (state) => state.main.menuOptions
export const currentMenuOptionSelector = (state) => state.main.currentMenuOption
export const currentLateralContentSelector = (state) =>
  state.main.currentLateralContent
export const lateralContentSelector = (state) => state.main.lateralContent

export const getMenuOption = () =>
  createSelector([menuOptionsSelector, (_, id) => id], (options, id) => {
    return options.find((o) => o.id === id)
  })

export const getCurrentLateralContent = createSelector(
  [currentLateralContentSelector, lateralContentSelector],
  (current, lateralContent) => {
    return findById(lateralContent, current)
  }
)

export const getEditingTag = (state) => {
  return (
    currentLateralContentSelector(state) === LATERAL_CONTENT.EDIT_TAG &&
    currentMenuOptionSelector(state) === MENU_OPTIONS.TAGS
  )
}

export const getEditingPoint = (state) => {
  return (
    currentLateralContentSelector(state) === LATERAL_CONTENT.EDIT_POINT &&
    currentMenuOptionSelector(state) === MENU_OPTIONS.POINTS
  )
}
