// import { createSelector } from 'reselect'
// import { findById } from '../utils/utils'
// import { getEditingPoint } from './reducerMain'

export const MATTERPORT_SET_BOUNDS = 'MATTERPORT_SET_BOUNDS'
export const MATTERPORT_SET_SDK = 'MATTERPORT_SET_SDK'
export const MATTERPORT_SET_MODEL_SID = 'MATTERPORT_SET_MODEL_SID'
export const MATTERPORT_SET_FLOORS = 'MATTERPORT_SET_FLOORS'
export const MATTERPORT_SET_CURRENT_FLOOR = 'MATTERPORT_SET_CURRENT_FLOOR'
export const MATTERPORT_SET_SWEEPS = 'MATTERPORT_SET_SWEEPS'
export const MATTERPORT_SET_CURRENT_SWEEP = 'MATTERPORT_SET_CURRENT_SWEEP'
export const MATTERPORT_SET_CAMERA_POSE = 'MATTERPORT_SET_CAMERA_POSE'
export const MATTERPORT_SET_CAMERA_SENSOR = 'MATTERPORT_SET_CAMERA_SENSOR'
export const MATTERPORT_SET_MODEL_LOADED = 'MATTERPORT_SET_MODEL_LOADED'

const initialState = {
  sdk: null,
  modelSid: 'qVvY17jDFdx',
  // modelSid: 'iL4RdJqi2yK',
  modelLoaded: false,
  floors: [],
  pose: null,
  cameraSensor: null,
  sweeps: [],
  currentFloor: '',
  currentSweep: '',
  floorsHeight: [], //{floorId,min} Ordenados de menor a mayor
  bounds: null
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case MATTERPORT_SET_SDK:
      return { ...state, sdk: action.sdk }
    case MATTERPORT_SET_MODEL_SID:
      return { ...state, modelSid: action.sid }
    case MATTERPORT_SET_FLOORS: {
      const floors = []
      for (let floor in action.collection) {
        floors.push(JSON.parse(JSON.stringify(action.collection[floor])))
      }
      return { ...state, floors }
    }
    case MATTERPORT_SET_SWEEPS:
      const sweeps = []
      for (let sweep in action.collection) {
        sweeps.push(JSON.parse(JSON.stringify(action.collection[sweep])))
      }

      // console.log(
      //   sweeps
      //     .filter((sw) => sw.floorInfo.id === '0')
      //     .map((sw) => sw.position.y)
      // )
      // console.log(
      //   sweeps
      //     .filter((sw) => sw.floorInfo.id === '1')
      //     .map((sw) => sw.position.y)
      // )

      const floorsHeight = sweeps.reduce((acc, sweep) => {
        let floor = acc.find((floor) => floor.floorId === sweep.floorInfo.id)
        if (!floor) {
          floor = { floorId: sweep.floorInfo.id, min: sweep.position.y }
          acc.push(floor)
        }
        if (floor.min > sweep.position.y) {
          floor.min = sweep.position.y
        }
        return acc
      }, [])
      floorsHeight.sort((a, b) => {
        return a.min - b.min
      })
      return { ...state, sweeps, floorsHeight }
    case MATTERPORT_SET_CAMERA_POSE:
      return { ...state, pose: action.pose }
    case MATTERPORT_SET_CURRENT_FLOOR:
      return {
        ...state,
        currentFloor: action.floorId
      }
    case MATTERPORT_SET_CURRENT_SWEEP:
      return {
        ...state,
        currentSweep: action.sweepId
      }
    case MATTERPORT_SET_MODEL_LOADED:
      console.log('--------------------------------------')
      console.log('--------------------------------------')
      console.log('--------------------------------------')
      console.log('--------------------------------------')
      return {
        ...state,
        modelLoaded: true
      }
    case MATTERPORT_SET_BOUNDS:
      return { ...state, bounds: action.bounds }
    case MATTERPORT_SET_CAMERA_SENSOR:
      return { ...state, cameraSensor: action.sensor }
    default:
      return state
  }
}
export default changeState

export const setSdk = (sdk) => ({
  type: MATTERPORT_SET_SDK,
  sdk
})

export const setModelSid = (sid) => ({
  type: MATTERPORT_SET_MODEL_SID,
  sid
})

export const setModelLoaded = (loaded) => ({
  type: MATTERPORT_SET_MODEL_LOADED,
  loaded
})

export const setFloors = (collection) => ({
  type: MATTERPORT_SET_FLOORS,
  collection
})

export const setCameraPose = (pose) => ({
  type: MATTERPORT_SET_CAMERA_POSE,
  pose
})

export const setSweeps = (collection) => ({
  type: MATTERPORT_SET_SWEEPS,
  collection
})

export const setCurrentFloor = (floorId) => ({
  type: MATTERPORT_SET_CURRENT_FLOOR,
  floorId
})

export const setCurrentSweep = (sweepId) => ({
  type: MATTERPORT_SET_CURRENT_SWEEP,
  sweepId
})

export const setBounds = (bounds) => ({
  type: MATTERPORT_SET_BOUNDS,
  bounds
})

export const setCameraSensor = (sensor) => ({
  type: MATTERPORT_SET_CAMERA_SENSOR,
  sensor
})

export const sdkSelector = (state) => state.matterport.sdk
export const modelSidSelector = (state) => state.matterport.modelSid
export const modelLoadedSelector = (state) => state.matterport.modelLoaded
export const cameraPoseSelector = (state) => state.matterport.pose
export const cameraSensorSelector = (state) => state.matterport.cameraSensor
export const floorsSelector = (state) => state.matterport.floors
export const sweepsSelector = (state) => state.matterport.sweeps
export const currentFloorPoseSelector = (state) => state.matterport.currentFloor
export const currentSweepSelector = (state) => state.matterport.currentSweep
export const boundsSelector = (state) => state.matterport.bounds
export const floorsHeightSelector = (state) => state.matterport.floorsHeight

/*
export const guessFloor = createSelector(
  [floorsHeightSelector, floorsSelector],
  (floorsHeight, floors) => (height) => {
    if (!floorsHeight || floorsHeight.length === 0) {
      return null
    }

    const floor = floorsHeight.reduce((acc, fh) => {
      if (height >= fh.min) {
        acc = fh
      }
      return acc
    }, floorsHeight[0])

    return floor ? findById(floors, floor.floorId) : null
  }
)
*/
