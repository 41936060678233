import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import {
  modelLoadedSelector,
  floorsSelector
} from '../../../modules/reducerMatterport'

const FloorSelect = ({ floorId, onChange }) => {
  const floors = useSelector(floorsSelector)
  const modelLoaded = useSelector(modelLoadedSelector)

  return (
    <TextField
      label="Floor"
      select
      disabled={!modelLoaded}
      value={floorId}
      onChange={onChange}
    >
      <MenuItem value={''}>All</MenuItem>
      {floors.map((floor) => (
        <MenuItem key={floor.id} value={floor.id}>
          {`${floor.id} ${floor.name}`}
        </MenuItem>
      ))}
    </TextField>
  )
}
export default FloorSelect
