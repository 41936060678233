import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import EditorTextField from '../../Common/Input/EditorTextField'

import { setName, nameSelector } from '../../../modules/reducerPointsFilter'

const PFName = () => {
  const name = useSelector(nameSelector)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (value) => {
      dispatch(setName(value))
    },
    [dispatch]
  )
  return <EditorTextField label="Name" value={name} onChange={handleChange} />
}
export default PFName
