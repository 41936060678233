import { makeStyles } from '@material-ui/core/styles'

import MattertagsFilter from '../Filter/MattertagsFilter'
import MLTopBar from './MLTopBar'
import MLList from './MLList'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden'
  }
}))

const MattertagsList = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MattertagsFilter />
      <MLTopBar />
      <MLList />
    </div>
  )
}
export default MattertagsList
