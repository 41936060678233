import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: ({ selected }) =>
      selected
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText,
    '&:hover': { color: theme.palette.secondary.main },
    '&:disabled': { color: theme.palette.primary.disabled }
  }
}))

const BarButton = ({
  hide,
  tooltipTitle,
  tooltipEnabled,
  selected,
  disabled,
  children,
  onClick
}) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles({ selected })

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return hide ? null : (
    <Tooltip
      open={tooltipEnabled && open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={tooltipTitle}
    >
      <IconButton
        className={classes.iconButton}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}
export default BarButton
