// import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import GEName from './GEName'
import GEDescription from './GEDescription'

import { getCurrentGroup } from '../../../modules/reducerGroups'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    boxSizing: 'border-box',
    paddingTop: 16,
    margin: 0,

    width: '100%',
    // height: '100%',
    // maxHeight: '200px',
    overflow: 'auto'

    // display: 'flex',
    // flexFlow: 'column'
  }
}))

const GEForm = ({ small }) => {
  const group = useSelector(getCurrentGroup)
  const classes = useStyles()

  return group ? (
    <Grid
      container
      className={classes.root}
      spacing={2}
      alignContent="flex-start"
    >
      <Grid item xs={12}>
        <GEName id={group.id} name={group.name} />
      </Grid>
      <Grid item xs={12}>
        <GEDescription id={group.id} description={group.description} />
      </Grid>
    </Grid>
  ) : null
}
export default GEForm
//
