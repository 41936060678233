import { makeStyles } from '@material-ui/core/styles'

import ResetTagsFilterButton from '../../Toolbar/ResetTagsFilterButton'
import EnableTagsFilterButton from '../../Toolbar/EnableTagsFilterButton'
import ImportMattertagsButton from '../../Toolbar/ImportMattertagsButton'
import RemoveAllTagsButton from '../../Toolbar/RemoveAllTagsButton'
import SeparatorButton from '../../Toolbar/SeparatorButton'
import Bar from '../../Toolbar/Bar'

const useStyles = makeStyles((theme) => ({
  topBar: {}
}))

const TLTopBar = () => {
  const classes = useStyles()

  return (
    <Bar className={classes.topBar}>
      <ImportMattertagsButton />
      <RemoveAllTagsButton />
      <SeparatorButton />
      <ResetTagsFilterButton />
      <EnableTagsFilterButton />
    </Bar>
  )
}
export default TLTopBar
