import { createSelector } from 'reselect'
import { findById } from '../utils/utils'

export const MATTERPORT_ADD_ICON = 'MATTERPORT_ADD_ICON'
export const MATTERPORT_REMOVE_ICON = 'MATTERPORT_REMOVE_ICON'

const initialState = {
  icons: [
    { id: 'i0', name: 'React', src: 'logo192.png' },
    { id: 'i1', name: 'Mattertag', src: 'Mattertag.svg' },
    {
      id: 'i2',
      name: 'rana',
      src: 'animated-frog-image-0357.gif'
    }
  ],
  mattertagIcon: { id: 'mti', src: 'square-rounded-64.png' }
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case MATTERPORT_ADD_ICON:
      return { ...state, sdk: action.sdk }
    case MATTERPORT_REMOVE_ICON:
      return { ...state, modelSid: action.sid }
    default:
      return state
  }
}
export default changeState

export const addIcon = (sdk) => ({
  type: MATTERPORT_ADD_ICON,
  sdk
})

export const removeIcon = (sid) => ({
  type: MATTERPORT_REMOVE_ICON,
  sid
})

export const matterportIconsSelector = (state) => state.matterportIcons.icons
export const mattertagIconSelector = (state) =>
  state.matterportIcons.mattertagIcon

export const getIcon = () =>
  createSelector([matterportIconsSelector, (_, id) => id], (icons, id) => {
    return findById(icons, id)
  })
