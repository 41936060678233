import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Accordion from '../../Common/Accordion/Accordion'
import TFState from './TFState'
import TFFloor from './TFFloor'
import { TFPositionX, TFPositionY, TFPositionZ } from './TFPosition'
import { TFIncX, TFIncY, TFIncZ } from './TFPosition'
import TFName from './TFName'
import TFOriginalMattertag from './TFOriginalMattertag'
import { enabledSelector } from '../../../modules/reducerTagsFilter'
import { animatingWidthSelector } from '../../../modules/reducerLateral'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const TagsFilter = () => {
  const classes = useStyles()
  const filterEnabled = useSelector(enabledSelector)
  const animatingWidth = useSelector(animatingWidthSelector)
  const small = animatingWidth < 400
  return (
    <Accordion show={filterEnabled} height={300}>
      <Grid
        container
        className={classes.root}
        spacing={1}
        alignContent="flex-start"
      >
        <Grid item xs={12}>
          <TFName />
        </Grid>
        <Grid item xs={12}>
          <TFOriginalMattertag />
        </Grid>
        <Grid item xs={small ? 12 : 6}>
          <TFPositionX />
        </Grid>
        <Grid item xs={small ? 12 : 6}>
          <TFIncX />
        </Grid>
        <Grid item xs={small ? 12 : 6}>
          <TFPositionY />
        </Grid>
        <Grid item xs={small ? 12 : 6}>
          <TFIncY />
        </Grid>
        <Grid item xs={small ? 12 : 6}>
          <TFPositionZ />
        </Grid>
        <Grid item xs={small ? 12 : 6}>
          <TFIncZ />
        </Grid>
        <Grid item xs={small ? 12 : 6}>
          <TFState />
        </Grid>
        <Grid item xs={small ? 12 : 6}>
          <TFFloor />
        </Grid>
      </Grid>
    </Accordion>
  )
}
export default TagsFilter
