import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import { ImportIcon } from '../Icons/Icons'
import BarButton from './BarButton'
import { modelLoadedSelector } from '../../modules/reducerMatterport'
import { restoreTagFromMattertag } from '../../modules/reducerTagsActions'
import { currentTagSelector } from '../../modules/reducerTagsSelectors'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const RestoreTagFromMattertagButton = () => {
  // const classes = useStyles()
  const modelLoaded = useSelector(modelLoadedSelector)
  const currentTag = useSelector(currentTagSelector)
  const dispatch = useDispatch()

  const handleRestore = useCallback(() => {
    dispatch(restoreTagFromMattertag(currentTag))
  }, [dispatch, currentTag])

  return (
    <BarButton
      tooltipTitle={'Importar valores desde Mattertag'}
      tooltipEnabled
      disabled={!modelLoaded}
      onClick={handleRestore}
    >
      <ImportIcon />
    </BarButton>
  )
}
export default RestoreTagFromMattertagButton
