import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Accordion from '../../Common/Accordion/Accordion'
import MTFState from './MTFState'
import MTFFloor from './MTFFloor'
import {
  MTFPositionX,
  MTFPositionY,
  MTFPositionZ,
  MTFIncX,
  MTFIncY,
  MTFIncZ
} from './MTFPosition'
import MTFLabel from './MTFLabel'
import MTFSid from './MTFSid'
import { enabledSelector } from '../../../modules/reducerMattertagsFilter'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const MattertagsFilter = () => {
  const classes = useStyles()
  const filterEnabled = useSelector(enabledSelector)
  console.log(filterEnabled)
  return (
    <Accordion show={filterEnabled} height={300}>
      <Grid
        container
        className={classes.root}
        spacing={1}
        alignContent="flex-start"
      >
        <Grid item xs={12}>
          <MTFLabel />
        </Grid>
        <Grid item xs={12}>
          <MTFSid />
        </Grid>
        <Grid item xs={9}>
          <MTFPositionX />
        </Grid>
        <Grid item xs={3}>
          <MTFIncX />
        </Grid>
        <Grid item xs={9}>
          <MTFPositionY />
        </Grid>
        <Grid item xs={3}>
          <MTFIncY />
        </Grid>
        <Grid item xs={9}>
          <MTFPositionZ />
        </Grid>
        <Grid item xs={3}>
          <MTFIncZ />
        </Grid>
        <Grid item xs={6}>
          <MTFState />
        </Grid>
        <Grid item xs={6}>
          <MTFFloor />
        </Grid>
      </Grid>
    </Accordion>
  )
}
export default MattertagsFilter
