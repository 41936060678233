import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'

import MatterportEditorTheme from './MatterportEditorTheme'
import LateralBar from './LateralBar/LateralBar'
import LateralContent from './LateralContent/LateralContent'
import Viewer from './Viewer/MatterportViewer'
// import AppDialog from './AppDialog/AppDialog'
import { setRootBounds } from '../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'grey',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexFlow: 'row',

    overflow: 'hidden',
    position: 'relative'
  }
}))

const MatterportEditorMain = () => {
  const [rootBoundsRef, rootBounds] = useMeasure()
  const classes = useStyles()
  // const dialogs = useSelector(dialogsSelector)
  // const lateralOpen = useSelector(lateralOpenSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setRootBounds(rootBounds))
  }, [dispatch, rootBounds])

  return (
    <ThemeProvider theme={MatterportEditorTheme}>
      <div ref={rootBoundsRef} className={classes.root}>
        <LateralBar />
        <LateralContent />
        <Viewer />
      </div>
    </ThemeProvider>
  )
}
export default MatterportEditorMain
/*
  <Viewer />

  <div
    style={{ width: '100%', height: '100%', backgroundColor: 'black' }}
  ></div>
*/
