// import { useCallback } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'

const useStyles = makeStyles((theme) => ({
  topBar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}))

const Bar = ({ children }) => {
  const classes = useStyles()
  return (
    <Toolbar disableGutters variant="dense" className={classes.topBar}>
      {children}
    </Toolbar>
  )
}
export default Bar
