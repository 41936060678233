import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import { CursorIcon } from '../Icons/Icons'
import BarButton from './BarButton'
import { modelLoadedSelector } from '../../modules/reducerMatterport'
import {
  cursorEnabledSelector,
  setCursorEnabled,
  setModeTagEditor
} from '../../modules/reducerMatterportCursor'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const EnableCursorButton = () => {
  // const classes = useStyles()
  const modelLoaded = useSelector(modelLoadedSelector)
  const cursorEnabled = useSelector(cursorEnabledSelector)
  const dispatch = useDispatch()

  const handleEnableCursor = useCallback(() => {
    if (!cursorEnabled) {
      dispatch(setModeTagEditor())
    }
    dispatch(setCursorEnabled(!cursorEnabled))
  }, [dispatch, cursorEnabled])

  return (
    <BarButton
      tooltipTitle={'Leer posicion del modelo'}
      tooltipEnabled
      disabled={!modelLoaded}
      selected={cursorEnabled}
      onClick={handleEnableCursor}
    >
      <CursorIcon />
    </BarButton>
  )
}
export default EnableCursorButton
