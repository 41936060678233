import { useRef, useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'

import MatterportCursor from './MatterportCursor'
import TagPermanentBillboard from './TagPermanentBillboard'
import {
  tagsSelector,
  getVisibleTags
} from '../../modules/reducerTagsSelectors'

import {
  matterportIconsSelector,
  mattertagIconSelector
} from '../../modules/reducerMatterportIcons'
import {
  setSdk,
  sdkSelector,
  setFloors,
  setCameraPose,
  setSweeps,
  setCurrentFloor,
  setCurrentSweep,
  modelSidSelector,
  setBounds,
  setModelLoaded,
  setCameraSensor
} from '../../modules/reducerMatterport'
import {
  setOriginalMattertags,
  setMattertags
} from '../../modules/reducerMattertags'
import {
  loadOfflineTags,
  setCurrentMattertag,
  mouseOverTag,
  setTagSensorReading
} from '../../modules/reducerTagsActions'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'grey',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    position: 'relative',
    cursor: 'pointer'
  }
}))

const MatterportViewer = () => {
  const [boundsRef, bounds] = useMeasure()
  const iframe = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [modelReady, setModelReady] = useState(false)
  const showcase = useRef(null)
  const sdk = useSelector(sdkSelector)
  const modelSid = useSelector(modelSidSelector)
  const icons = useSelector(matterportIconsSelector)
  const mattertagIcon = useSelector(mattertagIconSelector)
  const tags = useSelector(tagsSelector)
  const visibleTags = useSelector(getVisibleTags)
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setBounds(bounds))
  }, [dispatch, bounds])

  const onLoad = useCallback(() => {
    iframe.current = document.getElementById('matterport')
    const clave = 'rnn04fu9bxzm04t4muz0i10tc' //Seniors
    //const clave = '82d9dbwx5qdh0x7pabefxxhha' //impulsa
    try {
      window.MP_SDK.connect(iframe.current, clave, '3.10')
        .then((e) => {
          showcase.current = e
          setLoaded(true)
          dispatch(setSdk(e))
        })
        .catch((e) => console.log(e))
    } catch (e) {
      console.error(e)
    }
  }, [dispatch])

  useEffect(() => {
    if (modelReady) {
      //No veo nada que indique que el modelo ha terminado de cargarse y que
      //las mattertags son validas. El evento App.Event.PHASE_CHANGE se llama
      //antes de que indiquen las mattertags,plantas,sweeps... no me sirve.
      //Uso la primera llamada a sdk. Sweep.current con Sweep valido. Se supone
      //que entonces ya esta todo cargado.

      //Sensor
      sdk.Sensor.createSensor(sdk.Sensor.SensorType.CAMERA)
        .then((sensor) => {
          dispatch(setCameraSensor(sensor))
          // sensor.showDebug(true)
          //Despues de sensor
          dispatch(setModelLoaded(true))
          dispatch(loadOfflineTags())
          sensor.readings.subscribe({
            onAdded(source, reading) {
              dispatch(setTagSensorReading(source.userData.tagId, reading))
            },
            onUpdated(source, reading) {
              dispatch(setTagSensorReading(source.userData.tagId, reading))
            },
            onRemoved(source, reading, collection) {
              console.log('removed')
            }
          })
        })
        .catch((error) => alert(error))
    }
  }, [modelReady, dispatch, sdk])

  useEffect(() => {
    if (loaded && sdk) {
      //Icons
      const base = window.location.href + 'icons/'
      for (let icon of icons) {
        sdk.Mattertag.registerIcon(icon.id, base + icon.src).then(() =>
          console.log(
            '----------------Icon registered--------------',
            window.location.href
          )
        )
      }
      sdk.Mattertag.registerIcon(
        mattertagIcon.id,
        base + mattertagIcon.src
      ).then(() => {})

      //Floors
      const floorSubscription = sdk.Floor.data.subscribe({
        onCollectionUpdated: (collection) => {
          dispatch(setFloors(collection))
          floorSubscription.cancel()
        }
      })
      sdk.Floor.current.subscribe((currentFloor) => {
        dispatch(setCurrentFloor(currentFloor.id))
      })
      //Tags
      const mattertagSubscription = sdk.Mattertag.data.subscribe({
        onCollectionUpdated: (collection) => {
          dispatch(setOriginalMattertags(collection))
          mattertagSubscription.cancel()
          sdk.Mattertag.data.subscribe({
            onCollectionUpdated: (collection) => {
              dispatch(setMattertags(collection))
            }
          })
        }
      })

      sdk.on(sdk.Mattertag.Event.CLICK, (sid) => {
        dispatch(setCurrentMattertag(sid))
      })
      sdk.on(sdk.Mattertag.Event.HOVER, (sid) => {
        dispatch(mouseOverTag(sid))
      })
      //Sweep
      const sweepSubscription = sdk.Sweep.data.subscribe({
        onCollectionUpdated: (collection) => {
          dispatch(setSweeps(collection))
          sweepSubscription.cancel()
        }
      })
      sdk.Sweep.current.subscribe((currentSweep) => {
        dispatch(setCurrentSweep(currentSweep.id))
        if (currentSweep.id) {
          setModelReady(true)
        }
      })
      //Camera
      sdk.Camera.pose.subscribe((pose) => {
        dispatch(setCameraPose(pose))
      })
    }
  }, [dispatch, loaded, sdk, icons, mattertagIcon])

  // const handleMouseLeave = useCallback(() => {
  //   dispatch(setCursorEnabled(false))
  // }, [dispatch])

  const aaa = (collection) => {
    createSources(collection).then((sources) => {
      sdk.Sensor.createSensor(sdk.Sensor.SensorType.CAMERA).then((sensor) => {
        sensor.showDebug(true)
        // add sources from calls to `Sensor.createSource()`
        sensor.addSource(...sources)
        // start listening for changes to the sensor's readings
        sensor.readings.subscribe({
          onAdded(source, reading) {
            console.log(source.userData.id, 'has a reading of', reading)
          },
          onUpdated(source, reading) {
            console.log(
              source.userData.id,
              'has an updated reading',
              reading,
              reading.inRange,
              reading.inView
            )
            // if (reading.inRange) {
            //   console.log(source.userData.id, 'is currently in range')
            // }
            // if (reading.inView) {
            //   console.log(source.userData.id, 'currently visible on screen')
            // }
          }
        })
      })
    })
  }

  const createSources = async (collection) => {
    const mattertags = []
    for (let tagSid in collection) {
      mattertags.push(collection[tagSid])
    }

    const sources = await Promise.all(
      mattertags.map((mt) => {
        return sdk.Sensor.createSource(sdk.Sensor.SourceType.SPHERE, {
          origin: sdk.Mattertag.getDiscPosition(mt),
          radius: 10,
          userData: {
            id: mt.sid
          }
        })
      })
    )
    return sources
  }

  return (
    <div ref={boundsRef} className={classes.root}>
      <MatterportCursor />

      {visibleTags.map((tag) => (
        <TagPermanentBillboard key={tag.id} id={tag.id} />
      ))}

      <iframe
        onLoad={onLoad}
        id="matterport"
        title="havalook pano"
        src={`https://my.matterport.com/show/?m=${modelSid}&play=0&qs=1`}
        frameBorder="0"
        allowFullScreen
        allow="xr-spatial-tracking"
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          zIndex: 8
        }}
      />
    </div>
  )
}
export default MatterportViewer
/*
{tags.map((tag) => (
  <TagPermanentBillboard key={tag.id} id={tag.id} />
))}
*/
