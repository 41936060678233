import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import {
  MultimediaIcon,
  ListIcon,
  MattertagIcon,
  EditIcon,
  PermanentBillboardIcon
} from '../../Icons/Icons'
import OKCancelButtons from '../../Common/Buttons/OKCancelButtons'
import MattertagsFilter from '../../Mattertags/Filter/MattertagsFilter'
import TagsEditorGeneral from './General/TagsEditorGeneral'
import EnableCursorButton from '../../Toolbar/EnableCursorButton'
import GoToTagButton from '../../Toolbar/GoToTagButton'
import RestoreTagFromMattertagButton from '../../Toolbar/RestoreTagFromMattertagButton'

import TagsEditorOriginalMattertag from './OriginalMattertag/TagsEditorOriginalMattertag'
import GoToMattertagButton from '../../Toolbar/GoToMattertagButton'
import ShowMattertagButton from '../../Toolbar/ShowMattertagButton'

import TagsEditorMattertags from './Mattertags/TagsEditorMattertags'
import TETopBar from './TETopBar'

import ResetMattertagsFilterButton from '../../Toolbar/ResetMattertagsFilterButton'
import EnableMattertagsFilterButton from '../../Toolbar/EnableMattertagsFilterButton'

import MLShowAllMattertagsButton from '../../Mattertags/List/MLShowAllMattertagsButton'
import MLHideAllMattertagsButton from '../../Mattertags/List/MLHideAllMattertagsButton'

import TagsEditorContent from './Content/TagsEditorContent'
import TagsEditorPermanentBillboard from './PermanentBillboard/TagsEditorPermanentBillboard'

import { getCurrentTag } from '../../../modules/reducerTagsSelectors'
import { restoreTag } from '../../../modules/reducerTagsActions'

import {
  LATERAL_CONTENT,
  setCurrentLateralContent
} from '../../../modules/reducerMain'
import { modelLoadedSelector } from '../../../modules/reducerMatterport'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    maxHeight: '100%',
    minHeight: 0,
    flexGrow: 1,
    boxSizing: 'border-box',

    width: '100%'
  },
  contentWrapper: { flexGrow: 1, overflow: 'auto' }
}))

const TagEditor = () => {
  const [currentTab, setCurrentTab] = useState('t0')
  const modelLoaded = useSelector(modelLoadedSelector)
  const currentTag = useSelector(getCurrentTag)
  const dispatch = useDispatch()
  const classes = useStyles()

  const TABS = [
    { id: 't0', name: 'General', disabled: false, icon: <EditIcon /> },
    {
      id: 't1',
      name: 'Contenido',
      disabled: false,
      icon: <MultimediaIcon />
    },
    {
      id: 't2',
      name: 'Cartel permanente',
      disabled: false,
      icon: <PermanentBillboardIcon />
    },
    {
      id: 't3',
      name: 'Mattertag original',
      disabled: !modelLoaded,
      icon: <MattertagIcon />
    },
    {
      id: 't4',
      name: 'Mattertags',
      disabled: !modelLoaded,
      icon: <ListIcon />
    }
  ]

  const handleChange = (newValue) => {
    setCurrentTab(newValue)
  }
  const handleOK = useCallback(() => {
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.TAGS_LIST))
  }, [dispatch])

  const handleCancel = useCallback(() => {
    dispatch(restoreTag())
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.TAGS_LIST))
  }, [dispatch])

  const getTabButtons = () => {
    if (currentTab === 't0') {
      return (
        <>
          <RestoreTagFromMattertagButton />
          <GoToTagButton sid={currentTag.sid} />
          <EnableCursorButton />
        </>
      )
    } else if (currentTab === 't3') {
      return (
        <>
          <ShowMattertagButton sid={currentTag.originalMattertagSid} />
          <GoToMattertagButton sid={currentTag.originalMattertagSid} />
        </>
      )
    } else if (currentTab === 't4') {
      return (
        <>
          <MLHideAllMattertagsButton />
          <MLShowAllMattertagsButton />
          <ResetMattertagsFilterButton />
          <EnableMattertagsFilterButton />
        </>
      )
    }
    return null
  }
  return currentTag ? (
    <div className={classes.root}>
      {currentTab === 't4' && <MattertagsFilter />}
      <TETopBar tabs={TABS} currentTab={currentTab} onChange={handleChange}>
        {getTabButtons()}
      </TETopBar>
      <div className={classes.contentWrapper}>
        {currentTab === 't0' && <TagsEditorGeneral />}
        {currentTab === 't1' && <TagsEditorContent />}
        {currentTab === 't2' && <TagsEditorPermanentBillboard />}
        {currentTab === 't3' && <TagsEditorOriginalMattertag />}
        {currentTab === 't4' && <TagsEditorMattertags />}
      </div>
      <OKCancelButtons onOk={handleOK} onCancel={handleCancel} />
    </div>
  ) : null
}
export default TagEditor
