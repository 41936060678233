import { createSelector } from 'reselect'
import {
  originalMattertagsSelector,
  mattertagsSelector
} from './reducerMattertags'
import { tagsSelector, getTagsErrors } from './reducerTagsSelectors'
// import { findById } from '../utils/utils'

export const TAG_FILTER_SET_CURRENT_TAG = 'TAGS_FILTER_SET_CURRENT_TAG'
export const TAGS_FILTER_SET_ENABLED = 'TAGS_FILTER_SET_ENABLED'
export const TAGS_FILTER_RESET = 'TAGS_FILTER_RESET'
export const TAGS_FILTER_UPDATE_PROPERTY = 'TAGS_FILTER_UPDATE_PROPERTY'

export const TAG_STATES = [
  { id: 'st0', name: 'Todas' },
  { id: 'st1', name: 'Sin error' },
  { id: 'st2', name: 'Con error' }
]

export const TAGS_FILTER_PROPERTIES = {
  NAME: 'name',
  FLOOR_ID: 'floorId',
  STATE: 'state',
  POSITION: 'position',
  INC: 'inc',
  LABEL: 'label',
  ORIGINAL_MATTERTAG: 'originalMattertag'
}

const initialState = {
  currentTag: '',
  enabled: false,
  floorId: '',
  state: 'st0',
  position: { x: 0, y: 0, z: 0 },
  inc: { x: 1000, y: 1000, z: 1000 },
  label: '',
  name: '',
  originalMattertag: ''
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case TAGS_FILTER_SET_ENABLED:
      return { ...state, enabled: action.enabled }
    case TAGS_FILTER_RESET:
      return stateClearFilter(state)
    case TAG_FILTER_SET_CURRENT_TAG:
      return { ...state, currentTag: action.sid }
    case TAGS_FILTER_UPDATE_PROPERTY:
      return stateUpdateProperty(state, action.property, action.value)
    default:
      return state
  }
}
export default changeState

const stateClearFilter = (state) => {
  return {
    ...state,
    floorId: '',
    state: 'st0',
    position: { x: 0, y: 0, z: 0 },
    inc: { x: 1000, y: 1000, z: 1000 },
    label: '',
    name: '',
    originalMattertag: ''
  }
}

const stateUpdateProperty = (state, property, value) => {
  const newState = { ...state }
  newState[property] = value
  return newState
}

export const setFilterEnable = (enabled) => ({
  type: TAGS_FILTER_SET_ENABLED,
  enabled
})

export const enableFilter = () => ({
  type: TAGS_FILTER_SET_ENABLED,
  enabled: true
})

export const disableFilter = () => ({
  type: TAGS_FILTER_SET_ENABLED,
  enabled: false
})

export const resetFilter = () => ({
  type: TAGS_FILTER_RESET
})

export const updateProperty = (property, value) => ({
  type: TAGS_FILTER_UPDATE_PROPERTY,
  property,
  value
})

export const setName = (value) => ({
  type: TAGS_FILTER_UPDATE_PROPERTY,
  property: TAGS_FILTER_PROPERTIES.NAME,
  value
})

export const setFloor = (value) => ({
  type: TAGS_FILTER_UPDATE_PROPERTY,
  property: TAGS_FILTER_PROPERTIES.FLOOR_ID,
  value
})

export const setState = (value) => ({
  type: TAGS_FILTER_UPDATE_PROPERTY,
  property: TAGS_FILTER_PROPERTIES.STATE,
  value
})

export const setPosition = (value) => ({
  type: TAGS_FILTER_UPDATE_PROPERTY,
  property: TAGS_FILTER_PROPERTIES.POSITION,
  value
})

export const setInc = (value) => ({
  type: TAGS_FILTER_UPDATE_PROPERTY,
  property: TAGS_FILTER_PROPERTIES.INC,
  value
})

export const setLabel = (value) => ({
  type: TAGS_FILTER_UPDATE_PROPERTY,
  property: TAGS_FILTER_PROPERTIES.LABEL,
  value
})

export const setOriginalMattertag = (value) => ({
  type: TAGS_FILTER_UPDATE_PROPERTY,
  property: TAGS_FILTER_PROPERTIES.ORIGINAL_MATTERTAG,
  value
})

export const currentMattertagSelector = (state) => state.tagsFilter.currentTag
export const enabledSelector = (state) => state.tagsFilter.enabled
export const floorIdSelector = (state) => state.tagsFilter.floorId
export const stateSelector = (state) => state.tagsFilter.state
export const positionSelector = (state) => state.tagsFilter.position
export const incSelector = (state) => state.tagsFilter.inc
export const labelSelector = (state) => state.tagsFilter.label
export const nameSelector = (state) => state.tagsFilter.name
export const originalMattertagSelector = (state) =>
  state.tagsFilter.originalMattertag

export const getCurrentMattertag = createSelector(
  [originalMattertagsSelector, currentMattertagSelector],
  (originalMattertags, currentMattertag) => {
    return originalMattertags.find((mtag) => mtag.sid === currentMattertag)
  }
)

export const getFilteredTags = createSelector(
  [
    originalMattertagsSelector,
    tagsSelector,
    enabledSelector,
    floorIdSelector,
    stateSelector,
    positionSelector,
    incSelector,
    nameSelector,
    originalMattertagSelector,
    mattertagsSelector,
    getTagsErrors
  ],
  (
    originalMattertags,
    tags,
    enabled,
    floorId,
    state,
    position,
    inc,
    name,
    originalMattertag,
    mattertags,
    tagsErrors
  ) => {
    if (!enabled) {
      return tags
    }
    let res = tags
    //Estado
    //Sin error
    if (state === 'st1') {
      res = res.filter((tag) => {
        return tagsErrors.findIndex((e) => tag.id === e.tagId) < 0
      })
    }
    //Con error
    if (state === 'st2') {
      res = res.filter((tag) => {
        return tagsErrors.findIndex((e) => tag.id === e.tagId) >= 0
      })
    }
    //Planta
    if (floorId) {
      res = res.filter((tag) => {
        const mtFloor = mattertags.find((mt) => mt.sid === tag.sid)
        if (mtFloor) {
          return mtFloor.floorId === floorId
        }
        return false
      })
    }
    //Nombre
    if (name) {
      res = res.filter((tag) => tag.name.includes(name))
    }
    //OriginalMattertag
    if (originalMattertag) {
      res = res.filter((tag) =>
        tag.originalMattertagSid.includes(originalMattertag)
      )
    }
    //Posicion
    const xMin = position.x - inc.x
    const xMax = position.x + inc.x
    const yMin = position.y - inc.y
    const yMax = position.y + inc.y
    const zMin = position.z - inc.z
    const zMax = position.z + inc.z

    res = res.filter((tag) => tag.position.x >= xMin && tag.position.x <= xMax)
    res = res.filter((tag) => tag.position.y >= yMin && tag.position.y <= yMax)
    res = res.filter((tag) => tag.position.z >= zMin && tag.position.z <= zMax)
    return res
  }
)
