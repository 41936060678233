import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import EditorTextField from '../../Common/Input/EditorTextField'
import { sidSelector, setSid } from '../../../modules/reducerMattertagsFilter'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const TFSid = () => {
  // const classes = useStyles()
  const sid = useSelector(sidSelector)
  const dispatch = useDispatch()

  const handleSidChange = useCallback(
    (value) => {
      dispatch(setSid(value))
    },
    [dispatch]
  )

  return <EditorTextField label="Sid" value={sid} onChange={handleSidChange} />
}
export default TFSid
