import { useCallback, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    appearance: 'textfield'
  },
  root: {
    '& $notchedOutline ': {
      borderColor: theme.palette.primary.warning
    }
  },
  notchedOutline: {}
}))

const FloatValue = ({
  label,
  value,
  warning,
  error,
  disabled,
  max,
  min,
  onChange
}) => {
  const classes = useStyles()

  const getFloatValue = useCallback(
    (v) => {
      let floatValue = parseFloat(v)
      if (isNaN(floatValue)) {
        floatValue = 0
      }
      if (!isNaN(min)) {
        floatValue = Math.max(min, floatValue)
      }
      if (!isNaN(max)) {
        floatValue = Math.min(max, floatValue)
      }
      return floatValue
    },
    [min, max]
  )
  const [localValue, setLocalValue] = useState(getFloatValue(value))

  useEffect(() => {
    setLocalValue(getFloatValue(value)) //Deja esto
  }, [value, getFloatValue])

  const updateExternalValue = useCallback(() => {
    let newValue = parseFloat(localValue)
    if (isNaN(newValue)) {
      newValue = getFloatValue(value)
    }
    if (onChange) {
      onChange(newValue)
    }
    setLocalValue(value)
  }, [onChange, localValue, value, getFloatValue])

  const handleBlur = useCallback(
    (e) => {
      updateExternalValue()
    },
    [updateExternalValue]
  )

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        updateExternalValue()
      }
    },
    [updateExternalValue]
  )

  const handleChange = useCallback((e) => {
    setLocalValue(e.target.value)
  }, [])

  return (
    <TextField
      label={label}
      value={localValue}
      error={error}
      disabled={disabled}
      inputProps={{
        type: 'number',
        className: classes.input
      }}
      InputProps={{
        classes: {
          root: warning && classes.root,
          notchedOutline: warning && classes.notchedOutline
        }
      }}
      InputLabelProps={{
        classes: {
          root: warning && classes.rootLabel
        }
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
    />
  )
}
export default FloatValue
//
