import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDrag } from 'react-use-gesture'
import { useSelector, useDispatch } from 'react-redux'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import {
  minWidthSelector,
  widthSelector,
  setWidth,
  collapse,
  expand
} from '../../modules/reducerLateral'

const useStyles = makeStyles((theme) => ({
  resizeBarWrapper: {
    position: 'relative',
    height: '100%',
    backgroundColor: theme.palette.primary.main
  },
  resizeBar: {
    touchAction: 'none',
    width: 4,
    height: '100%',
    cursor: ({ dragEnable }) => (dragEnable ? 'w-resize' : ''),
    position: 'relative'
  },
  iconWrapper: {
    position: 'absolute',
    top: '50%',
    left: 4,
    transform: 'translate(0, -50%)',
    backgroundColor: theme.palette.primary.main,
    height: 80,
    width: 20,
    display: 'flex',
    alignItems: 'center',
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    zIndex: 10,
    cursor: 'pointer'
  },
  icon: { color: theme.palette.primary.contrastText }
}))

const LateralResizeBar = ({ hover }) => {
  const width = useSelector(widthSelector)
  const minWidth = useSelector(minWidthSelector)
  const dragEnable = width >= minWidth
  const showArrow = hover || width === 0
  const classes = useStyles({ dragEnable })
  const dispatch = useDispatch()

  const bind = useDrag(({ delta }) => {
    if (dragEnable) {
      dispatch(setWidth(width + delta[0]))
    }
  })

  const handleCollapse = useCallback(() => {
    dispatch(collapse())
  }, [dispatch])

  const handleExpand = useCallback(() => {
    dispatch(expand())
  }, [dispatch])
  return (
    <div className={classes.resizeBarWrapper}>
      <div {...bind()} className={classes.resizeBar} />
      {showArrow && width >= minWidth && minWidth > 0 && (
        <div className={classes.iconWrapper} onClick={handleCollapse}>
          <NavigateBeforeIcon className={classes.icon} />
        </div>
      )}
      {showArrow && (width < minWidth || minWidth === 0) && (
        <div className={classes.iconWrapper} onClick={handleExpand}>
          <NavigateNextIcon className={classes.icon} />
        </div>
      )}
    </div>
  )
}
export default LateralResizeBar
