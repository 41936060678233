import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useSpring, animated, config } from 'react-spring'
import LateralToolbar from './LateralToolbar'
import LateralResizeBar from './LateralResizeBar'

import {
  // minWidthSelector,
  // maxWidthSelector,
  // defaultWidthSelector,
  visibleSelector,
  widthSelector,
  finalWidthSelector,
  setWidth,
  titleSelector,
  closeButtonSelector,
  setAnimatingWidth,
  getWidth
  // BREAKPOINTS,
  // collapse,
  // expand
} from '../../modules/reducerLateral'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    maxHeight: '100%',
    minHeight: '100%',

    display: 'flex',
    flexFlow: 'row'
  },
  contentWrapper: {
    backgroundColor: theme.palette.primary.light,
    boxSizing: 'border-box',
    padding: 0,
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden'
  }
}))

const Lateral = ({ children, onClose }) => {
  const title = useSelector(titleSelector)
  const closeButton = useSelector(closeButtonSelector)
  const width = useSelector(widthSelector)
  const actualWidth = useSelector(getWidth)
  const finalWidth = useSelector(finalWidthSelector)
  // const minWidth = useSelector(minWidthSelector)
  // const maxWidth = useSelector(maxWidthSelector)
  // const defaultWidth = useSelector(defaultWidthSelector)
  const visible = useSelector(visibleSelector)
  const dispatch = useDispatch()

  const [hover, setHover] = useState(false)
  const [lastWidth, setLastWidth] = useState(0)
  const classes = useStyles()

  const widthAnimation = useSpring({
    widthExpand: width !== finalWidth ? finalWidth : width,
    onRest: () => {
      dispatch(setWidth(finalWidth))
    },
    onStart: (o) => {
      setLastWidth(o.value.widthExpand)
    },
    onChange: (o) => {
      if (Math.abs(lastWidth - o.value.widthExpand) > 1) {
        setLastWidth(o.value.widthExpand)
        dispatch(setAnimatingWidth(o.value.widthExpand))
      }
    },
    config: config.default
  })

  const handleMouseOver = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  return (
    <div
      className={classes.root}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {(visible || width > 0) && (
        <>
          <animated.div
            style={{ width: actualWidth }}
            className={classes.contentWrapper}
          >
            <LateralToolbar
              title={title}
              closeButton={closeButton}
              onClose={onClose}
            />
            {children}
          </animated.div>
          <LateralResizeBar hover={hover} />
        </>
      )}
    </div>
  )
}
export default Lateral
