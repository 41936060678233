import { makeStyles } from '@material-ui/core/styles'
import { List } from '@material-ui/core'
import RootRef from '@material-ui/core/RootRef'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: '100%',
    boxSizing: 'border-box',
    padding: 0
  }
}))

const DraggableList = ({ children, onSwap }) => {
  const classes = useStyles()

  const onDragEnd = (result) => {
    if (!result.destination || !onSwap) {
      return
    }
    onSwap(result.source.index, result.destination.index)
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <RootRef rootRef={provided.innerRef}>
            <List className={classes.root}>
              {children}
              {provided.placeholder}
            </List>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  )
}
export default DraggableList
