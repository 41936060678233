export const rgbToHex = (r, g, b) => {
  const pad = (value) => {
    return value.length < 2 ? '0' + value : value
  }
  return (
    '#' +
    pad(r ? (r * 255).toString(16) : '0') +
    pad(g ? (g * 255).toString(16) : '0') +
    pad(b ? (b * 255).toString(16) : '0')
  )
}

export const hexToRgb = (hex) => {
  return {
    r: parseInt(hex.slice(1, 3), 16) / 255,
    g: parseInt(hex.slice(3, 5), 16) / 255,
    b: parseInt(hex.slice(5, 7), 16) / 255
  }
}
