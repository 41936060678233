import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import OriginalMattertagSelect from '../../Common/Input/OriginalMattertagSelect'

import { setOriginalMattertag } from '../../../modules/reducerTagsFilter'

const TFOriginalMattertag = () => {
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (e) => {
      dispatch(setOriginalMattertag(e.target.value))
    },
    [dispatch]
  )

  return <OriginalMattertagSelect onChange={handleChange} />
}
export default TFOriginalMattertag
