import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    boxSizing: 'border-box',
    padding: 8,
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end'
  },
  button: { margin: '0 0 0 8px', width: 100 }
}))

const OKCancelButtons = ({ onOk, onCancel }) => {
  const classes = useStyles()

  return (
    <div className={classes.buttonWrapper}>
      <Button className={classes.button} onClick={onCancel}>
        Cancelar
      </Button>
      <Button className={classes.button} onClick={onOk}>
        OK
      </Button>
    </div>
  )
}
export default OKCancelButtons
