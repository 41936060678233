import { findById, swap } from '../utils/utils'
// import data from '../data/default.json'
import { TAG_PROPERTIES } from './tag'
import {
  TAGS_SET_TAGS,
  TAGS_SET_CURRENT_TAG,
  TAGS_INSERT,
  TAGS_REMOVE,
  TAGS_REMOVE_ALL,
  TAGS_UPDATE_PROPERTY,
  TAGS_SWAP_BY_ID,
  TAGS_REPLACE,
  TAGS_SET_SENSOR_READING
} from './reducerTagsActions'

const initialState = {
  tags: [],
  currentTag: '',
  backup: null
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case TAGS_SET_TAGS:
      return { ...state, tags: action.tags }
    case TAGS_SET_CURRENT_TAG:
      let tag = findById(state.tags, action.id)
      if (tag) {
        tag = JSON.parse(JSON.stringify(tag))
        return { ...state, currentTag: action.id, backup: tag }
      }
      return state
    case TAGS_INSERT:
      const newTags = [...state.tags]
      newTags.splice(action.position, 0, action.tag)
      return { ...state, tags: newTags }
    case TAGS_REMOVE:
      return {
        ...state,
        tags: state.tags.filter((tag) => tag.id !== action.tagId),
        currentTag: state.currentTag === action.tagId ? '' : state.currentTag
      }
    case TAGS_REMOVE_ALL:
      return {
        ...state,
        tags: [],
        currentTag: ''
      }

    case TAGS_UPDATE_PROPERTY: {
      return stateUpdateTag(state, action.tagId, action.property, action.value)
    }

    case TAGS_REPLACE: {
      const tags = state.tags.map((tag) => {
        if (tag.id === action.tagId) {
          return action.newTag
        }
        return tag
      })
      if (state.backup && state.backup.id === action.tagId) {
        return {
          ...state,
          tags,
          backup: { ...state.backup, sid: action.newTag.sid }
        }
      }
      return { ...state, tags }
    }
    case TAGS_SWAP_BY_ID:
      const fromIdx = state.tags.findIndex(
        (point) => point.id === action.fromId
      )
      const toIdx = state.tags.findIndex((point) => point.id === action.toId)
      return { ...state, tags: swap([...state.tags], fromIdx, toIdx) }
    case TAGS_SET_SENSOR_READING:
      console.log(action)
      const tags = state.tags.map((tag) => {
        if (tag.id === action.id) {
          return { ...tag, sensorReading: action.sensorReading }
        }
        return tag
      })
      return { ...state, tags }
    default:
      return state
  }
}
export default changeState

const stateUpdateTag = (state, tagId, property, value) => {
  const tags = state.tags.map((tag) => {
    if (tag.id === tagId) {
      switch (property) {
        case TAG_PROPERTIES.ORIGINAL_MATTERTAG_SID:
          return { ...tag, originalMattertagSid: value }
        case TAG_PROPERTIES.SID:
          return { ...tag, sid: value }
        case TAG_PROPERTIES.ENABLED:
          return { ...tag, enabled: value }
        case TAG_PROPERTIES.COLOR:
          return { ...tag, color: value }
        case TAG_PROPERTIES.OPACITY:
          return { ...tag, opacity: value }
        case TAG_PROPERTIES.NAME:
          return { ...tag, name: value }
        case TAG_PROPERTIES.LABEL:
          return { ...tag, label: value }
        case TAG_PROPERTIES.DESCRIPTION:
          return { ...tag, description: value }
        case TAG_PROPERTIES.POSITION:
          return { ...tag, position: value }
        case TAG_PROPERTIES.POS_X:
          return { ...tag, position: { ...tag.position, x: value } }
        case TAG_PROPERTIES.POS_Y:
          return { ...tag, position: { ...tag.position, y: value } }
        case TAG_PROPERTIES.POS_Z:
          return { ...tag, position: { ...tag.position, z: value } }
        case TAG_PROPERTIES.STEM_VECTOR:
          return { ...tag, stemVector: value }
        case TAG_PROPERTIES.STEM_X:
          return { ...tag, stemVector: { ...tag.stemVector, x: value } }
        case TAG_PROPERTIES.STEM_Y:
          return { ...tag, stemVector: { ...tag.stemVector, y: value } }
        case TAG_PROPERTIES.STEM_Z:
          return { ...tag, stemVector: { ...tag.stemVector, z: value } }
        case TAG_PROPERTIES.STEM_VISIBLE:
          return { ...tag, stemVisible: value }
        case TAG_PROPERTIES.ICON:
          return { ...tag, icon: value }
        case TAG_PROPERTIES.PB_VISIBLE:
          return {
            ...tag,
            permanentBillboard: { ...tag.permanentBillboard, visible: value }
          }
        case TAG_PROPERTIES.PB_TEXT:
          return {
            ...tag,
            permanentBillboard: { ...tag.permanentBillboard, text: value }
          }
        case TAG_PROPERTIES.PB_OFFSET_X:
          return {
            ...tag,
            permanentBillboard: {
              ...tag.permanentBillboard,
              offset: { ...tag.permanentBillboard.offset, x: value }
            }
          }
        case TAG_PROPERTIES.PB_OFFSET_Y:
          return {
            ...tag,
            permanentBillboard: {
              ...tag.permanentBillboard,
              offset: { ...tag.permanentBillboard.offset, y: value }
            }
          }
        default:
      }
    }
    return tag
  })
  return { ...state, tags }
}
