import { createSelector } from 'reselect'
import {
  modelLoadedSelector,
  sdkSelector,
  cameraPoseSelector,
  currentSweepSelector,
  boundsSelector
} from './reducerMatterport'
import { originalMattertagsSelector } from './reducerMattertags'
import { findById } from '../utils/utils'
import { tagToMattertagData } from './tag'

export const tagsSelector = (state) => state.tags.tags
export const currentTagSelector = (state) => state.tags.currentTag
export const backupSelector = (state) => state.tags.backup
export const tagsInViewSelector = (state) => state.tags.tagsInView

export const getVisibleTags = createSelector(
  [tagsSelector, sdkSelector, cameraPoseSelector, currentSweepSelector],
  (tags, sdk, cameraPose, currentSweep) => {
    // if (
    //   cameraPose &&
    //   cameraPose.mode === sdk.Mode.Mode.INSIDE &&
    //   currentSweep !== ''
    // ) {
    if (cameraPose && cameraPose.mode === sdk.Mode.Mode.INSIDE) {
      return tags.reduce((acc, tag) => {
        if (tag.sensorReading && tag.sensorReading.inView) {
          acc.push(tag)
        }
        return acc
      }, [])
    }
    return []
  }
)

export const getTag = () =>
  createSelector([tagsSelector, (_, id) => id], (tags, id) => {
    return findById(tags, id)
  })

export const getTagIndex = () =>
  createSelector([tagsSelector, (_, id) => id], (tags, id) => {
    return tags.findIndex((tag) => tag.id === id)
  })

export const getCurrentTag = createSelector(
  [tagsSelector, currentTagSelector],
  (tags, currentTag) => {
    return findById(tags, currentTag)
  }
)

export const getCurrentTagOriginalMattertag = createSelector(
  [getCurrentTag, originalMattertagsSelector],
  (currentTag, originalMattertags) => {
    return currentTag
      ? originalMattertags.find(
          (mt) => mt.sid === currentTag.originalMattertagSid
        )
      : null
  }
)

export const getTagDiscData = () =>
  createSelector(
    [tagsSelector, sdkSelector, (_, id) => id],
    (tags, sdk, id) => {
      const tag = findById(tags, id)
      if (tag) {
        const disc3DPosition = {
          x: tag.position.x + tag.stemVector.x,
          y: tag.position.y + tag.stemVector.y,
          z: tag.position.z + tag.stemVector.z
        }
        return { tag, disc3DPosition }
      }
      return null
    }
  )

export const getMattertag = () =>
  createSelector([tagsSelector, (_, id) => id], (tags, id) => {
    return tagToMattertagData(findById(tags, id))
  })

export const getFreeMattertags = createSelector(
  [originalMattertagsSelector, tagsSelector],
  (originalMattertags, tags) => {
    return originalMattertags.filter(
      (mt) => tags.findIndex((tag) => tag.originalMattertagSid === mt.sid) < 0
    )
  }
)

export const getDuplicateMattertags = createSelector(
  [tagsSelector, originalMattertagsSelector],
  (tags, originalMattertags) => {
    const mts = tags.reduce((acc, tag) => {
      if (tag.originalMattertagSid !== '') {
        acc.push(tag.originalMattertagSid)
      }
      return acc
    }, [])

    return Array.from(new Set(mts)).filter(
      (value) => mts.indexOf(value) !== mts.lastIndexOf(value)
    )
  }
)

const calculateTagErrors = (
  tag,
  mattertags,
  modelLoaded,
  duplicateMattertags
) => {
  const errors = { warnings: [], errors: [] }
  if (!tag) {
    errors.errors.push('No se corresponde a ninguna etiqueta actual')
  }
  if (tag && modelLoaded) {
    const mattertag = mattertags.find(
      (mtag) => mtag.sid === tag.originalMattertagSid
    )
    if (!mattertag) {
      errors.errors.push('No se corresponde a ninguna etiqueta del modelo')
    } else {
      if (
        tag.position.x !== mattertag.anchorPosition.x ||
        tag.position.y !== mattertag.anchorPosition.y ||
        tag.position.z !== mattertag.anchorPosition.z
      ) {
        errors.warnings.push('Posicion diferente')
      }
      if (
        tag.stemVector.x !== mattertag.stemVector.x ||
        tag.stemVector.y !== mattertag.stemVector.y ||
        tag.stemVector.z !== mattertag.stemVector.z
      ) {
        errors.warnings.push('Stem diferente')
      }
    }
    if (duplicateMattertags.indexOf(tag.originalMattertagSid) >= 0) {
      errors.errors.push('Mattertags ya usada por otra etiqueta')
    }
  }
  return errors
}

export const getTagsErrors = createSelector(
  [
    tagsSelector,
    originalMattertagsSelector,
    modelLoadedSelector,
    getDuplicateMattertags
  ],
  (tags, originalMattertags, modelLoaded, duplicateMattertags) => {
    return tags.reduce((acc, tag) => {
      const errors = calculateTagErrors(
        tag,
        originalMattertags,
        modelLoaded,
        duplicateMattertags
      )
      if (errors.errors.length > 0 || errors.warnings.length > 0) {
        acc.push({ tagId: tag.id, errors })
      }
      return acc
    }, [])
  }
)

export const getTagErrors = () =>
  createSelector(
    [
      tagsSelector,
      originalMattertagsSelector,
      modelLoadedSelector,
      getDuplicateMattertags,
      (_, id) => id
    ],
    (tags, originalMattertags, modelLoaded, duplicateMattertags, id) => {
      const tag = findById(tags, id)
      return calculateTagErrors(
        tag,
        originalMattertags,
        modelLoaded,
        duplicateMattertags
      )
    }
  )

const calculateMattertagErrors = (sid, freeMattertags, duplicateMattertags) => {
  const errors = { warnings: [], errors: [] }

  if (freeMattertags.find((mt) => mt.sid === sid)) {
    errors.errors.push('No esta asignada a ninguna etiqueta')
  } else if (duplicateMattertags.indexOf(sid) >= 0) {
    errors.errors.push('Asignada a más de una etiqueta')
  }

  return errors
}

export const getMattertagsErrors = createSelector(
  [
    originalMattertagsSelector,
    modelLoadedSelector,
    getDuplicateMattertags,
    getFreeMattertags
  ],
  (originalMattertags, modelLoaded, duplicateMattertags, freeMattertags) => {
    return originalMattertags.reduce((acc, mt) => {
      const errors = calculateMattertagErrors(
        mt.sid,
        freeMattertags,
        duplicateMattertags
      )
      if (errors.errors.length > 0 || errors.warnings.length > 0) {
        acc.push({ sid: mt.sid, errors })
      }
      return acc
    }, [])
  }
)

export const getMattertagErrors = () =>
  createSelector(
    [getDuplicateMattertags, getFreeMattertags, (_, sid) => sid],
    (duplicateMattertags, freeMattertags, sid) => {
      return calculateMattertagErrors(sid, freeMattertags, duplicateMattertags)
    }
  )
