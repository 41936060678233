import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import FloatValue from '../../../Common/Input/FloatValue'
import { updateTag } from '../../../../modules/reducerTagsActions'
import { TAG_PROPERTIES } from '../../../../modules/tag'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

export const TEGStemVectorX = ({ id, value, warning }) => {
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (value) => {
      dispatch(updateTag(id, TAG_PROPERTIES.STEM_X, value))
    },
    [dispatch, id]
  )

  return (
    <FloatValue
      label="Stem X"
      value={value}
      warning={warning}
      onChange={updateTagValue}
    />
  )
}

export const TEGStemVectorY = ({ id, value, warning }) => {
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (value) => {
      dispatch(updateTag(id, TAG_PROPERTIES.STEM_Y, value))
    },
    [dispatch, id]
  )

  return (
    <FloatValue
      label="Stem Y"
      value={value}
      warning={warning}
      onChange={updateTagValue}
    />
  )
}
export const TEGStemVectorZ = ({ id, value, warning }) => {
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (value) => {
      dispatch(updateTag(id, TAG_PROPERTIES.STEM_Z, value))
    },
    [dispatch, id]
  )

  return (
    <FloatValue
      label="Stem Z"
      value={value}
      warning={warning}
      onChange={updateTagValue}
    />
  )
}
