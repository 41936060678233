import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DraggableListItem from '../../Common/List/DraggableListItem'
import ListButton from '../../Common/List/ListButton'
import { AddDuplicateIcon, EditIcon, RemoveIcon } from '../../Icons/Icons'
import {
  setCurrentLateralContent,
  LATERAL_CONTENT
} from '../../../modules/reducerMain'
import {
  currentGroupSelector,
  getGroup,
  setCurrentGroup,
  removeGroup,
  insertGroup
} from '../../../modules/reducerGroups'

const GLItem = ({ id, index }) => {
  const currentGroup = useSelector(currentGroupSelector)
  const dispatch = useDispatch()
  const gGroup = useMemo(getGroup, [])
  const group = useSelector((state) => gGroup(state, id))

  const handleSelect = useCallback(() => {
    dispatch(setCurrentGroup(id))
  }, [dispatch, id])

  const handleEdit = useCallback(() => {
    dispatch(setCurrentGroup(id))
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.EDIT_GROUP))
  }, [dispatch, id])

  const handleAdd = useCallback(() => {
    dispatch(insertGroup(index + 1, id))
  }, [dispatch, index, id])

  const handleRemove = useCallback(() => {
    dispatch(removeGroup(id))
  }, [dispatch, id])

  return group ? (
    <DraggableListItem
      key={group.id}
      id={group.id}
      index={index}
      currentItemId={currentGroup}
      primaryText={group.name}
      secondaryText={group.description}
      icon={null}
      onSelect={handleSelect}
      hoverButtons={
        <>
          <ListButton tooltipTitle="Editar" onClick={handleEdit}>
            <EditIcon />
          </ListButton>
          <ListButton tooltipTitle="Añadir/Duplicar" onClick={handleAdd}>
            <AddDuplicateIcon />
          </ListButton>
          <ListButton tooltipTitle="Borrar" onClick={handleRemove}>
            <RemoveIcon />
          </ListButton>
        </>
      }
    ></DraggableListItem>
  ) : null
}
export default GLItem
