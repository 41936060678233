import { useCallback, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Lateral from '../Lateral/Lateral'
import TagsList from '../Tags/List/TagsList'
import TagEditor from '../Tags/Editor/TagEditor'
import PointsList from '../Points/List/PointsList'
import PointEditor from '../Points/Editor/PointEditor'
import GroupsList from '../Groups/List/GroupsList'
import GroupEditor from '../Groups/Editor/GroupEditor'
import MattertagsList from '../Mattertags/List/MattertagsList'
import CurrentMattertagDetails from '../Mattertags/Details/CurrentMattertagDetails'

import {
  setWidths,
  setVisible,
  setTitle,
  setCloseButton
} from '../../modules/reducerLateral'
import {
  currentLateralContentSelector,
  getCurrentLateralContent,
  setCurrentMenuOption,
  LATERAL_CONTENT
} from '../../modules/reducerMain'

const LateralContent = () => {
  const dispatch = useDispatch()
  const currentLateral = useSelector(currentLateralContentSelector)
  const currentLateralContent = useSelector(getCurrentLateralContent)

  useEffect(() => {
    if (currentLateralContent) {
      dispatch(setVisible(true))
      dispatch(
        setWidths(
          currentLateralContent.minWidth,
          currentLateralContent.maxWidth,
          currentLateralContent.defaultWidth
        )
      )
      dispatch(setTitle(currentLateralContent.title))
      dispatch(setCloseButton(currentLateralContent.closeButton))
    } else {
      dispatch(setVisible(false))
    }
  }, [currentLateralContent, dispatch])

  const getContent = (id) => {
    switch (id) {
      case LATERAL_CONTENT.TAGS_LIST:
        return <TagsList />
      case LATERAL_CONTENT.MATTERTAGS_LIST:
        return <MattertagsList />
      case LATERAL_CONTENT.EDIT_TAG:
        return <TagEditor />
      case LATERAL_CONTENT.POINTS_LIST:
        return <PointsList />
      case LATERAL_CONTENT.EDIT_POINT:
        return <PointEditor />
      case LATERAL_CONTENT.GROUPS_LIST:
        return <GroupsList />
      case LATERAL_CONTENT.EDIT_GROUP:
        return <GroupEditor />
      case LATERAL_CONTENT.MATTERTAGS_DETAILS:
        return <CurrentMattertagDetails />
      default:
        return null
    }
  }

  const handleClose = useCallback(
    (position) => {
      if (currentLateralContent.id === LATERAL_CONTENT.EDIT_TAG) {
      } else {
        dispatch(setCurrentMenuOption(''))
      }
    },
    [dispatch, currentLateralContent]
  )

  return <Lateral onClose={handleClose}>{getContent(currentLateral)}</Lateral>
}
export default LateralContent
//
