import { makeStyles } from '@material-ui/core/styles'

import {
  TagIcon,
  SaveIcon,
  OpenIcon,
  PointIcon,
  GroupIcon,
  MattertagIcon,
  FloorIcon
} from '../Icons/Icons'
import { MENU_OPTIONS } from '../../modules/reducerMain'
const useStyles = makeStyles((theme) => ({
  icon: {
    width: '24px',
    height: 'auto'
  }
}))

const OptionIcon = ({ optionId }) => {
  const classes = useStyles()

  const getIcon = (menuOptionId) => {
    switch (menuOptionId) {
      case MENU_OPTIONS.GROUPS:
        return <GroupIcon className={classes.icon} />
      case MENU_OPTIONS.POINTS:
        return <PointIcon className={classes.icon} />
      case MENU_OPTIONS.FLOORS:
        return <FloorIcon className={classes.icon} />
      case MENU_OPTIONS.TAGS:
        return <TagIcon className={classes.icon} />
      case MENU_OPTIONS.SAVE:
        return <SaveIcon className={classes.icon} />
      case MENU_OPTIONS.OPEN:
        return <OpenIcon className={classes.icon} />
      case MENU_OPTIONS.MATTERTAGS:
        return <MattertagIcon className={classes.icon} />

      default:
        return null
    }
  }

  return getIcon(optionId)
}
export default OptionIcon
