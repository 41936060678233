import LayersIcon from '@material-ui/icons/Layers'
import AdjustIcon from '@material-ui/icons/Adjust'
import ArchiveIcon from '@material-ui/icons/Archive'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import BookmarksIcon from '@material-ui/icons/Bookmarks'
import CropDinIcon from '@material-ui/icons/CropDin'
import FilterListIcon from '@material-ui/icons/FilterList'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIconMaterial from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import GetAppIcon from '@material-ui/icons/GetApp'
import MouseIcon from '@material-ui/icons/Mouse'
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import DetailsIconMaterial from '@material-ui/icons/Details'
import ErrorIconMaterial from '@material-ui/icons/Error'
import ReceiptIcon from '@material-ui/icons/Receipt'

import ImageIcon from '@material-ui/icons/Image'
import ListIconMaterial from '@material-ui/icons/List'

export const PermanentBillboardIcon = (props) => {
  return <ReceiptIcon {...props} />
}

export const MultimediaIcon = (props) => {
  return <ImageIcon {...props} />
}

export const ListIcon = (props) => {
  return <ListIconMaterial {...props} />
}

export const DetailsIcon = (props) => {
  return <DetailsIconMaterial {...props} />
}

export const ErrorIcon = (props) => {
  return <ErrorIconMaterial {...props} />
}

export const ImportIcon = (props) => {
  return <GetAppIcon {...props} />
}

export const CursorIcon = (props) => {
  return <MouseIcon {...props} />
}

export const GotoIcon = (props) => {
  return <GpsNotFixedIcon {...props} />
}

export const VisibleIcon = (props) => {
  return <VisibilityIcon {...props} />
}

export const NotVisibleIcon = (props) => {
  return <VisibilityOffIcon {...props} />
}
export const FloorIcon = (props) => {
  return <LayersIcon {...props} />
}

export const TagIcon = (props) => {
  return <AdjustIcon {...props} />
}

export const SaveIcon = (props) => {
  return <ArchiveIcon {...props} />
}

export const OpenIcon = (props) => {
  return <InsertDriveFileIcon {...props} />
}

export const GrabCameraPoseIcon = (props) => {
  return <PhotoCameraIcon {...props} />
}

export const ClearFilterIcon = (props) => {
  return <ClearAllIcon {...props} />
}

export const FilterIcon = (props) => {
  return <FilterListIcon {...props} />
}

export const PointIcon = (props) => {
  return <BookmarkIcon {...props} />
}

export const GroupIcon = (props) => {
  return <BookmarksIcon {...props} />
}

export const MattertagIcon = (props) => {
  return <CropDinIcon {...props} />
}

export const AddDuplicateIcon = (props) => {
  return <AddIcon {...props} />
}

export const RemoveIcon = (props) => {
  return <DeleteIcon {...props} />
}

export const RemoveAllIcon = (props) => {
  return <DeleteIcon {...props} />
}

export const EditIcon = (props) => {
  return <EditIconMaterial {...props} />
}
