import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BarButton from './BarButton'
import { FilterIcon } from '../Icons/Icons'
import {
  enabledSelector,
  setFilterEnable
} from '../../modules/reducerMattertagsFilter'

const EnableMattertagsFilterButton = () => {
  const filterEnabled = useSelector(enabledSelector)
  const dispatch = useDispatch()

  const handleEnableFilter = useCallback(() => {
    dispatch(setFilterEnable(!filterEnabled))
  }, [dispatch, filterEnabled])

  return (
    <BarButton
      tooltipTitle={filterEnabled ? 'Deshabilitar filtro' : 'Habilitar filtro'}
      tooltipEnabled
      selected={filterEnabled}
      onClick={handleEnableFilter}
    >
      <FilterIcon />
    </BarButton>
  )
}
export default EnableMattertagsFilterButton
