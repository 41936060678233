import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import EditorTextField from '../../Common/Input/EditorTextField'
import {
  updateGroupProperty,
  GROUP_PROPERTIES
} from '../../../modules/reducerGroups'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const GEName = ({ id, name }) => {
  // const tag = useSelector(getCurrentTag)
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateGroupValue = useCallback(
    (value) => {
      dispatch(updateGroupProperty(id, GROUP_PROPERTIES.NAME, value))
    },
    [dispatch, id]
  )

  return (
    <EditorTextField label="Nombre" value={name} onChange={updateGroupValue} />
  )
}
export default GEName
