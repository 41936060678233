// import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import TEGName from './TEGName'
import TEGDescription from './TEGDescription'
import { TEGPositionX, TEGPositionY, TEGPositionZ } from './TEGPosition'
import { TEGStemVectorX, TEGStemVectorY, TEGStemVectorZ } from './TEGStemVector'
import TEGStemVisible from './TEGStemVisible'
import TEGIcon from './TEGIcon'
import TEGColor from './TEGColor'
import TEGLabel from './TEGLabel'
import TEGOpacity from './TEGOpacity'
import { animatingWidthSelector } from '../../../../modules/reducerLateral'
import {
  getCurrentTag,
  getCurrentTagOriginalMattertag
} from '../../../../modules/reducerTagsSelectors'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    boxSizing: 'border-box',
    paddingTop: 16,
    margin: 0,

    width: '100%',
    // height: '100%',
    // maxHeight: '200px',
    overflow: 'auto'

    // display: 'flex',
    // flexFlow: 'column'
  }
}))

const TagsEditorGeneral = () => {
  const tag = useSelector(getCurrentTag)
  const mattertag = useSelector(getCurrentTagOriginalMattertag)
  const classes = useStyles()
  const animatingWidth = useSelector(animatingWidthSelector)
  const small = animatingWidth < 500
  return tag ? (
    <Grid
      container
      className={classes.root}
      spacing={2}
      alignContent="flex-start"
    >
      <Grid item xs={12}>
        <TEGName id={tag.id} name={tag.name} />
      </Grid>
      <Grid item xs={12}>
        <TEGLabel id={tag.id} value={tag.label} />
      </Grid>
      <Grid item xs={12}>
        <TEGDescription id={tag.id} description={tag.description} />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGPositionX
          id={tag.id}
          value={tag.position.x}
          warning={
            mattertag ? mattertag.anchorPosition.x !== tag.position.x : false
          }
        />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGPositionY
          id={tag.id}
          value={tag.position.y}
          warning={
            mattertag ? mattertag.anchorPosition.y !== tag.position.y : false
          }
        />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGPositionZ
          id={tag.id}
          value={tag.position.z}
          warning={
            mattertag ? mattertag.anchorPosition.z !== tag.position.z : false
          }
        />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGStemVectorX
          id={tag.id}
          value={tag.stemVector.x}
          warning={
            mattertag ? mattertag.stemVector.x !== tag.stemVector.x : false
          }
        />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGStemVectorY
          id={tag.id}
          value={tag.stemVector.y}
          warning={
            mattertag ? mattertag.stemVector.y !== tag.stemVector.y : false
          }
        />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGStemVectorZ
          id={tag.id}
          value={tag.stemVector.z}
          warning={
            mattertag ? mattertag.stemVector.z !== tag.stemVector.z : false
          }
        />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGStemVisible
          id={tag.id}
          value={tag.stemVisible}
          warning={
            mattertag ? mattertag.stemVisible !== tag.stemVisible : false
          }
        />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGColor
          id={tag.id}
          value={tag.color}
          warning={
            mattertag
              ? mattertag.color.r !== tag.color.r ||
                mattertag.color.g !== tag.color.g ||
                mattertag.color.b !== tag.color.b
              : false
          }
        />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGOpacity id={tag.id} value={tag.opacity} />
      </Grid>
      <Grid item xs={small ? 12 : 4}>
        <TEGIcon id={tag.id} value={tag.icon} />
      </Grid>
    </Grid>
  ) : null
}
export default TagsEditorGeneral
//
