import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import BarButton from './BarButton'
import { ClearFilterIcon } from '../Icons/Icons'
import { enabledSelector, resetFilter } from '../../modules/reducerPointsFilter'

const ResetPointsFilterButton = () => {
  const filterEnabled = useSelector(enabledSelector)
  const dispatch = useDispatch()

  const handleClearFilter = useCallback(() => {
    dispatch(resetFilter())
  }, [dispatch])

  return (
    <BarButton
      hide={!filterEnabled}
      tooltipTitle={'Reset filtro'}
      tooltipEnabled
      onClick={handleClearFilter}
    >
      <ClearFilterIcon />
    </BarButton>
  )
}
export default ResetPointsFilterButton
