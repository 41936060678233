import { createSelector } from 'reselect'
// import { findById } from '../utils/utils'
// import { sdkSelector, modelLoadedSelector } from './reducerMatterport'

export const CURSOR_SET_ENABLED = 'CURSOR_SET_ENABLED'
export const CURSOR_SET_INTERSECTION = 'CURSOR_SET_INTERSECTION'
export const CURSOR_BUTTON_CLICK = 'CURSOR_BUTTON_CLICK'
export const CURSOR_SET_VISIBLE_OPTIONS = 'CURSOR_SET_VISIBLE_OPTIONS'

export const OPTIONS = {
  TAG_GET_POSITION: 'op0',
  TAG_GET_NORMAL: 'op1',
  TAG_GET_POSITION_AND_NORMAL: 'op2'
}
/*
WAIT_MOUSE_ENTER
ENABLED
*/
const initialState = {
  enabled: false,
  intersection: null,
  options: [
    { id: OPTIONS.TAG_GET_POSITION, title: 'Get position' },
    { id: OPTIONS.TAG_GET_NORMAL, title: 'Get normal' },
    { id: OPTIONS.TAG_GET_POSITION_AND_NORMAL, title: 'Get position & normal' }
  ],
  visibleOptions: new Set()
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case CURSOR_SET_ENABLED:
      return { ...state, enabled: action.enabled, intersection: null }
    case CURSOR_SET_INTERSECTION:
      return { ...state, intersection: action.intersection }
    case CURSOR_SET_VISIBLE_OPTIONS:
      return { ...state, visibleOptions: new Set(action.options) }
    default:
      return state
  }
}
export default changeState

export const setCursorEnabled = (enabled) => ({
  type: CURSOR_SET_ENABLED,
  enabled
})

export const setIntersection = (intersection) => ({
  type: CURSOR_SET_INTERSECTION,
  intersection
})

// export const cursorButtonClick = (id) => (dispatch, getState) => {
//   const state = getState()
//   const modelLoaded = modelLoadedSelector(state)
//   if (modelLoaded) {
//     dispatch({
//       type: CURSOR_BUTTON_CLICK,
//       id,
//       intersection: intersectionSelector(state)
//     })
//     return
//   }
// }

export const setCursorVisibleOptions = (options) => ({
  type: CURSOR_SET_VISIBLE_OPTIONS,
  options
})

export const setModeTagEditor = (options) => ({
  type: CURSOR_SET_VISIBLE_OPTIONS,
  options: [
    OPTIONS.TAG_GET_POSITION,
    OPTIONS.TAG_GET_NORMAL,
    OPTIONS.TAG_GET_POSITION_AND_NORMAL
  ]
})

export const cursorEnabledSelector = (state) => state.cursor.enabled
export const intersectionSelector = (state) => state.cursor.intersection
export const optionsSelector = (state) => state.cursor.options
export const visibleOptionsSelector = (state) => state.cursor.visibleOptions

export const getVisibleOptions = createSelector(
  [optionsSelector, visibleOptionsSelector],
  (options, visibleOptions) => {
    return options.filter((o) => visibleOptions.has(o.id))
  }
)
