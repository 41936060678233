import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import FloatValue from '../../Common/Input/FloatValue'
import {
  positionSelector,
  setPosition,
  incSelector,
  setInc
} from '../../../modules/reducerTagsFilter'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

export const TFPositionX = () => {
  // const classes = useStyles()
  const position = useSelector(positionSelector)
  const dispatch = useDispatch()

  const handleXChange = useCallback(
    (value) => {
      dispatch(setPosition({ ...position, x: value }))
    },
    [dispatch, position]
  )

  return (
    <FloatValue
      label="Posición X"
      value={position.x}
      onChange={handleXChange}
    />
  )
}

export const TFPositionY = () => {
  // const classes = useStyles()
  const position = useSelector(positionSelector)
  const dispatch = useDispatch()

  const handleYChange = useCallback(
    (value) => {
      dispatch(setPosition({ ...position, y: value }))
    },
    [dispatch, position]
  )

  return (
    <FloatValue
      label="Posición Y"
      value={position.y}
      onChange={handleYChange}
    />
  )
}

export const TFPositionZ = () => {
  // const classes = useStyles()
  const position = useSelector(positionSelector)
  const dispatch = useDispatch()

  const handleZChange = useCallback(
    (value) => {
      dispatch(setPosition({ ...position, z: value }))
    },
    [dispatch, position]
  )

  return (
    <FloatValue
      label="Posición Z"
      value={position.z}
      onChange={handleZChange}
    />
  )
}

export const TFIncX = () => {
  // const classes = useStyles()
  const inc = useSelector(incSelector)
  const dispatch = useDispatch()

  const handleIncXChange = useCallback(
    (value) => {
      dispatch(setInc({ ...inc, x: value }))
    },
    [dispatch, inc]
  )

  return <FloatValue label="Inc X" value={inc.x} onChange={handleIncXChange} />
}

export const TFIncY = () => {
  // const classes = useStyles()
  const inc = useSelector(incSelector)
  const dispatch = useDispatch()

  const handleIncYChange = useCallback(
    (value) => {
      dispatch(setInc({ ...inc, y: value }))
    },
    [dispatch, inc]
  )

  return <FloatValue label="Inc Y" value={inc.y} onChange={handleIncYChange} />
}

export const TFIncZ = () => {
  // const classes = useStyles()
  const inc = useSelector(incSelector)
  const dispatch = useDispatch()

  const handleIncZChange = useCallback(
    (value) => {
      dispatch(setInc({ ...inc, z: value }))
    },
    [dispatch, inc]
  )

  return <FloatValue label="Inc Z" value={inc.z} onChange={handleIncZChange} />
}
