import { createSelector } from 'reselect'
// import { findById } from '../utils/utils'

export const LATERAL_SET_BREAK_POINTS = 'LATERAL_SET_BREAK_POINTS'
export const LATERAL_SET_WIDTH = 'LATERAL_SET_WIDTH'
export const LATERAL_SET_ANIMATING_WIDTH = 'LATERAL_SET_ANIMATING_WIDTH'
export const LATERAL_COLLAPSE = 'LATERAL_COLLAPSE'
export const LATERAL_EXPAND = 'LATERAL_EXPAND'
export const LATERAL_SET_VISIBLE = 'LATERAL_SET_VISIBLE'
export const LATERAL_SET_TITLE = 'LATERAL_SET_TITLE'
export const LATERAL_SET_CLOSE_BUTTON = 'LATERAL_SET_CLOSE_BUTTON'

export const BREAKPOINTS = {
  COLLAPSED: 'COLLAPSED',
  MIN: 'MIN',
  SMALLER_DEFAULT: 'SMALLER_DEFAULT',
  SMALLER_MAX: 'SMALLER_MAX',
  MAX: 'MAX'
}

const initialState = {
  minWidth: 0,
  maxWidth: 1000,
  defaultWidth: 500,
  width: 0,
  finalWidth: 0,
  animatingWidth: 0,
  bkWidth: 0,
  visible: true,
  title: '',
  closeButton: true
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case LATERAL_SET_TITLE:
      return { ...state, title: action.title }
    case LATERAL_SET_CLOSE_BUTTON:
      return { ...state, closeButton: action.closeButton }
    case LATERAL_SET_BREAK_POINTS: {
      const minWidth = Math.max(0, action.minWidth)
      const maxWidth = Math.max(minWidth, action.maxWidth)
      const defaultWidth = Math.min(
        Math.max(minWidth, action.defaultWidth),
        maxWidth
      )
      let finalWidth = state.width
      if (finalWidth > maxWidth) {
        finalWidth = defaultWidth
      } else if (finalWidth < minWidth) {
        finalWidth = defaultWidth
      }
      return {
        ...state,
        minWidth,
        maxWidth,
        defaultWidth,
        // width: defaultWidth,
        finalWidth,
        animatingWidth: state.width
      }
    }

    case LATERAL_SET_WIDTH: {
      if (action.width === 0 && state.finalWidth === 0) {
        return { ...state, width: 0, finalWidth: 0 }
      }
      const width = Math.min(
        Math.max(state.minWidth, action.width),
        state.maxWidth
      )
      return { ...state, width, finalWidth: width, animatingWidth: width }
    }
    case LATERAL_SET_ANIMATING_WIDTH: {
      return { ...state, animatingWidth: action.width }
    }
    case LATERAL_COLLAPSE:
      if (state.width > 0) {
        return {
          ...state,
          finalWidth: 0,
          animatingWidth: state.width,
          bkWidth: state.width
        }
      }
      return state
    case LATERAL_EXPAND:
      if (state.width === 0) {
        return {
          ...state,
          finalWidth: state.bkWidth,
          animatingWidth: state.width
        }
      }
      return state
    case LATERAL_SET_VISIBLE:
      if (action.visible) {
        return {
          ...state,
          visible: action.visible,
          finalWidth: state.defaultWidth
        }
      }
      return {
        ...state,
        visible: action.visible,
        finalWidth: 0
      }
    default:
      return state
  }
}
export default changeState

export const setTitle = (title) => ({
  type: LATERAL_SET_TITLE,
  title
})
export const setCloseButton = (closeButton) => ({
  type: LATERAL_SET_CLOSE_BUTTON,
  closeButton
})

export const setWidths = (minWidth, maxWidth, defaultWidth) => ({
  type: LATERAL_SET_BREAK_POINTS,
  minWidth,
  maxWidth,
  defaultWidth
})

export const setWidth = (width) => ({
  type: LATERAL_SET_WIDTH,
  width
})
export const setAnimatingWidth = (width) => ({
  type: LATERAL_SET_ANIMATING_WIDTH,
  width
})

export const collapse = () => ({
  type: LATERAL_COLLAPSE
})

export const expand = () => ({
  type: LATERAL_EXPAND
})

export const setVisible = (visible) => ({
  type: LATERAL_SET_VISIBLE,
  visible
})

export const minWidthSelector = (state) => state.lateral.minWidth
export const maxWidthSelector = (state) => state.lateral.maxWidth
export const defaultWidthSelector = (state) => state.lateral.defaultWidth
export const widthSelector = (state) => state.lateral.width
export const finalWidthSelector = (state) => state.lateral.finalWidth
export const animatingWidthSelector = (state) => state.lateral.animatingWidth
export const visibleSelector = (state) => state.lateral.visible
export const titleSelector = (state) => state.lateral.title
export const closeButtonSelector = (state) => state.lateral.closeButton

export const getWidth = createSelector(
  [widthSelector, finalWidthSelector, animatingWidthSelector],
  (width, finalWidth, animatingWidth) => {
    return width !== finalWidth ? animatingWidth : width
  }
)
export const getCurrentBreakPoint = createSelector(
  [getWidth, minWidthSelector, maxWidthSelector, defaultWidthSelector],
  (width, min, max, def) => {
    if (width === 0) {
      return BREAKPOINTS.COLLAPSED
    } else if (width === min) {
      return BREAKPOINTS.MIN
    } else if (width < def) {
      return BREAKPOINTS.SMALLER_DEFAULT
    } else if (width < max) {
      return BREAKPOINTS.SMALLER_MAX
    } else if (width === max) {
      return BREAKPOINTS.MAX
    }
  }
)
