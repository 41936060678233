// import { useCallback, useState, useMemo } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const LBOpenFileAlertDialog = ({ open, error, onClose }) => {
  // const classes = useStyles()

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <DialogContentText>{error}. ¿Abrir de todas formas?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Disagree
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default LBOpenFileAlertDialog
