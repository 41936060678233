import { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import DraggableList from '../../Common/List/DraggableList'

import GEPointItem from './GEPointItem'
import {
  swapPoints,
  insertPoint,
  getCurrentGroup,
  getCurrentGroupFreePoints
} from '../../../modules/reducerGroups'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 0,
    flex: '1 1 auto',
    borderRadius: 4,
    margin: 8
  },
  legend: { color: theme.palette.primary.contrastText }
}))

const GEPointList = () => {
  const [currentPoint, setCurrentPoint] = useState('')
  const classes = useStyles()
  const currentGroup = useSelector(getCurrentGroup)
  const freePoints = useSelector(getCurrentGroupFreePoints)
  const dispatch = useDispatch()

  const onDragEnd = (fromIdx, toIdx) => {
    dispatch(
      swapPoints(
        currentGroup.id,
        currentGroup.points[fromIdx].id,
        currentGroup.points[toIdx].id
      )
    )
  }

  const handleSelect = (id) => {
    setCurrentPoint(id)
  }

  return (
    <fieldset className={classes.root}>
      <legend className={classes.legend}>Puntos</legend>
      <DraggableList onSwap={onDragEnd}>
        {currentGroup.points.map((point, index) => (
          <GEPointItem
            key={point.id}
            groupId={currentGroup.id}
            pointId={point.id}
            index={index}
            currentItemId={currentPoint}
            onSelect={handleSelect}
          />
        ))}
      </DraggableList>
    </fieldset>
  )
}
export default GEPointList
