import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ImportIcon } from '../Icons/Icons'
import BarButton from './BarButton'
import { originalMattertagsSelector } from '../../modules/reducerMattertags'
import { importMattertags } from '../../modules/reducerTagsActions'

const ImportMattertagsButton = () => {
  const originalMattertags = useSelector(originalMattertagsSelector)
  const dispatch = useDispatch()

  const handleImport = useCallback(() => {
    dispatch(importMattertags())
  }, [dispatch])

  return (
    <BarButton
      tooltipTitle={'Importar mattertags'}
      tooltipEnabled
      disabled={originalMattertags.length === 0}
      onClick={handleImport}
    >
      <ImportIcon />
    </BarButton>
  )
}
export default ImportMattertagsButton
