import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DraggableListItem from '../../Common/List/DraggableListItem'
import ListButton from '../../Common/List/ListButton'
import {
  GrabCameraPoseIcon,
  AddDuplicateIcon,
  EditIcon,
  RemoveIcon
} from '../../Icons/Icons'
import {
  setCurrentLateralContent,
  LATERAL_CONTENT
} from '../../../modules/reducerMain'
import {
  currentPointSelector,
  getPoint,
  setCurrentPoint,
  removePoint,
  insertPoint,
  updatePointProperty,
  POINT_PROPERTIES
} from '../../../modules/reducerPoints'

import {
  modelLoadedSelector,
  cameraPoseSelector
} from '../../../modules/reducerMatterport'

const PLItem = ({ id, index }) => {
  const currentPoint = useSelector(currentPointSelector)
  const dispatch = useDispatch()
  const gPoint = useMemo(getPoint, [])
  const point = useSelector((state) => gPoint(state, id))
  const cameraPose = useSelector(cameraPoseSelector)
  const modelLoaded = useSelector(modelLoadedSelector)

  const handleSelect = useCallback(() => {
    dispatch(setCurrentPoint(id))
  }, [dispatch, id])

  const handleEdit = useCallback(() => {
    dispatch(setCurrentPoint(id))
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.EDIT_POINT))
  }, [dispatch, id])

  const handleAdd = useCallback(() => {
    dispatch(insertPoint(index + 1, id))
  }, [dispatch, index, id])

  const handleRemove = useCallback(() => {
    dispatch(removePoint(id))
  }, [dispatch, id])

  const handleCamera = useCallback(() => {
    dispatch(updatePointProperty(id, POINT_PROPERTIES.POSE, cameraPose))
  }, [dispatch, id, cameraPose])

  return point ? (
    <DraggableListItem
      key={point.id}
      id={point.id}
      index={index}
      currentItemId={currentPoint}
      primaryText={point.name}
      secondaryText={point.description}
      icon={null}
      onSelect={handleSelect}
      hoverButtons={
        <>
          <ListButton
            hide={!modelLoaded}
            tooltipTitle="Guardar posición"
            onClick={handleCamera}
          >
            <GrabCameraPoseIcon />
          </ListButton>

          <ListButton tooltipTitle="Editar" onClick={handleEdit}>
            <EditIcon />
          </ListButton>
          <ListButton tooltipTitle="Añadir/Duplicar" onClick={handleAdd}>
            <AddDuplicateIcon />
          </ListButton>
          <ListButton tooltipTitle="Borrar" onClick={handleRemove}>
            <RemoveIcon />
          </ListButton>
        </>
      }
    ></DraggableListItem>
  ) : null
}
export default PLItem
