import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ToggleVisibilityButton from './ToggleVisibilityButton'
import {
  setOriginalMatetertagVisible,
  getOriginalMattertag
} from '../../modules/reducerMattertags'

const ShowMattertagButton = ({ sid }) => {
  const gOriginalMattertag = useMemo(getOriginalMattertag, [])
  const mattertag = useSelector((state) => gOriginalMattertag(state, sid))
  const visible = mattertag && mattertag.visibleSid

  const dispatch = useDispatch()

  const handleChange = useCallback(
    (value) => {
      dispatch(setOriginalMatetertagVisible(sid, value))
    },
    [dispatch, sid]
  )

  return <ToggleVisibilityButton visible={visible} onChange={handleChange} />
}
export default ShowMattertagButton
