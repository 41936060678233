import { createSelector } from 'reselect'
// import { sdkSelector } from './reducerMatterport'
import { originalMattertagsSelector } from './reducerMattertags'
import { tagsSelector, getMattertagsErrors } from './reducerTagsSelectors'
// import { findById } from '../utils/utils'

export const MATTERTAGS_FILTER_SET_ENABLED = 'MATTERTAGS_FILTER_SET_ENABLED'
export const MATTERTAGS_FILTER_RESET = 'MATTERTAGS_FILTER_RESET'
export const MATTERTAGS_FILTER_UPDATE_PROPERTY =
  'MATTERTAGS_FILTER_UPDATE_PROPERTY'

export const MATTERTAG_STATES = [
  { id: 'st0', name: 'Todas' },
  { id: 'st1', name: 'Libres' },
  { id: 'st2', name: 'Ocupadas' },
  { id: 'st3', name: 'Error' }
]

export const MATTERTAGS_FILTER_PROPERTIES = {
  NAME: 'name',
  FLOOR_ID: 'floorId',
  STATE: 'state',
  POSITION: 'position',
  INC: 'inc',
  LABEL: 'label',
  SID: 'sid'
}

const initialState = {
  enabled: false,
  floorId: '',
  state: 'st0',
  position: { x: 0, y: 0, z: 0 },
  inc: { x: 1000, y: 1000, z: 1000 },
  label: '',
  name: '',
  sid: ''
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case MATTERTAGS_FILTER_SET_ENABLED:
      return { ...state, enabled: action.enabled }
    case MATTERTAGS_FILTER_RESET:
      return stateClearFilter(state)
    case MATTERTAGS_FILTER_UPDATE_PROPERTY:
      return stateUpdateProperty(state, action.property, action.value)
    default:
      return state
  }
}
export default changeState

const stateClearFilter = (state) => {
  return {
    ...state,
    floorId: '',
    state: 'st0',
    position: { x: 0, y: 0, z: 0 },
    inc: { x: 1000, y: 1000, z: 1000 },
    label: '',
    name: '',
    sid: ''
  }
}

const stateUpdateProperty = (state, property, value) => {
  const newState = { ...state }
  newState[property] = value
  return newState
}

export const setFilterEnable = (enabled) => ({
  type: MATTERTAGS_FILTER_SET_ENABLED,
  enabled
})

export const enableFilter = () => ({
  type: MATTERTAGS_FILTER_SET_ENABLED,
  enabled: true
})

export const disableFilter = () => ({
  type: MATTERTAGS_FILTER_SET_ENABLED,
  enabled: false
})

export const resetFilter = () => ({
  type: MATTERTAGS_FILTER_RESET
})

export const updateProperty = (property, value) => ({
  type: MATTERTAGS_FILTER_UPDATE_PROPERTY,
  property,
  value
})

export const setName = (value) => ({
  type: MATTERTAGS_FILTER_UPDATE_PROPERTY,
  property: MATTERTAGS_FILTER_PROPERTIES.NAME,
  value
})

export const setFloor = (value) => ({
  type: MATTERTAGS_FILTER_UPDATE_PROPERTY,
  property: MATTERTAGS_FILTER_PROPERTIES.FLOOR_ID,
  value
})

export const setState = (value) => ({
  type: MATTERTAGS_FILTER_UPDATE_PROPERTY,
  property: MATTERTAGS_FILTER_PROPERTIES.STATE,
  value
})

export const setPosition = (value) => ({
  type: MATTERTAGS_FILTER_UPDATE_PROPERTY,
  property: MATTERTAGS_FILTER_PROPERTIES.POSITION,
  value
})

export const setInc = (value) => ({
  type: MATTERTAGS_FILTER_UPDATE_PROPERTY,
  property: MATTERTAGS_FILTER_PROPERTIES.INC,
  value
})

export const setLabel = (value) => ({
  type: MATTERTAGS_FILTER_UPDATE_PROPERTY,
  property: MATTERTAGS_FILTER_PROPERTIES.LABEL,
  value
})

export const setSid = (value) => ({
  type: MATTERTAGS_FILTER_UPDATE_PROPERTY,
  property: MATTERTAGS_FILTER_PROPERTIES.SID,
  value
})

export const enabledSelector = (state) => state.mattertagsFilter.enabled
export const floorIdSelector = (state) => state.mattertagsFilter.floorId
export const stateSelector = (state) => state.mattertagsFilter.state
export const positionSelector = (state) => state.mattertagsFilter.position
export const incSelector = (state) => state.mattertagsFilter.inc
export const labelSelector = (state) => state.mattertagsFilter.label
export const nameSelector = (state) => state.mattertagsFilter.name
export const sidSelector = (state) => state.mattertagsFilter.sid

export const getFilteredMattertags = createSelector(
  [
    originalMattertagsSelector,
    getMattertagsErrors,
    tagsSelector,
    enabledSelector,
    floorIdSelector,
    stateSelector,
    positionSelector,
    incSelector,
    labelSelector,
    sidSelector
  ],
  (
    originalMattertags,
    mattertagsErrors,
    tags,
    enabled,
    floorId,
    state,
    position,
    inc,
    label,
    sid
  ) => {
    if (!enabled) {
      return originalMattertags
    }
    let res = originalMattertags
    //Estado
    //Libres
    if (state === 'st1') {
      res = res.filter((mt) => {
        return tags.findIndex((t) => t.originalMattertagSid === mt.sid) < 0
      })
    }
    //Ocupadas
    if (state === 'st2') {
      res = res.filter((mt) => {
        return tags.findIndex((t) => t.originalMattertagSid === mt.sid) >= 0
      })
    } //Error
    if (state === 'st3') {
      res = res.filter((mt) => {
        return mattertagsErrors.findIndex((mte) => mte.sid === mt.sid) >= 0
      })
    }
    //Planta
    if (floorId) {
      res = res.filter((mt) => mt.floorInfo.id === floorId)
    }
    //Etiqueta
    if (label) {
      res = res.filter((mt) => mt.label.includes(label))
    }
    //Sid
    if (sid) {
      res = res.filter((mt) => mt.sid.includes(sid))
    }
    //Posicion
    const xMin = position.x - inc.x
    const xMax = position.x + inc.x
    const yMin = position.y - inc.y
    const yMax = position.y + inc.y
    const zMin = position.z - inc.z
    const zMax = position.z + inc.z

    res = res.filter(
      (mt) => mt.anchorPosition.x >= xMin && mt.anchorPosition.x <= xMax
    )
    res = res.filter(
      (mt) => mt.anchorPosition.y >= yMin && mt.anchorPosition.y <= yMax
    )
    res = res.filter(
      (mt) => mt.anchorPosition.z >= zMin && mt.anchorPosition.z <= zMax
    )
    return res
  }
)
