import { makeStyles } from '@material-ui/core/styles'

import GLList from './GLList'
import GLTopBar from './GLTopBar'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden'
  }
}))

const GroupsList = ({ small }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <GLTopBar />
      <GLList />
    </div>
  )
}
export default GroupsList
