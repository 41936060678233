import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import Popover from '@material-ui/core/Popover'
import { CompactPicker } from 'react-color'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline ': {
      borderColor: theme.palette.primary.warning
    }
  },
  notchedOutline: {}
}))

const ColorPicker = ({ color, warning, onChange }) => {
  // const [color, setColor] = useState(initialColor ? initialColor : '#ff0000')
  const classes = useStyles({ color })
  // const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleChangeComplete = (color) => {
    if (onChange) {
      onChange(color.hex)
    }
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      <TextField
        label="Color"
        value={color}
        inputProps={{
          style: {
            color: 'transparent',
            backgroundColor: color,
            backgroundClip: 'content-box'
          }
        }}
        InputProps={{
          classes: {
            root: warning && classes.root,
            notchedOutline: warning && classes.notchedOutline
          }
        }}
        InputLabelProps={{
          classes: {
            root: warning && classes.rootLabel
          }
        }}
        onClick={handleClick}
        className={classes.textField}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <CompactPicker color={color} onChangeComplete={handleChangeComplete} />
      </Popover>
    </>
  )
}
export default ColorPicker
