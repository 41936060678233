import { useSelector, useDispatch } from 'react-redux'

import DraggableList from '../../Common/List/DraggableList'
import MLItem from './MLItem'

import { getFilteredMattertags } from '../../../modules/reducerMattertagsFilter'
import { swapMattertags } from '../../../modules/reducerMattertags'

const MLList = ({ editorMode }) => {
  const filteredMattertags = useSelector(getFilteredMattertags)
  const dispatch = useDispatch()

  const onDragEnd = (fromIdx, toIdx) => {
    dispatch(
      swapMattertags(
        filteredMattertags[fromIdx].sid,
        filteredMattertags[toIdx].sid
      )
    )
  }
  return (
    <DraggableList onSwap={onDragEnd}>
      {filteredMattertags.map((mt, index) => (
        <MLItem
          key={mt.sid}
          sid={mt.sid}
          index={index}
          editorMode={editorMode}
        />
      ))}
    </DraggableList>
  )
}
export default MLList
