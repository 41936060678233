import { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import {
  sdkSelector,
  cameraPoseSelector,
  boundsSelector
} from '../../modules/reducerMatterport'
import {} from '../../modules/reducerTagsActions'

import { getTagDiscData } from '../../modules/reducerTagsSelectors'

const useStyles = makeStyles((theme) => ({
  box: {
    position: 'absolute',
    display: ({ position }) => (position ? '' : 'none'),
    left: ({ position, offset }) => (position ? position.x + offset.x : ''),
    top: ({ position, offset }) => (position ? position.y + offset.y : ''),
    transform: 'translate(-50%,-50%)',
    width: 200,
    height: 50,
    backgroundColor: 'green',
    zIndex: 10,
    cursor: 'default'
  },
  icon: {
    position: 'absolute',
    display: ({ position }) => (position ? '' : 'none'),
    left: ({ position }) => (position ? position.x : ''),
    top: ({ position }) => (position ? position.y : ''),
    transform: 'translate(-50%,-50%)',
    width: 64,
    height: 64,
    zIndex: 10,
    cursor: 'default'
  }
}))

const TagPermanentBillboard = ({ id }) => {
  const gTagDiscData = useMemo(getTagDiscData, [])
  const tagDiscData = useSelector((state) => gTagDiscData(state, id))
  const sdk = useSelector(sdkSelector)
  const cameraPose = useSelector(cameraPoseSelector)
  const matterportBounds = useSelector(boundsSelector)
  const [disc2DPosition, setDisc2DPosition] = useState(null)
  const classes = useStyles({
    position: disc2DPosition,
    offset: tagDiscData ? tagDiscData.tag.permanentBillboard.offset : null
  })

  useEffect(() => {
    if (tagDiscData) {
      sdk.Renderer.getScreenPosition(tagDiscData.disc3DPosition).then(
        (sp) => setDisc2DPosition(sp)
        // setDisc2DPosition({
        //   x: sp.x + tagDiscData.tag.permanentBillboard.offset.x,
        //   y: sp.y + tagDiscData.tag.permanentBillboard.offset.y
        // })
      )
      // setDiscPosition2D(
      //   sdk.Conversion.worldToScreen(discPosition3D, cameraPose, {
      //     w: matterportBounds.width,
      //     h: matterportBounds.height
      //   })
      // )
    }
  }, [tagDiscData, sdk, cameraPose, matterportBounds])

  return (
    <>
      {tagDiscData &&
      tagDiscData.tag.permanentBillboard.visible &&
      disc2DPosition ? (
        <div className={classes.box}>
          {tagDiscData.tag.permanentBillboard.text}
        </div>
      ) : null}
    </>
  )
}
export default TagPermanentBillboard
//src={'./icons/animated-frog-image-0357.gif'}
//src={'./icons/comedor.png'}
/*
{tagDiscData && disc2DPosition ? (
  <img
    className={classes.icon}
    src={'./icons/comedor.png'}
    alt={'icono'}
  />
) : null}
*/
