import { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'

import EditableListEmptyItem from '../../Common/List/EditableListEmptyItem'
import {
  getCurrentGroup,
  restoreGroup,
  getCurrentGroupFreePoints,
  insertPoint
} from '../../../modules/reducerGroups'

import {
  LATERAL_CONTENT,
  setCurrentLateralContent
} from '../../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {},
  menu: {
    minWidth: 100,
    maxWidth: 300,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  textRoot: {},
  text: {
    color: 'currentColor', //theme.palette.primary.contrastText,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'none'
  }
}))

const GEAddPoint = ({ small }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const currentGroup = useSelector(getCurrentGroup)
  const freePoints = useSelector(getCurrentGroupFreePoints)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleAdd = useCallback(
    (id) => {
      handleClose()
      dispatch(insertPoint(currentGroup.id, id, currentGroup.points.length))
    },
    [dispatch, currentGroup]
  )

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return freePoints.length > 0 ? (
    <>
      <EditableListEmptyItem text={'Añadir punto'} onAdd={handleClick} />
      <Menu
        classes={{ paper: classes.menu }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {freePoints.map((point) => (
          <MenuItem
            className={classes.menuItem}
            key={point.id}
            onClick={() => handleAdd(point.id)}
          >
            <ListItemText
              primary={point.name}
              secondary={point.description}
              classes={{
                root: classes.textRoot,
                primary: classes.text,
                secondary: classes.text
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null
}
export default GEAddPoint
