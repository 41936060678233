import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import MattertagDetails from './MattertagDetails'
import MDTopBar from './MDTopBar'
import { currentOriginalMattertagSelector } from '../../../modules/reducerMattertags'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',

    boxSizing: 'border-box',
    maxHeight: '100%',
    minHeight: 0,
    width: '100%'
  }
}))
const CurrentMattertagDetails = () => {
  const classes = useStyles()
  const currentOriginalMattertag = useSelector(currentOriginalMattertagSelector)

  return (
    <div className={classes.root}>
      <MDTopBar />
      <MattertagDetails sid={currentOriginalMattertag} />
    </div>
  )
}
export default CurrentMattertagDetails
