import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import ColorPicker from '../../../Common/Input/ColorPicker'
import { rgbToHex, hexToRgb } from '../../../../utils/color'
import { updateTag } from '../../../../modules/reducerTagsActions'
import { TAG_PROPERTIES } from '../../../../modules/tag'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const TEGColor = ({ id, value, warning }) => {
  // const tag = useSelector(getCurrentTag)
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (hexColor) => {
      dispatch(updateTag(id, TAG_PROPERTIES.COLOR, hexToRgb(hexColor)))
    },
    [dispatch, id]
  )

  return (
    <ColorPicker
      color={rgbToHex(value.r, value.g, value.b)}
      warning={warning}
      onChange={updateTagValue}
    />
  )
}
export default TEGColor
