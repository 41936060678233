import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import LBButton from './LBButton'
// import {} from '../../modules/reducerMatterport'
import { getMenuOption, MENU_OPTIONS } from '../../modules/reducerMain'
import { getOutput } from '../../modules/reducerOpenSave'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const LBSaveFileButton = () => {
  // const classes = useStyles()
  const output = useSelector(getOutput)
  const gMenuOption = useMemo(getMenuOption, [])
  const saveOption = useSelector((state) =>
    gMenuOption(state, MENU_OPTIONS.SAVE)
  )

  const handleClick = useCallback(() => {
    const a = document.createElement('a')
    const file = new Blob([output.text], { type: 'application/json' })
    a.href = URL.createObjectURL(file)
    a.download = output.fileName
    a.click()
  }, [output])

  return (
    <LBButton
      name={saveOption.name}
      optionId={saveOption.id}
      disabled={saveOption.disabled}
      onClick={handleClick}
    />
  )
}
export default LBSaveFileButton
