import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import ToggleButton from '../../Common/Input/ToggleButton'
import { getOriginalMattertag } from '../../../modules/reducerMattertags'
import { animatingWidthSelector } from '../../../modules/reducerLateral'

import { rgbToHex } from '../../../utils/color'
// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    // flex: '1 0 auto',
    boxSizing: 'border-box',
    paddingTop: 0,
    // paddingLeft: 8,
    margin: 0,
    width: '100%',
    overflow: 'auto'
  }
}))

const MattertagDetails = ({ sid }) => {
  const classes = useStyles()
  const gOriginalMattertag = useMemo(getOriginalMattertag, [])
  const mattertag = useSelector((state) => gOriginalMattertag(state, sid))
  const animatingWidth = useSelector(animatingWidthSelector)
  const small = animatingWidth < 500
  const color =
    mattertag &&
    rgbToHex(mattertag.color.r, mattertag.color.g, mattertag.color.b)
  // const dispatch = useDispatch()

  return mattertag ? (
    true ? (
      <Grid
        container
        className={classes.root}
        spacing={2}
        alignContent="flex-start"
      >
        <Grid item xs={12}>
          <TextField
            label="Label"
            value={mattertag.label ? mattertag.label : 'Sin label'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descripción"
            multiline
            rowsMax={4}
            value={
              mattertag.description ? mattertag.description : 'Sin descripción'
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Media"
            value={mattertag.media.src ? mattertag.media.src : 'Sin media src'}
          />
        </Grid>
        <Grid item xs={small ? 12 : 6}>
          <TextField
            label="Media type"
            value={
              mattertag.media.type ? mattertag.media.type : 'Sin media type'
            }
          />
        </Grid>
        {mattertag.media.src && (
          <Grid item xs={small ? 12 : 6}>
            <iframe
              title="Contenido"
              frameborder="0"
              src={mattertag.media.src}
            />
          </Grid>
        )}

        <Grid item xs={small ? 12 : 6}>
          <TextField label="Floor Id" value={mattertag.floorInfo.id} />
        </Grid>

        <Grid item xs={small ? 12 : 4}>
          <TextField label="Posición X" value={mattertag.anchorPosition.x} />
        </Grid>

        <Grid item xs={small ? 12 : 4}>
          <TextField label="Posición Y" value={mattertag.anchorPosition.y} />
        </Grid>
        <Grid item xs={small ? 12 : 4}>
          <TextField label="Posición Z" value={mattertag.anchorPosition.z} />
        </Grid>

        <Grid item xs={small ? 12 : 4}>
          <TextField label="Stem X" value={mattertag.stemVector.x} />
        </Grid>

        <Grid item xs={small ? 12 : 4}>
          <TextField label="Stem Y" value={mattertag.stemVector.y} />
        </Grid>

        <Grid item xs={small ? 12 : 4}>
          <TextField label="Stem Z" value={mattertag.stemVector.z} />
        </Grid>

        <Grid item xs={small ? 12 : 4}>
          <ToggleButton
            label={'Stem Visible'}
            checked={mattertag.stemVisible}
            disabled={false}
          />
        </Grid>

        <Grid item xs={small ? 12 : 4}>
          <ToggleButton
            label={'Enabled'}
            checked={mattertag.enabled}
            disabled={false}
          />
        </Grid>

        <Grid item xs={small ? 12 : 4}>
          <TextField
            label="Color"
            value={color}
            inputProps={{
              style: {
                color: 'transparent',
                backgroundColor: color,
                backgroundClip: 'content-box'
              }
            }}
            className={classes.textField}
          />
        </Grid>
      </Grid>
    ) : (
      <div style={{ height: 300 }}></div>
    )
  ) : null
}
export default MattertagDetails
