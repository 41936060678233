import { useCallback, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import { Draggable } from 'react-beautiful-dnd'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingRight: 16,
    color: ({ showIcons }) =>
      showIcons
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText, // theme.palette.primary.contrastText,
    '&:hover': { color: theme.palette.secondary.main },
    height: 56
  },
  textRoot: {},
  text: {
    color: 'currentColor', //theme.palette.primary.contrastText,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'none'
  },
  buttonsWrapper: { display: 'flex', flexFlow: 'row', height: '100%' }
}))

const getItemStyle = (theme, isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    backgroundColor: theme.palette.primary.light,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 15px 30px 0px'
  })
})

const DraggableListItem = ({
  id,
  index,
  currentItemId,
  primaryText,
  secondaryText,
  checkbox,
  checked,
  icon,
  hoverButtons,
  buttons,
  onSelect,
  onCheck
}) => {
  const [hover, setHover] = useState(false)
  const selected = id === currentItemId
  const showIcons = selected || hover
  const theme = useTheme()
  const classes = useStyles({
    showIcons
  })

  const handleMouseOver = useCallback(() => {
    setHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  const handleSelect = useCallback(
    (e) => {
      if (onSelect) {
        onSelect(id)
      }
    },
    [onSelect, id]
  )

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          button
          className={classes.listItem}
          selected={selected}
          autoFocus={selected}
          onClick={handleSelect}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            theme,
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {checkbox && (
            <Checkbox
              size="small"
              edge="start"
              checked={checked}
              disableRipple
              onClick={(e) => e.stopPropagation()}
              onChange={onCheck}
            />
          )}
          {icon}
          <ListItemText
            primary={primaryText}
            secondary={secondaryText}
            classes={{
              root: classes.textRoot,
              primary: classes.text,
              secondary: classes.text
            }}
          />
          <div
            className={classes.buttonsWrapper}
            onClick={(e) => e.stopPropagation()}
          >
            {showIcons && hoverButtons}
            {buttons}
          </div>
        </ListItem>
      )}
    </Draggable>
  )
}
export default DraggableListItem
