import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { NotVisibleIcon } from '../../Icons/Icons'
import BarButton from '../../Toolbar/BarButton'
import {
  setAllOriginalMattertagsVisibles,
  getNOriginalMattertagsShown
} from '../../../modules/reducerMattertags'

const MLHideAllMattertagsButton = () => {
  const dispatch = useDispatch()
  const nOriginalMattertagsShown = useSelector(getNOriginalMattertagsShown)

  const handleHideAll = useCallback(() => {
    dispatch(setAllOriginalMattertagsVisibles(false))
  }, [dispatch])

  return (
    <BarButton
      tooltipTitle={'Ocultar todas'}
      tooltipEnabled
      disabled={nOriginalMattertagsShown === 0}
      onClick={handleHideAll}
    >
      <NotVisibleIcon />
    </BarButton>
  )
}
export default MLHideAllMattertagsButton
