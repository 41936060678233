import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { TagIcon } from '../../../Icons/Icons'
import { matterportIconsSelector } from '../../../../modules/reducerMatterportIcons'
import { updateTag } from '../../../../modules/reducerTagsActions'
import { TAG_PROPERTIES } from '../../../../modules/tag'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', alignItems: 'center' },
  image: { width: 16, height: 16, paddingRight: 4 }
}))

const TEGIcon = ({ id, value }) => {
  // const tag = useSelector(getCurrentTag)
  const classes = useStyles()
  const icons = useSelector(matterportIconsSelector)
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (e) => {
      dispatch(updateTag(id, TAG_PROPERTIES.ICON, e.target.value))
    },
    [dispatch, id]
  )
  const base = window.location.href + 'icons/'
  return (
    <TextField
      select
      SelectProps={{ classes: { root: classes.root } }}
      label="Icon"
      value={value}
      onChange={updateTagValue}
    >
      <MenuItem value="">
        <TagIcon className={classes.image} />
        Default
      </MenuItem>
      {icons.map((icon) => {
        return (
          <MenuItem key={icon.id} value={icon.id}>
            {icon.src ? (
              <img src={base + icon.src} alt="Icon" className={classes.image} />
            ) : (
              <TagIcon className={classes.image} />
            )}
            {icon.name}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
export default TEGIcon
