import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import EditorTextField from '../../../Common/Input/EditorTextField'
import { updateTag } from '../../../../modules/reducerTagsActions'
import { TAG_PROPERTIES } from '../../../../modules/tag'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const TEGName = ({ id, name }) => {
  // const tag = useSelector(getCurrentTag)
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (value) => {
      dispatch(updateTag(id, TAG_PROPERTIES.NAME, value))
    },
    [dispatch, id]
  )

  return (
    <EditorTextField label="Nombre" value={name} onChange={updateTagValue} />
  )
}
export default TEGName
