import { useCallback, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import LBButton from './LBButton'
import LBOpenFileAlertDialog from './LBOpenFileAlertDialog'
// import {} from '../../modules/reducerMatterport'
import { getMenuOption, MENU_OPTIONS } from '../../modules/reducerMain'
import { setData, getDataError } from '../../modules/reducerOpenSave'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    display: 'none'
  },
  error: { color: theme.palette.primary.contrastText, padding: '0 12px' }
}))

const LBOpenFileButton = () => {
  const classes = useStyles()
  const gMenuOption = useMemo(getMenuOption, [])
  const saveOption = useSelector((state) =>
    gMenuOption(state, MENU_OPTIONS.OPEN)
  )

  const [error, setError] = useState('')
  const [localData, setLocalData] = useState(null)
  const gDataError = useSelector(getDataError)
  const dispatch = useDispatch()

  const handleFileChosen = useCallback(
    (file) => {
      const fileReader = new FileReader()
      fileReader.onloadend = (e) => {
        const content = fileReader.result
        const data = JSON.parse(content)
        const err = gDataError(data)
        setError(err)
        setLocalData(data)
        if (!err) {
          dispatch(setData(data))
        }
      }
      fileReader.readAsText(file)
    },
    [gDataError, dispatch]
  )

  const handleDialog = useCallback(
    (ok) => {
      if (ok) {
        dispatch(setData(localData))
      }
      setError('')
    },
    [dispatch, localData]
  )

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        accept=".json"
        type="file"
        id="button-file"
        value=""
        onChange={(e) => handleFileChosen(e.target.files[0])}
      />
      <label htmlFor="button-file">
        <LBButton
          name={saveOption.name}
          optionId={saveOption.id}
          disabled={saveOption.disabled}
        />
      </label>
      <LBOpenFileAlertDialog
        open={error !== ''}
        error={error}
        onClose={handleDialog}
      />
    </div>
  )
}
export default LBOpenFileButton
