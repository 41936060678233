import { createSelector } from 'reselect'
import { modelSidSelector } from './reducerMatterport'
import { tagsSelector } from './reducerTagsSelectors'
import { setTags } from './reducerTagsActions'
import { pointsSelector, setPoints } from './reducerPoints'
import { groupsSelector, setGroups } from './reducerGroups'
const stringify = require('json-stringify-pretty-compact')
// import { findById } from '../utils/utils'

export const SAVE_OPEN_SET_DATA = 'SAVE_OPEN_SET_DATA'

const initialState = {
  fileName: ''
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
export default changeState

export const setData = (data) => (dispatch, getState) => {
  // const state = getState()
  console.log(data)
  dispatch(setTags(data.tags))
  dispatch(setPoints(data.points))
  dispatch(setGroups(data.groups))
}

export const fileNameSelector = (state) => state.openSave.fileName

export const getOutput = createSelector(
  [
    fileNameSelector,
    modelSidSelector,
    tagsSelector,
    pointsSelector,
    groupsSelector
  ],
  (fileName, modelSid, tags, points, groups) => {
    const cleanTags = tags.map((tag) => {
      const cleanTag = { ...tag }
      delete cleanTag.sid
      delete cleanTag.sensorReading
      delete cleanTag.sensorSource
      return cleanTag
    })
    const data = { modelSid, tags: cleanTags, points, groups }
    return {
      text: stringify(data, { maxLength: 100, indent: 1 }),
      fileName: fileName ? fileName : modelSid + '.json'
    }
  }
)

export const getDataError = createSelector(
  [modelSidSelector],
  (modelSid) => (data) => {
    if (!data) {
      return 'No hay datos'
    }
    if (data.modelSid !== modelSid) {
      return 'Archivo elegido no corresponde al modelo Matterport cargado'
    }
    return ''
  }
)
