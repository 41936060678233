import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DraggableListItem from '../../Common/List/DraggableListItem'
import ListButton from '../../Common/List/ListButton'
import { AddDuplicateIcon, EditIcon, RemoveIcon } from '../../Icons/Icons'

import { getPoint, goToPointPromise } from '../../../modules/reducerPoints'
import { removePoint } from '../../../modules/reducerGroups'
import { sdkSelector } from '../../../modules/reducerMatterport'
const GEPointItem = ({ groupId, pointId, index, currentItemId, onSelect }) => {
  const gPoint = useMemo(getPoint, [])
  const point = useSelector((state) => gPoint(state, pointId))
  const sdk = useSelector(sdkSelector)
  const dispatch = useDispatch()

  const handleRemove = useCallback(() => {
    dispatch(removePoint(groupId, pointId))
  }, [dispatch, groupId, pointId])

  const handleSelect = useCallback(() => {
    if (onSelect) {
      onSelect(pointId)
    }
    goToPointPromise(sdk, point).then(() => {})
  }, [onSelect, pointId, point, sdk])

  return point ? (
    <DraggableListItem
      key={point.id}
      id={point.id}
      index={index}
      primaryText={point.name}
      secondaryText={point.description}
      icon={null}
      currentItemId={currentItemId}
      onSelect={handleSelect}
      hoverButtons={
        <>
          <ListButton tooltipTitle="Borrar" onClick={handleRemove}>
            <RemoveIcon />
          </ListButton>
        </>
      }
    ></DraggableListItem>
  ) : null
}
export default GEPointItem
/*
<ListButton tooltipTitle="Añadir/Duplicar" onClick={handleAdd}>
  <AddDuplicateIcon />
</ListButton>
*/
