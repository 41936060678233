import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import OKCancelButtons from '../../Common/Buttons/OKCancelButtons'
import PEForm from './PEForm'
import { getCurrentPoint, restorePoint } from '../../../modules/reducerPoints'

import {
  LATERAL_CONTENT,
  setCurrentLateralContent
} from '../../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    maxHeight: '100%',
    minHeight: 0,
    flexGrow: 1,
    boxSizing: 'border-box',

    width: '100%'
  },
  contentWrapper: { flexGrow: 1, overflow: 'auto' }
}))

const PointEditor = ({ small }) => {
  const point = useSelector(getCurrentPoint)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleOK = useCallback(() => {
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.POINTS_LIST))
  }, [dispatch])

  const handleCancel = useCallback(() => {
    dispatch(restorePoint())
    dispatch(setCurrentLateralContent(LATERAL_CONTENT.POINTS_LIST))
  }, [dispatch])

  return point ? (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <PEForm />
      </div>
      <OKCancelButtons onOk={handleOK} onCancel={handleCancel} />
    </div>
  ) : null
}
export default PointEditor
