import { useSelector } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { originalMattertagsSelector } from '../../../modules/reducerMattertags'
import { modelLoadedSelector } from '../../../modules/reducerMatterport'
import { originalMattertagSelector } from '../../../modules/reducerTagsFilter'

// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const OriginalMattertagSelect = ({ onChange }) => {
  // const classes = useStyles()
  const originalMattertags = useSelector(originalMattertagsSelector)
  const modelLoaded = useSelector(modelLoadedSelector)
  const originalMattertag = useSelector(originalMattertagSelector)

  return (
    <TextField
      label="Original Mattertag"
      select
      value={originalMattertag}
      onChange={onChange}
      disabled={!modelLoaded}
    >
      <MenuItem value={''}>All</MenuItem>
      {originalMattertags.map((mt) => (
        <MenuItem key={mt.sid} value={mt.sid}>
          {mt.sid}
        </MenuItem>
      ))}
    </TextField>
  )
}
export default OriginalMattertagSelect
