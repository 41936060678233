import { makeStyles } from '@material-ui/core/styles'

import Bar from '../../Toolbar/Bar'
import SeparatorButton from '../../Toolbar/SeparatorButton'
import ResetPointsFilterButton from '../../Toolbar/ResetPointsFilterButton'
import EnablePointsFilterButton from '../../Toolbar/EnablePointsFilterButton'
import RemoveAllPointsButton from '../../Toolbar/RemoveAllPointsButton'

const useStyles = makeStyles((theme) => ({
  topBar: {}
}))

const PLTopBar = () => {
  const classes = useStyles()
  return (
    <Bar className={classes.topBar}>
      <RemoveAllPointsButton />
      <SeparatorButton />
      <ResetPointsFilterButton />
      <EnablePointsFilterButton />
    </Bar>
  )
}
export default PLTopBar
