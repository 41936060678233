import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    minHeight: 32,
    paddingLeft: 8
  },
  iconButton: {
    padding: 0,
    color: 'inherit',
    '&:hover': { color: theme.palette.secondary.main }
  }
}))

const LateralToolbar = ({ title, closeButton, onClose }) => {
  const classes = useStyles()

  return (
    <Toolbar className={classes.toolbar} disableGutters variant="dense">
      <div className={classes.title}>{title}</div>
      <div style={{ flexGrow: 1 }} />
      {closeButton && (
        <IconButton className={classes.iconButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </Toolbar>
  )
}
export default LateralToolbar
