import { makeStyles } from '@material-ui/core/styles'

import PointsFilter from '../Filter/PointsFilter'
import PLList from './PLList'
import PLTopBar from './PLTopBar'

// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden'
  },
  list: {
    overflow: 'auto',
    width: '100%',
    flex: '1 1 auto'
  }
}))

const PointsList = ({ small }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <PointsFilter />
      <PLTopBar />
      <PLList />
    </div>
  )
}
export default PointsList
/*

*/
