// import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { getFreeMattertags } from '../../../modules/reducerTagsSelectors'
import { ErrorIcon } from '../../Icons/Icons'
// import { showEditorSelector } from '../../../modules/SegmentGenerator/reducerSGVisualization'
const useStyles = makeStyles((theme) => ({
  bottomBar: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '0 16px',
    width: '100%',
    height: '32px',
    minHeight: '32px',
    backgroundColor: theme.palette.primary.main,
    color: 'red' // theme.palette.primary.contrastText
  }
}))

const TLBottomBar = () => {
  const classes = useStyles()
  const freeMattertags = useSelector(getFreeMattertags)
  // const tagsError = { level: 0, msg: 'Hay mattertags sin asignar' }
  // const dispatch = useDispatch()
  return freeMattertags.length > 0 ? (
    <div className={classes.bottomBar}>
      <ErrorIcon />
      Hay {freeMattertags.length} mattertags sin asignar
    </div>
  ) : null
}
export default TLBottomBar
