import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RemoveAllIcon } from '../Icons/Icons'
import BarButton from './BarButton'
import { getFilteredTags } from '../../modules/reducerTagsFilter'
import { removeAllTags } from '../../modules/reducerTagsActions'

const RemoveAllTagsButton = () => {
  const filteredTags = useSelector(getFilteredTags)
  const dispatch = useDispatch()

  const handleRemoveAll = useCallback(() => {
    dispatch(removeAllTags())
  }, [dispatch])

  return (
    <BarButton
      tooltipTitle={'Borrar todas'}
      tooltipEnabled
      disabled={filteredTags.length === 0}
      onClick={handleRemoveAll}
    >
      <RemoveAllIcon />
    </BarButton>
  )
}
export default RemoveAllTagsButton
