import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'

import { TagIcon } from '../../Icons/Icons'
import { getTagErrors } from '../../../modules/reducerTagsSelectors'
import { getIcon } from '../../../modules/reducerMatterportIcons'

import { rgbToHex } from '../../../utils/color'
const useStyles = makeStyles((theme) => ({
  itemIcon: {
    minWidth: 36,
    paddingRight: 4
  },
  icon: {
    width: 36,
    height: 36,
    color: ({ color }) => rgbToHex(color.r, color.g, color.b)
  },
  imgIcon: {
    width: 36,
    height: 'auto'
  }
}))

const TLItemIcon = ({ id, color, iconId }) => {
  const gTagErrors = useMemo(getTagErrors, [])
  const errors = useSelector((state) => gTagErrors(state, id))
  const gIcon = useMemo(getIcon, [])
  const icon = useSelector((state) => gIcon(state, iconId))

  const classes = useStyles({ color })

  const getTooltipMsg = () => {
    return errors.errors.length + errors.warnings.length > 0 ? (
      <div>
        {errors.errors.map((msg, idx) => (
          <div key={idx}>{msg}</div>
        ))}
        {errors.warnings.map((msg, idx) => (
          <div key={idx}>{msg}</div>
        ))}
      </div>
    ) : (
      ''
    )
  }

  const base = window.location.href + 'icons/'
  return (
    <Tooltip title={getTooltipMsg()}>
      <ListItemIcon className={classes.itemIcon}>
        <Badge
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          badgeContent={errors.errors.length}
          color="error"
        >
          <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            badgeContent={errors.warnings.length}
            color="error"
          >
            {icon ? (
              <img
                className={classes.imgIcon}
                src={base + icon.src}
                alt="Icon"
              />
            ) : (
              <TagIcon className={classes.icon} />
            )}
          </Badge>
        </Badge>
      </ListItemIcon>
    </Tooltip>
  )
}
export default TLItemIcon
