export const TAG_PROPERTIES = {
  SID: 'SID',
  ORIGINAL_MATTERTAG_SID: 'ORIGINAL_MATTERTAG_SID',
  NAME: 'NAME',
  COLOR: 'COLOR',
  LABEL: 'LABEL',
  DESCRIPTION: 'DESCRIPTION',
  POSITION: 'POSITION',
  POS_X: 'POS_X',
  POS_Y: 'POS_Y',
  POS_Z: 'POS_Z',
  STEM_VISIBLE: 'STEM_VISIBLE',
  STEM_VECTOR: 'STEM_VECTOR',
  STEM_X: 'STEM_X',
  STEM_Y: 'STEM_Y',
  STEM_Z: 'STEM_Z',
  ICON: 'ICON',
  OPACITY: 'OPACITY',
  PB_VISIBLE: 'PB_VISIBLE',
  PB_TEXT: 'PB_TEXT',
  PB_OFFSET_X: 'PB_OFFSET_X',
  PB_OFFSET_Y: 'PB_OFFSET_Y'
}

export const Tag = () => {
  return {
    id: '',
    sid: '',
    originalMattertagSid: '',
    name: 'Nombre',
    description: 'Descripción de la etiqueta',
    label: '',
    icon: '',
    position: { x: 0, y: 0, z: 0 },
    color: { r: 0, g: 0.6, b: 0.8 },
    opacity: 1,
    media: { src: '', type: 'mattertag.media.none' },
    stemVisible: false,
    stemVector: { x: 0, y: 0.5, z: 0 },
    permanentBillboard: { visible: false, offset: { x: 0, y: 0 }, text: '' }
  }
}

export const mattertagDataToTag = (mattertagData) => {
  if (!mattertagData) {
    return null
  }
  return {
    id: '',
    sid: '',
    originalMattertagSid: mattertagData.sid,
    name: '',
    position: { ...mattertagData.anchorPosition },
    color: { ...mattertagData.color },
    opacity: 1,
    description: mattertagData.description,
    label: mattertagData.label,
    media: { ...mattertagData.media },
    stemVector: { ...mattertagData.stemVector },
    stemVisible: mattertagData.stemVisible,
    icon: '',
    permanentBillboard: { visible: false, offset: { x: 0, y: 0 }, text: '' }
  }
}

export const updateTagFromMattertag = (tag, mattertagData) => {
  if (!tag || !mattertagData) {
    return tag
  }
  return {
    ...tag,
    originalMattertagSid: mattertagData.sid,
    position: { ...mattertagData.anchorPosition },
    color: { ...mattertagData.color },
    opacity: 1,
    description: mattertagData.description,
    label: mattertagData.label,
    media: { ...mattertagData.media },
    stemVector: { ...mattertagData.stemVector },
    stemVisible: mattertagData.stemVisible,
    icon: ''
  }
}

export const tagToMattertagData = (tag) => {
  if (!tag) {
    return null
  }
  return {
    anchorPosition: { ...tag.position },
    color: { ...tag.color },
    description: tag.description,
    label: tag.label,
    stemVector: { ...tag.stemVector },
    stemVisible: tag.stemVisible
  }
}
