import { createSelector } from 'reselect'
import { findById, getNotUsedNameObjects, swap } from '../utils/utils'
import { sdkSelector, modelLoadedSelector } from './reducerMatterport'

export const POINTS_SET_POINTS = 'POINTS_SET_POINTS'
export const POINTS_INSERT = 'POINTS_INSERT'
export const POINTS_REMOVE = 'POINTS_REMOVE'
export const POINTS_REMOVE_ALL = 'POINTS_REMOVE_ALL'
export const POINTS_UPDATE_PROPERTY = 'POINTS_UPDATE_PROPERTY'
export const POINTS_SET_CURRENT_POINT = 'POINTS_SET_CURRENT_POINT'
export const POINTS_REPLACE = 'POINTS_REPLACE'
export const POINTS_SWAP = 'POINTS_SWAP'

export const POINT_PROPERTIES = {
  NAME: 'name',
  DESCRIPTION: 'description',
  POSE: 'pose'
}

const Point = () => {
  return { id: 'p0', name: 'Point', description: '', pose: null }
}

const initialState = {
  points: [],
  currentPoint: '',
  backup: null
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case POINTS_SET_CURRENT_POINT:
      let point = findById(state.points, action.id)
      if (point) {
        point = JSON.parse(JSON.stringify(point))
        return { ...state, currentPoint: action.id, backup: point }
      }
      return state
    case POINTS_SET_POINTS:
      return { ...state, points: action.points, currentPoint: '' }
    case POINTS_INSERT:
      const newPoints = [...state.points]
      newPoints.splice(action.position, 0, action.point)
      return { ...state, points: newPoints }
    case POINTS_REMOVE:
      return {
        ...state,
        points: state.points.filter((point) => point.id !== action.id),
        currentPoint: state.currentPoint === action.id ? '' : state.currentPoint
      }
    case POINTS_REMOVE_ALL:
      return { ...state, currentPoint: '', points: [] }
    case POINTS_UPDATE_PROPERTY:
      return stateUpdateProperty(
        state,
        action.id,
        action.property,
        action.value
      )
    case POINTS_REPLACE: {
      const points = state.points.map((point) => {
        if (point.id === action.pointId) {
          return action.newPoint
        }
        return point
      })
      return { ...state, points }
    }
    case POINTS_SWAP:
      const fromIdx = state.points.findIndex(
        (point) => point.id === action.fromId
      )
      const toIdx = state.points.findIndex((point) => point.id === action.toId)
      return { ...state, points: swap([...state.points], fromIdx, toIdx) }
    default:
      return state
  }
}
export default changeState

const stateUpdateProperty = (state, id, property, value) => {
  const points = state.points.map((point) => {
    if (point.id === id) {
      switch (property) {
        case POINT_PROPERTIES.NAME:
          return { ...point, name: value }
        case POINT_PROPERTIES.DESCRIPTION:
          return { ...point, description: value }
        case POINT_PROPERTIES.POSE:
          return { ...point, pose: value }

        default:
      }
    }
    return point
  })
  return { ...state, points }
}

export const setPoints = (points) => ({
  type: POINTS_SET_POINTS,
  points
})

export const removePoint = (id) => ({
  type: POINTS_REMOVE,
  id
})

export const removeAllPoints = (id) => ({
  type: POINTS_REMOVE_ALL
})

export const insertPoint = (position, modelPointId) => (dispatch, getState) => {
  const state = getState()
  // const modelLoaded = modelLoadedSelector(state)
  // const sdk = sdkSelector(state)
  let point = findById(pointsSelector(state), modelPointId)
  if (point) {
    point = JSON.parse(JSON.stringify(point))
  } else {
    point = Point()
  }
  point.id = getNotUsedNameObjects(pointsSelector(state), 'id', 'p')
  point.name = getNotUsedNameObjects(pointsSelector(state), 'name', point.name)
  dispatch({
    type: POINTS_INSERT,
    position,
    point
  })
  // if (!modelLoaded || !sdk) {
  //   dispatch({
  //     type: POINTS_INSERT,
  //     position,
  //     point
  //   })
  //   return
  // }
}

export const updatePointProperty = (id, property, value) => ({
  type: POINTS_UPDATE_PROPERTY,
  id,
  property,
  value
})

export const restorePoint = () => (dispatch, getState) => {
  const state = getState()
  const backup = backupSelector(state)
  if (!backup) {
    return
  }
  dispatch({
    type: POINTS_REPLACE,
    pointId: backup.id,
    newPoint: backup
  })
}

export const swapPoints = (fromId, toId) => ({
  type: POINTS_SWAP,
  fromId,
  toId
})

export const setCurrentPoint = (id) => (dispatch, getState) => {
  const state = getState()
  const sdk = sdkSelector(state)
  const points = pointsSelector(state)
  const point = findById(points, id)
  goToPointPromise(sdk, point)
    .then(() =>
      dispatch({
        type: POINTS_SET_CURRENT_POINT,
        id
      })
    )
    .catch((error) => alert(error))
}

export const goToPointPromise = (sdk, point) => {
  return new Promise((resolve, reject) => {
    if (!point || !sdk || !point.pose) {
      return resolve()
    }
    sdk.Sweep.moveTo(point.pose.sweep, {
      rotation: point.pose.rotation,
      transition: sdk.Sweep.Transition.INSTANT,
      transitionTime: 2000
    })
      .then((sweepId) => resolve())
      .catch((error) => reject(error))
  })
}

export const pointsSelector = (state) => state.points.points
export const currentPointSelector = (state) => state.points.currentPoint
export const backupSelector = (state) => state.points.backup

export const getPoint = () =>
  createSelector([pointsSelector, (_, id) => id], (points, id) => {
    return findById(points, id)
  })

export const getCurrentPoint = createSelector(
  [pointsSelector, currentPointSelector],
  (points, currentPoint) => {
    return findById(points, currentPoint)
  }
)
