import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import FloatValue from '../../../Common/Input/FloatValue'
import { updateTag } from '../../../../modules/reducerTagsActions'
import { TAG_PROPERTIES } from '../../../../modules/tag'
// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const TEGOpacity = ({ id, value }) => {
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (value) => {
      dispatch(updateTag(id, TAG_PROPERTIES.OPACITY, value))
    },
    [dispatch, id]
  )

  return (
    <FloatValue
      label="Opacidad"
      value={value}
      min={0}
      max={1}
      onChange={updateTagValue}
    />
  )
}
export default TEGOpacity
