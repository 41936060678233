import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({ iconButton: { color: 'inherit' } }))
const ListButton = ({ hide, tooltipTitle, disabled, children, onClick }) => {
  const classes = useStyles()
  return hide ? null : (
    <Tooltip title={tooltipTitle}>
      <IconButton
        className={classes.iconButton}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}
export default ListButton
