import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import FloorSelect from '../../Common/Input/FloorSelect'
import {
  setFloor,
  floorIdSelector
} from '../../../modules/reducerMattertagsFilter'

const MTFFloor = () => {
  const floorId = useSelector(floorIdSelector)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (e) => {
      dispatch(setFloor(e.target.value))
    },
    [dispatch]
  )

  return <FloorSelect floorId={floorId} onChange={handleChange} />
}
export default MTFFloor
