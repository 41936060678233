import { makeStyles } from '@material-ui/core/styles'

import Bar from '../../Toolbar/Bar'

import RemoveAllGroupsButton from '../../Toolbar/RemoveAllGroupsButton'

const useStyles = makeStyles((theme) => ({
  topBar: {}
}))

const PLTopBar = () => {
  const classes = useStyles()
  return (
    <Bar className={classes.topBar}>
      <RemoveAllGroupsButton />
    </Bar>
  )
}
export default PLTopBar
