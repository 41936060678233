import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import EditableListEmptyItem from '../../Common/List/EditableListEmptyItem'
import DraggableList from '../../Common/List/DraggableList'
import TLItem from './TLItem'

import { getFilteredTags } from '../../../modules/reducerTagsFilter'
import { tagsSelector } from '../../../modules/reducerTagsSelectors'
import { insertTag, swapTags } from '../../../modules/reducerTagsActions'

const TLList = () => {
  const tags = useSelector(tagsSelector)
  const fTags = useSelector(getFilteredTags)
  const dispatch = useDispatch()

  const onDragEnd = (fromIdx, toIdx) => {
    dispatch(swapTags(fTags[fromIdx].id, fTags[toIdx].id))
  }

  const handleAdd = useCallback(
    (e) => {
      dispatch(insertTag(0, ''))
    },
    [dispatch]
  )

  return tags.length === 0 ? (
    <EditableListEmptyItem text={'Añadir tag'} onAdd={handleAdd} />
  ) : (
    <DraggableList onSwap={onDragEnd}>
      {fTags.map((tag, index) => (
        <TLItem key={tag.id} id={tag.id} index={index} />
      ))}
    </DraggableList>
  )
}
export default TLList
