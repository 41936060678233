// import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import OptionIcon from './OptionIcon'
import { currentMenuOptionSelector } from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0,
    color: ({ selected }) =>
      selected
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  buttonLabel: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {}
}))

const LBButton = ({ optionId, name, disabled, onClick }) => {
  const currentMenuOption = useSelector(currentMenuOptionSelector)
  const selected = currentMenuOption === optionId
  const classes = useStyles({ selected })

  return (
    <Button
      classes={{ root: classes.button, label: classes.buttonLabel }}
      variant="text"
      component="span"
      disabled={disabled}
      onClick={onClick ? () => onClick(optionId) : null}
    >
      <OptionIcon optionId={optionId} />
      <Typography variant="h5" className={classes.title}>
        {name}
      </Typography>
    </Button>
  )
}
export default LBButton
