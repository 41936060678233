import {
  tagsSelector,
  backupSelector,
  currentTagSelector,
  getCurrentTag
} from './reducerTagsSelectors'
import {
  sdkSelector,
  modelLoadedSelector,
  cameraSensorSelector
} from './reducerMatterport'
import { originalMattertagsSelector } from './reducerMattertags'
import { getEditingTag } from './reducerMain'
import {
  navigateToMatterTagPromise,
  replaceMattertagPromise,
  addMattertagPromise
} from '../utils/matterportUtils'
import { findById, getNotUsedNameObjects } from '../utils/utils'

import {
  mattertagDataToTag,
  tagToMattertagData,
  TAG_PROPERTIES,
  Tag,
  updateTagFromMattertag
} from './tag'

export const TAGS_SET_TAGS = 'TAGS_SET_TAGS'
export const TAGS_SET_CURRENT_TAG = 'TAG_SET_CURRENT_TAG'
export const TAGS_SET_SENSOR_READING = 'TAGS_SET_SENSOR_READING'

export const TAGS_INSERT = 'TAGS_INSERT'
export const TAGS_REMOVE = 'TAGS_REMOVE'
export const TAGS_REMOVE_ALL = 'TAGS_REMOVE_ALL'
export const TAGS_REPLACE = 'TAGS_REPLACE'
export const TAGS_UPDATE_PROPERTY = 'TAGS_UPDATE_PROPERTY'
export const TAGS_SWAP_BY_ID = 'TAGS_SWAP_BY_ID'

export const swapTags = (fromId, toId) => ({
  type: TAGS_SWAP_BY_ID,
  fromId,
  toId
})

//Nueva lista de tags
export const setTags = (tags) => async (dispatch, getState) => {
  const state = getState()
  const modelLoaded = modelLoadedSelector(state)
  const sdk = sdkSelector(state)
  if (!modelLoaded || !sdk) {
    dispatch({
      type: TAGS_SET_TAGS,
      tags
    })
  } else {
    dispatch({
      type: TAGS_REMOVE_ALL
    })
    setTagsAux(dispatch, sdk, tags, cameraSensorSelector(state))
  }
}

export const setTagsAux = async (dispatch, sdk, tags, sensor) => {
  for (const tag of tags) {
    try {
      const newTag = await insertTagPromise(sdk, tag, sensor)
      dispatch({
        type: TAGS_INSERT,
        position: tags.length,
        tag: newTag
      })
    } catch (e) {
      alert(e)
    }
  }
}

//Inserta en Matterport las Tags que hay en el editor.
export const loadOfflineTags = () => (dispatch, getState) => {
  const state = getState()
  const modelLoaded = modelLoadedSelector(state)
  const sdk = sdkSelector(state)
  if (modelLoaded && sdk) {
    const tags = tagsSelector(state)
    loadOfflineTagsAux(dispatch, sdk, tags, cameraSensorSelector(state))
  }
}

export const loadOfflineTagsAux = async (dispatch, sdk, tags, sensor) => {
  for (const tag of tags) {
    try {
      const newTag = await insertTagPromise(sdk, tag, sensor)
      dispatch({
        type: TAGS_REPLACE,
        tagId: tag.id,
        newTag
      })
    } catch (e) {
      alert(e)
      //No se ha podido insertar. Borrar de la lista
      dispatch({
        type: TAGS_REMOVE,
        tagId: tag.id
      })
    }
  }
}

//Carga Mattertags originales del modelo. Borra las Tags actuales
export const importMattertags = () => (dispatch, getState) => {
  const state = getState()
  removeAllTagsPromise(state)
    .then(() => {
      dispatch({
        type: TAGS_REMOVE_ALL
      })
      insertMattertagsAux(
        dispatch,
        sdkSelector(state),
        originalMattertagsSelector(state),
        cameraSensorSelector(state)
      )
    })
    .catch((error) => alert(error))
}

const insertMattertagsAux = async (dispatch, sdk, mattertags, sensor) => {
  const tags = []
  for (const mattertag of mattertags) {
    try {
      const tag = mattertagDataToTag(mattertag)
      tag.id = getNotUsedNameObjects(tags, 'id', 'tag')
      tag.originalMattertagSid = mattertag.sid
      tag.name = getNotUsedNameObjects(tags, 'name', 'Tag')

      const newTag = await insertTagPromise(sdk, tag, sensor)

      dispatch({
        type: TAGS_INSERT,
        position: mattertags.length,
        tag: newTag
      })
      tags.push(newTag)
    } catch (e) {
      alert(e)
    }
  }
}

//Cambia Tag actual si no se esta editando ninguna
export const setCurrentTag = (id) => (dispatch, getState) => {
  const state = getState()
  if (!getEditingTag(state)) {
    const tag = findById(tagsSelector(state), id)
    setCurrentTagPromise(state, tag)
      .then(() =>
        dispatch({
          type: TAGS_SET_CURRENT_TAG,
          id
        })
      )
      .catch((error) => alert(error))
  }
}

//Cambia Tag actual (a partir de su Mattertag actual) si no se esta editando
//ninguna. Responde a click en una mattertag en pantalla
export const setCurrentMattertag = (sid) => (dispatch, getState) => {
  const state = getState()
  if (!getEditingTag(state)) {
    const tags = tagsSelector(state)
    const tag = tags.find((tag) => tag.sid === sid)
    if (tag) {
      setCurrentTagPromise(state, tag.id)
        .then(() =>
          dispatch({
            type: TAGS_SET_CURRENT_TAG,
            id: tag.id
          })
        )
        .catch((error) => alert(error))
    }
  }
}

export const setCurrentTagPromise = (state, tag) => {
  return new Promise((resolve, reject) => {
    if (!tag) {
      return reject('Tag id invalido')
    }
    const modelLoaded = modelLoadedSelector(state)
    const sdk = sdkSelector(state)
    if (!sdk || !modelLoaded) {
      return resolve()
    }
    navigateToMatterTagPromise(sdk, tag.sid)
      .then(() => {
        return resolve()
      })
      .catch((error) => reject(error))
  })
}

export const mouseOverTag = (sid) => (dispatch, getState) => {
  // const state = getState()
}

export const setTagSensorReading = (id, sensorReading) => ({
  type: TAGS_SET_SENSOR_READING,
  id,
  sensorReading
})

//Recupera valores de la Tag desde la Mattertag original.
export const restoreTagFromMattertag = (id) => (dispatch, getState) => {
  const state = getState()
  const tags = tagsSelector(state)
  const tag = findById(tags, id)
  if (tag) {
    const mattertags = originalMattertagsSelector(state)
    const mattertag = mattertags.find(
      (mt) => mt.sid === tag.originalMattertagSid
    )
    if (mattertag) {
      const sdk = sdkSelector(state)
      replaceMattertagPromise(sdk, tag.sid, mattertag)
        .then((sid) => {
          const newTag = updateTagFromMattertag(tag, mattertag)
          newTag.sid = sid
          dispatch({
            type: TAGS_REPLACE,
            tagId: id,
            newTag
          })
        })
        .catch((error) => alert(error))
    }
  }
}

//Recupera tag del backup
export const restoreTag = () => (dispatch, getState) => {
  const state = getState()
  const backupTag = backupSelector(state)
  if (backupTag) {
    const sdk = sdkSelector(state)
    const modelLoaded = modelLoadedSelector(state)
    if (!sdk || !modelLoaded) {
      dispatch({
        type: TAGS_REPLACE,
        tagId: backupTag.id,
        newTag: backupTag
      })
    } else {
      replaceTagAux(
        dispatch,
        sdk,
        backupTag.id,
        backupTag.sid,
        JSON.parse(JSON.stringify(backupTag))
      )
    }
  }
}

//Inserta nueva tag. Si se da un modelTagId valido, la nueva tag es una copia de
//modelTagId
export const insertTag = (position, modelTagId) => (dispatch, getState) => {
  const state = getState()
  const modelLoaded = modelLoadedSelector(state)
  const sdk = sdkSelector(state)
  let tag = findById(tagsSelector(state), modelTagId)
  if (tag) {
    tag = JSON.parse(JSON.stringify(tag))
  } else {
    tag = Tag()
  }
  tag.id = getNotUsedNameObjects(tagsSelector(state), 'id', 'tag')
  tag.name = getNotUsedNameObjects(tagsSelector(state), 'name', tag.name)
  tag.originalMattertagSid = ''

  if (!modelLoaded || !sdk) {
    dispatch({
      type: TAGS_INSERT,
      position,
      tag
    })
  } else {
    insertTagPromise(sdk, tag, cameraSensorSelector(state))
      .then((newTag) => {
        dispatch({
          type: TAGS_INSERT,
          position,
          tag: newTag
        })
      })
      .catch((error) => alert(error))
  }
}

export const removeTag = (tagId) => (dispatch, getState) => {
  const state = getState()
  const modelLoaded = modelLoadedSelector(state)
  const sdk = sdkSelector(state)
  if (!modelLoaded || !sdk) {
    dispatch({
      type: TAGS_REMOVE,
      tagId
    })
  } else {
    const tag = findById(tagsSelector(state), tagId)
    if (tag) {
      sdk.Mattertag.remove(tag.sid)
        .then(() =>
          dispatch({
            type: TAGS_REMOVE,
            tagId
          })
        )
        .catch((error) => alert(error))
    }
  }
}

export const removeAllTags = () => (dispatch, getState) => {
  const state = getState()
  removeAllTagsPromise(state)
    .then(() => {
      dispatch({
        type: TAGS_REMOVE_ALL
      })
    })
    .catch((error) => alert(error))
}

export const setCurrentTagPosition = (position) => (dispatch, getState) => {
  const state = getState()
  const currentTag = currentTagSelector(state)
  updateTagAux(state, dispatch, currentTag, TAG_PROPERTIES.POSITION, position)
}

export const setCurrentTagStemVector = (vector) => (dispatch, getState) => {
  const state = getState()
  const currentTag = currentTagSelector(state)
  updateTagAux(state, dispatch, currentTag, TAG_PROPERTIES.STEM_VECTOR, vector)
}

export const setCurrentTagDirection = (dir) => (dispatch, getState) => {
  const state = getState()
  const currentTag = getCurrentTag(state)
  updateTagAux(
    state,
    dispatch,
    currentTag.id,
    TAG_PROPERTIES.STEM_VECTOR,
    stemVectorFromDirection(currentTag.stemVector, dir)
  )
}

const stemVectorFromDirection = (stemVector, direction) => {
  let stemModule = stemVector.x * stemVector.x
  stemModule += stemVector.y * stemVector.y
  stemModule += stemVector.z * stemVector.z
  stemModule = Math.sqrt(stemModule)
  let normalModule = direction.x * direction.x
  normalModule += direction.y * direction.y
  normalModule += direction.z * direction.z
  normalModule = Math.sqrt(normalModule)
  return {
    x: (direction.x * stemModule) / normalModule,
    y: (direction.y * stemModule) / normalModule,
    z: (direction.z * stemModule) / normalModule
  }
}

export const updateTag = (tagId, property, value) => (dispatch, getState) => {
  const state = getState()
  updateTagAux(state, dispatch, tagId, property, value)
}

//******************************************************************************
//******************************************************************************
const insertTagPromise = (sdk, tag, sensor) => {
  return new Promise((resolve, reject) => {
    const newTag = JSON.parse(JSON.stringify(tag))
    const mattertagData = tagToMattertagData(newTag)

    //Sensor source
    sdk.Sensor.createSource(sdk.Sensor.SourceType.SPHERE, {
      origin: sdk.Mattertag.getDiscPosition(mattertagData),
      radius: 10,
      userData: {
        tagId: tag.id
      }
    })
      .then((source) => {
        addMattertagPromise(sdk, mattertagData).then((sid) => {
          sensor.addSource(source)
          newTag.sid = sid
          newTag.sensorSource = source
          mattertagData.sid = sid

          if (tag.icon) {
            sdk.Mattertag.editIcon(sid, tag.icon)
              .then(() => resolve(newTag))
              .catch((error) => {
                tag.icon = ''
                resolve(newTag)
              })
          } else {
            resolve(newTag)
          }
        })
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const removeAllTagsPromise = (state) => {
  return new Promise((resolve, reject) => {
    const modelLoaded = modelLoadedSelector(state)
    const sdk = sdkSelector(state)
    const tags = tagsSelector(state)
    if (!sdk || !modelLoaded || !tags.length) {
      return resolve()
    }
    const sids = tags.map((tag) => tag.sid)
    sdk.Mattertag.remove(sids)
      .then(() => resolve())
      .catch((error) => {
        reject(error)
      })
  })
}

const updateTagAux = (state, dispatch, tagId, property, value) => {
  const tag = findById(tagsSelector(state), tagId)
  if (!tag) {
    return
  }
  const update = (newValue) => {
    dispatch({
      type: TAGS_UPDATE_PROPERTY,
      tagId,
      property,
      value: newValue
    })
  }
  const modelLoaded = modelLoadedSelector(state)
  const sdk = sdkSelector(state)
  if (!sdk || !modelLoaded) {
    update(value)
    return
  }
  switch (property) {
    case TAG_PROPERTIES.NAME:
    case TAG_PROPERTIES.DESCRIPTION:
    case TAG_PROPERTIES.LABEL:
      //Para cambiarlo en Matterport hay que recrear la mattertag. Como no se
      //usan se cambian directamente sin actualizarlos en Matterport
      update(value)
      break
    case TAG_PROPERTIES.ORIGINAL_MATTERTAG_SID:
    case TAG_PROPERTIES.PB_VISIBLE:
    case TAG_PROPERTIES.PB_TEXT:
    case TAG_PROPERTIES.PB_OFFSET_X:
    case TAG_PROPERTIES.PB_OFFSET_Y:
      update(value)
      break
    case TAG_PROPERTIES.COLOR:
      sdk.Mattertag.editColor(tag.sid, value)
        .then(() => update(value))
        .catch((error) => alert(error))
      break
    case TAG_PROPERTIES.OPACITY:
      const opacity = Math.min(1, Math.max(0, value))
      sdk.Mattertag.editOpacity(tag.sid, opacity)
        .then(() => update(opacity))
        .catch((error) => alert(error))
      break
    case TAG_PROPERTIES.POSITION:
      updatePosition(sdk, tag, value, dispatch)
      break
    case TAG_PROPERTIES.POS_X:
      updatePosition(
        sdk,
        tag,
        { x: value, y: tag.position.y, z: tag.position.z },
        dispatch
      )
      break
    case TAG_PROPERTIES.POS_Y:
      updatePosition(
        sdk,
        tag,
        { x: tag.position.x, y: value, z: tag.position.z },
        dispatch
      )
      break
    case TAG_PROPERTIES.POS_Z:
      updatePosition(
        sdk,
        tag,
        { x: tag.position.x, y: tag.position.y, z: value },
        dispatch
      )
      break
    case TAG_PROPERTIES.STEM_VECTOR:
      updateStem(sdk, tag, value, dispatch)
      break
    case TAG_PROPERTIES.STEM_X:
      updateStem(
        sdk,
        tag,
        { x: value, y: tag.stemVector.y, z: tag.stemVector.z },
        dispatch
      )
      break
    case TAG_PROPERTIES.STEM_Y:
      updateStem(
        sdk,
        tag,
        { x: tag.stemVector.x, y: value, z: tag.stemVector.z },
        dispatch
      )
      break
    case TAG_PROPERTIES.STEM_Z:
      updateStem(
        sdk,
        tag,
        { x: tag.stemVector.x, y: tag.stemVector.y, z: value },
        dispatch
      )
      break
    case TAG_PROPERTIES.ICON:
      if (value === '') {
        sdk.Mattertag.resetIcon(tag.sid)
          .then(() => update(value))
          .catch((error) => alert(error))
      } else {
        sdk.Mattertag.editIcon(tag.sid, value)
          .then(() => update(value))
          .catch((error) => alert(error))
      }
      break
    case TAG_PROPERTIES.STEM_VISIBLE:
      recreateTag(state, dispatch, sdk, tag, property, value)
      break
    default:
      console.log('-----------TODO------------')
  }
}

const updatePosition = (sdk, tag, position, dispatch) => {
  sdk.Mattertag.editPosition(tag.sid, {
    anchorPosition: position
  })
    .then(() => {
      tag.sensorSource.volume.origin = {
        x: position.x + tag.stemVector.x,
        y: position.y + tag.stemVector.y,
        z: position.z + tag.stemVector.z
      }
      tag.sensorSource.commit()
      dispatch({
        type: TAGS_UPDATE_PROPERTY,
        tagId: tag.id,
        property: TAG_PROPERTIES.POSITION,
        value: position
      })
    })
    .catch((error) => alert(error))
}

const updateStem = (sdk, tag, stemVector, dispatch) => {
  sdk.Mattertag.editPosition(tag.sid, {
    stemVector: stemVector
  })
    .then(() => {
      tag.sensorSource.volume.origin = {
        x: tag.position.x + stemVector.x,
        y: tag.position.y + stemVector.y,
        z: tag.position.z + stemVector.z
      }
      tag.sensorSource.commit()
      dispatch({
        type: TAGS_UPDATE_PROPERTY,
        tagId: tag.id,
        property: TAG_PROPERTIES.STEM_VECTOR,
        value: stemVector
      })
    })
    .catch((error) => alert(error))
}

const recreateTag = (state, dispatch, sdk, tag, property, value) => {
  const newTag = JSON.parse(JSON.stringify(tag))
  switch (property) {
    case TAG_PROPERTIES.STEM_VISIBLE: {
      newTag.stemVisible = value
      break
    }
    default:
  }
  replaceTagAux(dispatch, sdk, tag.id, tag.sid, newTag)
}

const replaceTagAux = (dispatch, sdk, originalId, originalSid, newTag) => {
  sdk.Mattertag.remove(originalSid)
    .then(() => {
      addMattertagPromise(sdk, tagToMattertagData(newTag)).then((sid) => {
        newTag.sid = sid
        if (newTag.icon !== '') {
          sdk.Mattertag.editIcon(sid, newTag.icon).then(
            dispatch({
              type: TAGS_REPLACE,
              tagId: originalId,
              newTag
            })
          )
        } else {
          dispatch({
            type: TAGS_REPLACE,
            tagId: originalId,
            newTag
          })
        }
      })
    })
    .catch((error) => alert(error))
}
