import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'

import { getMattertagErrors } from '../../../modules/reducerTagsSelectors'

const useStyles = makeStyles((theme) => ({
  itemIcon: {
    minWidth: 0,
    paddingRight: 4
  },
  fakeIcon: { height: 36, minWidth: 8 }
}))

const MLItemIcon = ({ sid, color, iconId }) => {
  const gMattertagErrors = useMemo(getMattertagErrors, [])
  const errors = useSelector((state) => gMattertagErrors(state, sid))

  const classes = useStyles({ color })

  const getTooltipMsg = () => {
    return errors.errors.length + errors.warnings.length > 0 ? (
      <div>
        {errors.errors.map((msg, idx) => (
          <div key={idx}>{msg}</div>
        ))}
        {errors.warnings.map((msg, idx) => (
          <div key={idx}>{msg}</div>
        ))}
      </div>
    ) : (
      ''
    )
  }

  return (
    <Tooltip title={getTooltipMsg()}>
      <ListItemIcon className={classes.itemIcon}>
        <Badge
          variant="dot"
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          badgeContent={errors.errors.length}
          color="error"
        >
          <Badge
            variant="dot"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            badgeContent={errors.warnings.length}
            color="error"
          >
            <div className={classes.fakeIcon} />
          </Badge>
        </Badge>
      </ListItemIcon>
    </Tooltip>
  )
}
export default MLItemIcon
