import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import ToggleButton from '../../../Common/Input/ToggleButton'

import { updateTag } from '../../../../modules/reducerTagsActions'
import { TAG_PROPERTIES } from '../../../../modules/tag'
// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const TEGStemVisible = ({ id, value, warning }) => {
  // const classes = useStyles()
  const dispatch = useDispatch()

  const updateTagValue = useCallback(
    (e) => {
      dispatch(updateTag(id, TAG_PROPERTIES.STEM_VISIBLE, e.target.checked))
    },
    [dispatch, id]
  )

  return (
    <ToggleButton
      label={'Stem Visible'}
      checked={value}
      disabled={false}
      warning={warning}
      onChange={updateTagValue}
    />
  )
}
export default TEGStemVisible
