import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import DraggableList from '../../Common/List/DraggableList'
import EditableListEmptyItem from '../../Common/List/EditableListEmptyItem'
import PLItem from './PLItem'
import {
  pointsSelector,
  insertPoint,
  swapPoints
} from '../../../modules/reducerPoints'
import { getFilteredPoints } from '../../../modules/reducerPointsFilter'

const PLList = () => {
  const fPoints = useSelector(getFilteredPoints)
  const points = useSelector(pointsSelector)
  const dispatch = useDispatch()

  const onDragEnd = (fromIdx, toIdx) => {
    dispatch(swapPoints(fPoints[fromIdx].id, fPoints[toIdx].id))
  }

  const handleAdd = useCallback(
    (e) => {
      dispatch(insertPoint(0, ''))
    },
    [dispatch]
  )

  return points.length === 0 ? (
    <EditableListEmptyItem text={'Añadir punto'} onAdd={handleAdd} />
  ) : (
    <DraggableList onSwap={onDragEnd}>
      {fPoints.map((point, index) => (
        <PLItem key={point.id} id={point.id} index={index} />
      ))}
    </DraggableList>
  )
}
export default PLList
